import React from "react";
import {StyleSheet, TextInput, View} from "react-native";
import {font500, font600, font700} from "../../../fonts";

export interface PrettyTextInputProps {
    text: string;
    setText: React.Dispatch<React.SetStateAction<string>>;
    containerStyle?: object;
    textStyle?: object;
}

export const PrettyTextInput: React.FC<PrettyTextInputProps> = ({
    text,
    setText,
    containerStyle = {},
    textStyle = {},
                                                                }) => {
    return (
        <View style={[styles.textBox, containerStyle]}>
            <TextInput
                style={[styles.textInput, textStyle]}
                value={text}
                onChangeText={setText}
                placeholderTextColor='#958C8B'
                maxLength={15}/>
        </View>
    );
}

const styles = StyleSheet.create({
    textBox: {
        backgroundColor: '#e6e6e6',
        borderRadius: 20,
        paddingVertical: 10,
        paddingHorizontal: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
    },
    textInput: {
        color: '#424242',
        fontFamily: font500,
        fontSize: 18,
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',

        // styles to remove border when focused
        borderWidth: 0,
        outlineWidth: 0,
        outlineStyle: 'none',
        boxShadow: 'none',
    },
});
