import {Dispatch, SetStateAction, useState} from "react";

export default function shareState<T>(initialState: T): SharedState<T> {
    const [value, setValue] = useState(initialState);
    return {
        value: value,
        set: setValue,
    };
}

export interface SharedState<T> {
    value: T,
    set: Dispatch<SetStateAction<T>>,
}
