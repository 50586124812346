import React from "react";
import {FlatList, StyleSheet, Text, View} from "react-native";
import {ContentSection} from "./ContentSection";
import Svg, {Path} from "react-native-svg";
import {font500} from "../../fonts";
import {SKU} from "../../../lib/sku";
import {SKUPreview} from "../../common/SkuPreview";
import {useNavigation} from "@react-navigation/native";
import {openCreatorCatalog} from "../../../screens/content/CreatorCatalog";

export interface CatalogSectionProps {
    skus: SKU[];
}

export const CatalogSection: React.FC<CatalogSectionProps> = ({skus}) => {
    const navigation = useNavigation();

    const viewFullCatalog = () => {
        openCreatorCatalog(navigation);
    };

    // @ts-ignore
    const renderItem = ({item, index}) => (
        <SKUPreview sku={item} />
    );

    return (
        <ContentSection
            title={'Catalog'}
            viewMoreText={'View all'}
            onViewMorePressed={viewFullCatalog}>

            {skus?.length === 0 &&
                <EmptyCatalogDisplay />
            }

            {skus?.length > 0 &&
                <View style={styles.container}>
                    <FlatList
                        style={{width: '100%'}}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        data={skus}
                        keyExtractor={item => item.id}
                        ItemSeparatorComponent={() => <View style={{width: 1,}} />}
                        // @ts-ignore
                        renderItem={renderItem} />
                </View>
            }
        </ContentSection>
    )
}

const EmptyCatalogDisplay: React.FC = () => {
    return (
        <View style={styles.emptyContainer}>
            <Svg width="26" height="25" viewBox="0 0 26 25" fill="none">
                <Path
                    d="M3.62499 0.000104174C3.48695 -0.00184795 3.34991 0.0236533 3.22182 0.0751257C3.09373 0.126598 2.97714 0.203015 2.87884 0.299936C2.78054 0.396857 2.70248 0.512349 2.6492 0.6397C2.59593 0.76705 2.56849 0.90372 2.56849 1.04177C2.56849 1.17981 2.59593 1.31648 2.6492 1.44383C2.70248 1.57118 2.78054 1.68668 2.87884 1.7836C2.97714 1.88052 3.09373 1.95693 3.22182 2.00841C3.34991 2.05988 3.48695 2.08538 3.62499 2.08343H22.3749C22.5129 2.08538 22.65 2.05988 22.7781 2.00841C22.9062 1.95693 23.0228 1.88052 23.1211 1.7836C23.2194 1.68668 23.2974 1.57118 23.3507 1.44383C23.404 1.31648 23.4314 1.17981 23.4314 1.04177C23.4314 0.90372 23.404 0.76705 23.3507 0.6397C23.2974 0.512349 23.2194 0.396857 23.1211 0.299936C23.0228 0.203015 22.9062 0.126598 22.7781 0.0751257C22.65 0.0236533 22.5129 -0.00184795 22.3749 0.000104174H3.62499ZM2.58332 4.16675C2.44529 4.1648 2.30825 4.1903 2.18016 4.24178C2.05207 4.29325 1.93548 4.36966 1.83718 4.46659C1.73888 4.56351 1.66082 4.679 1.60754 4.80635C1.55426 4.9337 1.52683 5.07037 1.52683 5.20842C1.52683 5.34646 1.55426 5.48313 1.60754 5.61048C1.66082 5.73783 1.73888 5.85333 1.83718 5.95025C1.93548 6.04717 2.05207 6.12358 2.18016 6.17506C2.30825 6.22653 2.44529 6.25203 2.58332 6.25008H23.4166C23.5546 6.25203 23.6916 6.22653 23.8197 6.17506C23.9478 6.12358 24.0644 6.04717 24.1627 5.95025C24.261 5.85333 24.3391 5.73783 24.3924 5.61048C24.4456 5.48313 24.4731 5.34646 24.4731 5.20842C24.4731 5.07037 24.4456 4.9337 24.3924 4.80635C24.3391 4.679 24.261 4.56351 24.1627 4.46659C24.0644 4.36966 23.9478 4.29325 23.8197 4.24178C23.6916 4.1903 23.5546 4.1648 23.4166 4.16675H2.58332ZM2.58332 8.3334C1.43229 8.3334 0.5 9.26569 0.5 10.4167V22.9167C0.5 24.0677 1.43229 25 2.58332 25H23.4166C24.5676 25 25.4999 24.0677 25.4999 22.9167V10.4167C25.4999 9.26569 24.5676 8.3334 23.4166 8.3334H2.58332Z"
                    fill="#858585"/>
            </Svg>
            <Text style={styles.emptyContainerText}>Content you upload will be visible here.</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        padding: 10,
        borderRadius: 10,
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    emptyContainer: {
        width: '100%',
        paddingTop: 40,
        paddingBottom: 20,
        flexDirection: 'column',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    emptyContainerText: {
        color: '#858585',
        fontSize: 14,
        fontFamily: font500,
    },
});
