import {apiBaseUrl} from "./utils";
import {Linking} from "react-native";
import {canAccessProject} from "./auth";

export type VerificationMethod = (token: string) => Promise<void>;

export function getVerificationMethod(token?: string): VerificationMethod {
    if (!token || token === '') {
        throw new Error('missing token');
    }

    if (canAccessProject(token, 'autoverify')) {
        return autoVerify;
    }
    return startIdentityVerification;
}

export async function startIdentityVerification(token: string) {
    let res = await fetch(`${apiBaseUrl}/verify/start`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    if (!res.ok) {
        throw new Error('failed to start verification')
    }
    let body = await res.json();
    Linking.openURL(body.url);
}

export enum VerificationStatus {
    VERIFIED,
    VERIFICATION_FAILED
}

export async function getVerificationStatus(token: string): Promise<VerificationStatus> {
    let res = await fetch(`${apiBaseUrl}/verify/status`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });

    if (!res.ok) {
        throw new Error('failed to get verification status');
    }

    let body = await res.json();
    return body.approved ? VerificationStatus.VERIFIED : VerificationStatus.VERIFICATION_FAILED;
}

export async function autoVerify(token: string) {
    let res = await fetch(`${apiBaseUrl}/verify/auto`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });

    if (!res.ok) {
        throw new Error('failed to auto verify');
    }
}
