import {Conversation, InboxState, LastMessage, State} from "./state";
import React from "react";
import _ from "lodash";
import {getConversations} from "../lib/inbox";

export const updateInbox = (inbox: InboxState, data: State, setData: React.Dispatch<React.SetStateAction<State>>) => {
    if (_.isEqual(inbox, data.inbox)) return;

    setData(prev => ({...prev, inbox: inbox}));
}

export const refreshInbox = async (data: State, setData: React.Dispatch<React.SetStateAction<State>>) => {
    try {
        let res = await getConversations(0);
        updateInbox({
            hasMore: res.hasMore,
            nextOffset: res.nextOffset,
            conversations: res.conversations
        }, data, setData);
    } catch (ex) {
        console.error('error fetching conversations: ', ex);
        throw ex;
    }
};