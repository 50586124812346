import {CreatorContentState, State} from "./state";
import React from "react";
import _ from "lodash";
import {getSkus} from "../lib/sku";

export function setContentState(
    state: CreatorContentState,
    data: State,
    setData: React.Dispatch<React.SetStateAction<State>>
) {
    if (_.isEqual(state, data.content)) return;

    setData(prev => ({...prev, content: state}));
}

export async function refreshContentState(data: State, setData: React.Dispatch<React.SetStateAction<State>>) {
    try {
        // @ts-ignore
        let skus = await getSkus(data.authToken || '', data.profile.me.userId);
        let state = {
            myCatalog: skus,
            catalogStale: false,
        };
        setContentState(state, data, setData);
    } catch (ex) {
        console.log(ex);
        alert(`could not fetch catalog from server: ${ex}`);
    }
}
