import {SafeAreaView, StyleSheet, View} from "react-native";
import React, {useEffect, useState} from "react";
import {DetachedMenuBar} from "../../components/MenuBar";
import {BalanceHeader} from "../../components/wallet/BalanceHeader";
import {getTransactionsAndBalance, WalletTransaction} from "../../lib/wallet";
import {useApp} from "../../state/AppProvider";
import {TransactionLog} from "../../components/wallet/TransactionLog";

export const Wallet: React.FC = ({}) => {
    const {data, setData} = useApp();

    // todo: manage wallet state in shared app state
    //       and use object comparisons to avoid blocking api call on each load
    const [balance, setBalance] = useState(0);
    const [transactions, setTransactions] = useState<WalletTransaction[]>([]);

    const rehydrate = async () => {
        try {
            let tx = await getTransactionsAndBalance(data.authToken);
            setBalance(tx.balance);
            setTransactions(tx.transactions);
        } catch (ex) {
            console.log(`error getting wallet data: ${ex}`);
            alert('Server error');
        }
    }

    useEffect(() => {
        rehydrate();
    }, []);

    return (
        <SafeAreaView style={styles.container}>
            {/* @ts-ignore */}
            <DetachedMenuBar />
            <View style={styles.content}>
                <BalanceHeader balance={balance} onBalanceChange={rehydrate} />
                <TransactionLog transactions={transactions}/>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f2f2f2',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingTop: 30,
        gap: 30,
    },
});
