import getUser, {User} from "../lib/profile";
import {State} from "./state";
import React from "react";
import _ from "lodash";

export const setProfileData = (me: User, data: State, setData: React.Dispatch<React.SetStateAction<State>>) => {
    if (_.isEqual(me, data.profile?.me)) return;

    setData(prev => ({...prev, profile: {...prev.profile, me}}));
}

export const refreshProfileData = async (data: State, setData: React.Dispatch<React.SetStateAction<State>>) => {
    try {
        let me = await getUser('@me');
        setProfileData(me, data, setData);
    } catch (err) {
        console.log(`failed to get user: ${err}`);
        throw err;
    }
};
