import React, {useState} from "react";
import {Dimensions, FlatList, Image, Pressable, SafeAreaView, StyleSheet, Text, TextInput, View} from "react-native";
import {DetachedMenuBar} from "../../components/MenuBar";
import Svg, {Defs, LinearGradient, Path, Stop} from "react-native-svg";
import {font500} from "../../components/fonts";
import {Controller, useForm} from "react-hook-form";
import Compressor from "compressorjs";
import {apiBaseUrl} from "../../lib/utils";
import {useApp} from "../../state/AppProvider";
import {useNavigation} from "@react-navigation/native";
import {setContentState} from "../../state/content";

export interface ContentUploadPageProps {

}

export const ContentUploadPage: React.FC<ContentUploadPageProps> = ({}) => {
    const {data, setData} = useApp();
    const [files, setFiles] = useState<StagedFile[]>([]);
    const navigation = useNavigation();

    const uploadSku = async (metadata: ContentMetadata) => {
        const formData = new FormData();
        files.forEach(f => {
            formData.append('files', f.file);
        })

        formData.append('metadata', JSON.stringify({
            body: metadata.summary,
            tags: metadata.tags,
            price: metadata.price,
            segments: ['followers'],
        }));

        try {
            const response = await fetch(`${apiBaseUrl}/studio/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${data.authToken}`
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`bad status response from api ${response.status}`);
            }
        } catch (error) {
            console.error('Upload failed:', error);
            alert(`Upload failed: ${error}`);
            throw error;
        }
    };

    const onPublish = (metadata: ContentMetadata) => {
        // todo: show confirmation screen before sending data to server
        uploadSku(metadata).then(() => {
            setContentState({
                catalogPreview: data.content.catalogPreview,
                catalogPreviewStale: true,
            }, data, setData);

            navigation.goBack();
        });
    };

    return (
        <SafeAreaView style={styles.main.container}>
            {/* @ts-ignore */}
            <DetachedMenuBar onBackPressed={(n) => n.goBack()} />
            <View style={styles.main.body}>
                <UploadButton addFile={(file) => setFiles(prev => [file, ...prev])}/>
                <SelectedFilesSection files={files} remove={(target) => {
                    setFiles(prev => prev.filter(f => f.uri !== target.uri))
                }} />

                {files?.length > 0 &&
                    <FormSection onSubmit={onPublish} />
                }
            </View>
        </SafeAreaView>
    )
}

interface StagedFile {
    uri: string;
    file: File | Blob;
}

interface UploadButtonProps {
    addFile: (f: StagedFile) => void;
}

const UploadButton: React.FC<UploadButtonProps> = ({addFile}) => {
    const handleMediaSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files || files.length == 0) {
            return;
        }

        new Compressor(files[0], {
            quality: 0.8,
            success(file: File | Blob) {
                if (file.size > 200 * 1024 * 1024) {
                    // todo: better error handling
                    alert('cannot upload file: size exceeds 200mb limit');
                    return;
                }

                addFile({
                    uri: URL.createObjectURL(file),
                    file: file,
                });
            },
            error(error: Error) {
                // todo: better error handling
                alert(`failed to load file for upload: ${error}`);
            }
        })
    };

    return (
        <Pressable style={styles.uploadBtn.container}>
            <Svg width="60" height="60" viewBox="0 0 60 60" fill="none">
                <Path
                    d="M30 0C13.4325 0 0 13.4325 0 30C0 46.5675 13.4325 60 30 60C46.5675 60 60 46.5675 60 30C60 13.4325 46.5675 0 30 0ZM45 32.5H32.5V45C32.5 46.3825 31.38 47.5 30 47.5C28.62 47.5 27.5 46.3825 27.5 45V32.5H15C13.62 32.5 12.5 31.3825 12.5 30C12.5 28.6175 13.62 27.5 15 27.5H27.5V15C27.5 13.6175 28.62 12.5 30 12.5C31.38 12.5 32.5 13.6175 32.5 15V27.5H45C46.38 27.5 47.5 28.6175 47.5 30C47.5 31.3825 46.38 32.5 45 32.5Z"
                    fill="url(#paint0_linear_1135_2030)"/>
                <Defs>
                    <LinearGradient id="paint0_linear_1135_2030" x1="-10.2632" y1="30" x2="60" y2="30"
                                    gradientUnits="userSpaceOnUse">
                        <Stop stop-color="#577CFF"/>
                        <Stop offset="1" stop-color="#AAECBC"/>
                    </LinearGradient>
                </Defs>
            </Svg>

            <Text style={styles.uploadBtn.text}>
                Upload file
            </Text>

            <input
                style={{
                    opacity: 0,
                    position: 'absolute',
                    // we want this to match the size of the svg
                    width: 60,
                    height: 60,
                }}
                type="file"
                accept="image/*"
                onChange={handleMediaSelection}
            />
        </Pressable>
    )
}

interface SelectedFilesSectionProps {
    // file locations
    files: StagedFile[];
    remove: (f: StagedFile) => void;
}

const SelectedFilesSection: React.FC<SelectedFilesSectionProps> = ({files, remove}) => {
    // @ts-ignore
    const renderFilePreview = ({item, index}) => (
        <View>
            <Image
                style={{
                    width: 130,
                    height: 140,
                    borderRadius: 4,
                    overflow: 'hidden',
                }}
                source={{uri: item.uri}}/>
            <Pressable
                onPress={() => remove(item)}
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                }}>
                <Svg width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <Path
                        d="M2.31834 0.000217377C2.12062 0.000217377 1.92269 0.0755321 1.77201 0.226597L0.226597 1.77201C-0.0755322 2.07414 -0.0755322 2.56331 0.226597 2.86467L5.86193 8.5L0.226597 14.1353C-0.0755322 14.4375 -0.0755322 14.9266 0.226597 15.228L1.77201 16.7734C2.07414 17.0755 2.56331 17.0755 2.86467 16.7734L8.5 11.1381L14.1353 16.7734C14.4367 17.0755 14.9266 17.0755 15.228 16.7734L16.7734 15.228C17.0755 14.9259 17.0755 14.4367 16.7734 14.1353L11.1381 8.5L16.7734 2.86467C17.0755 2.56331 17.0755 2.07337 16.7734 1.77201L15.228 0.226597C14.9259 -0.0755322 14.4367 -0.0755322 14.1353 0.226597L8.5 5.86193L2.86467 0.226597C2.7136 0.0755321 2.51606 0.000217377 2.31834 0.000217377Z"
                        fill="white"/>
                </Svg>
            </Pressable>
        </View>

    );

    // todo: show files
    return (
        <View style={styles.sf.container}>
            {files?.length === 0 &&
                <View style={styles.sf.empty}>
                    <Svg width="26" height="22" viewBox="0 0 26 22" fill="none">
                        <Path
                            d="M24 0H2C0.895 0 0 0.895 0 2V20C0 21.105 0.895 22 2 22H24C25.105 22 26 21.105 26 20V2C26 0.895 25.105 0 24 0ZM5 20H2V17H5V20ZM5 15H2V12H5V15ZM5 10H2V7H5V10ZM5 5H2V2H5V5ZM17.435 11.891L10.461 15.873C10.321 15.947 10.169 16 10 16C9.448 16 9 15.552 9 15V11V7C9 6.448 9.448 6 10 6C10.169 6 10.321 6.053 10.461 6.127L17.435 10.109C17.767 10.272 18 10.606 18 11C18 11.394 17.767 11.728 17.435 11.891ZM24 20H21V17H24V20ZM24 15H21V12H24V15ZM24 10H21V7H24V10ZM24 5H21V2H24V5Z"
                            fill="#484848"/>
                    </Svg>
                    <Text style={styles.sf.emptyText}>
                        Upload one or more files to get started.
                        Acceptable file types are .jpg, .png, .gif, .mov, and .mp4.
                    </Text>
                </View>
            }

            {files?.length > 0 &&
                <FlatList
                    style={{width: 100}}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    data={files}
                    keyExtractor={item => item.uri}
                    ItemSeparatorComponent={() => <View style={{width: 1,}} />}
                    // @ts-ignore
                    renderItem={renderFilePreview} />
            }
        </View>
    );
}

interface FormSectionProps {
    onSubmit: (data: ContentMetadata) => void;
}

interface ContentMetadata {
    summary: string;
    tags: string[];
    price: string;
}

const FormSection: React.FC<FormSectionProps> = ({onSubmit}) => {
    const {
        control,
        handleSubmit ,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            summary: '',
            tags: '',
            price: '$0',
        }
    });

    // @ts-ignore
    const onPublish = (data) => {
        onSubmit({
            summary: data.summary,
            // @ts-ignore
            tags: data.tags?.split(',').map(tag => tag.trim()),
            price: data.price,
        });
    };

    const placeholderColor = '#A8A8A8';

    return (
        <View style={styles.fs.container}>
            <View style={styles.fs.forms}>
                <View style={styles.fs.labeledInput}>
                    <Text style={styles.fs.labeledInputText}>Summary</Text>
                    <Controller
                        control={control}
                        name="summary"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput
                                onBlur={onBlur}
                                onChangeText={onChange}
                                value={value}
                                style={[
                                    styles.fs.inputBase,
                                    styles.fs.summaryInput,
                                ]}
                                placeholder="What I learned in boating school is..."
                                placeholderTextColor={placeholderColor}
                            />
                        )}
                    />
                </View>
                <View style={styles.fs.tagsAndPrice}>
                    <View style={styles.fs.labeledInput}>
                        <Text style={styles.fs.labeledInputText}>Tags</Text>
                        <Controller
                            control={control}
                            name="tags"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput
                                    onBlur={onBlur}
                                    onChangeText={onChange}
                                    value={value}
                                    style={[
                                        styles.fs.inputBase,
                                        styles.fs.tagsInput,
                                    ]}
                                    placeholder="cosplay, 4k"
                                    placeholderTextColor={placeholderColor}
                                />
                            )}
                        />
                    </View>

                    <View style={styles.fs.labeledInput}>
                        <Text style={styles.fs.labeledInputText}>Price</Text>
                        <Controller
                            control={control}
                            name="price"
                            rules={{
                                validate: value => {
                                    const numberValue = value.replace(/^\$/, '');
                                    return (
                                        // @ts-ignore
                                        (!numberValue || !isNaN(numberValue)) && Number.isInteger(parseFloat(numberValue)) || 'Price must be a whole number'
                                    );
                                }
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput
                                    onBlur={onBlur}
                                    onChangeText={(text) => {
                                        const formattedText = text.startsWith('$') ? text : `$${text.replace(/^\$/, '')}`;
                                        onChange(formattedText);
                                    }}
                                    value={value?.startsWith('$') ? value : `$${value}`}
                                    placeholder="0"
                                    placeholderTextColor={placeholderColor}
                                    keyboardType="numeric"
                                    style={[
                                        styles.fs.inputBase,
                                        styles.fs.priceInput,
                                    ]}
                                />
                            )}
                        />


                    </View>
                </View>
            </View>

            <Pressable
                onPress={handleSubmit(onPublish)}
                style={styles.fs.publishButton}>
                <Text
                    selectable={false}
                    style={styles.fs.publishButtonText}>
                    Publish
                </Text>
            </Pressable>

            <View style={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5,
            }}>
                {errors.summary &&
                    // @ts-ignore
                    <Text style={styles.fs.errorMsg}>{errors.summary.message}</Text>
                }

                {errors.tags &&
                    // @ts-ignore
                    <Text style={styles.fs.errorMsg}>{errors.tags.message}</Text>
                }

                {errors.price &&
                    // @ts-ignore
                    <Text style={styles.fs.errorMsg}>{errors.price.message}</Text>
                }
            </View>
        </View>
    )
}

const styles = {
    main: StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        body: {
            flex: 1,
            flexDirection: 'column',
            gap: 30,
            paddingTop: 30,
            paddingBottom: 15,
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
    }),
    uploadBtn: StyleSheet.create({
        container: {
            flexDirection: 'column',
            gap: 5,
            alignItems: 'center',
            justifyContent: 'center',
        },
        text: {
            fontSize: 15,
            fontFamily: font500,
            color: '#676767',
        },
    }),
    // selected files
    sf: StyleSheet.create({
        container: {
            paddingHorizontal: 20,
            paddingVertical: 20,
            gap: 10,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderColor: '#E7E7E7',
            width: '100%',
        },
        empty: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 10,
        },
        emptyText: {
            fontSize: 13,
            fontFamily: font500,
            color: '#484848',
            textAlign: 'center',
        },
    }),
    // form section
    fs: StyleSheet.create({
        container: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 20,
            gap: 30,
            width: '100%',
        },
        forms: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
            width: '100%',
        },
        publishButton: {
            paddingHorizontal: 30,
            paddingVertical: 7,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#69B2F6',
            borderRadius: 11,
        },
        publishButtonText: {
            fontSize: 18,
            fontFamily: font500,
            color: 'white',
        },
        labeledInput: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 5,
        },
        labeledInputText: {
            fontSize: 16,
            fontFamily: font500,
            color: '#4A4A4A',
        },
        tagsAndPrice: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: 20,
        },
        inputBase: {
            borderRadius: 15,
            borderWidth: 1,
            borderColor: '#E7E7E7',
            backgroundColor: 'white',
        },
        summaryInput: {
            height: 56,
            padding: 10,
            width: Dimensions.get('window').width - 20,
        },
        tagsInput: {
            textAlign: 'center',
            paddingVertical: 7,
            paddingHorizontal: 5,
            alignItems: 'center',
            justifyContent: 'center',
            height: 35,
        },
        priceInput: {
            textAlign: 'center',
            paddingVertical: 7,
            paddingHorizontal: 5,
            alignItems: 'center',
            justifyContent: 'center',
            height: 35,
        },
        errorMsg: {
            fontSize: 14,
            fontFamily: font500,
            color: '#ab4d4d'
        },
    }),
};
