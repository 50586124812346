import React, {Children, cloneElement, isValidElement, ReactNode} from "react";
import {Pressable, StyleSheet, Text, View} from "react-native";
import {font600} from "../fonts";
import Svg, {Path} from "react-native-svg";

export interface MultiButtonProps {
    children: ReactNode;
    separatorColor?: string;
}

export const MultiButton: React.FC<MultiButtonProps> = ({
                                                            separatorColor,
                                                            children,
                                                        }) => {
    const Separator = () => <View style={[
        styles.main.separator,
        separatorColor ? { backgroundColor: separatorColor } : {},
    ]}/>;

    const childrenArray = Children.toArray(children);

    const modifiedChildren = childrenArray.map((child, index) => {
        if (!isValidElement(child)) {
            return child;
        }

        let style = {};
        if (index === 0) {
            style = styles.row.topStyle;
        } else if (index === childrenArray.length - 1) {
            style = styles.row.bottomStyle;
        }

        return cloneElement(child, {
            // @ts-ignore
            style: {...child.props.style, ...style},
        });
    });

    return (
        <View style={styles.main.container}>
            {modifiedChildren.map((child, index) => (
                <>
                    {child}
                    {index < modifiedChildren.length - 1 && <Separator />}
                </>
            ))}
        </View>
    );
}

export interface MultiButtonRowProps {
    Icon: any;
    text: string;
    textStyle: object;
    onPress: () => void;
    style?: object;
    arrowColor?: string;
}

export const MultiButtonRow: React.FC<MultiButtonRowProps> = ({
                                                                  Icon,
                                                                  text,
                                                                  onPress,
                                                                  style = {},
                                                                  textStyle = {},
                                                                  arrowColor,
                                                              }) => {
    return (
        <Pressable
            onPress={onPress}
            style={[styles.row.container, style]}>
            <View style={styles.row.iconAndLabel}>
                {Icon && <View style={styles.row.iconContainer}>{<Icon/>}</View>}
                <Text style={[styles.row.text, textStyle]}>{text}</Text>
            </View>

            <Svg width="9" height="12" viewBox="0 0 9 12" fill="none">
                <Path
                    d="M1.03992 0.000560047C0.824567 -0.00665604 0.612658 0.0560229 0.435877 0.179227C0.259095 0.302431 0.126937 0.479541 0.0591587 0.684083C-0.00861951 0.888624 -0.008377 1.10961 0.0598501 1.314C0.128077 1.51839 0.260624 1.69521 0.437676 1.81803L6.2867 5.99577L0.437676 10.1735C0.326866 10.2479 0.23211 10.3438 0.159018 10.4555C0.0859253 10.5672 0.0359809 10.6924 0.0121406 10.8238C-0.0116997 10.9551 -0.00895157 11.0899 0.0202224 11.2201C0.0493965 11.3504 0.104403 11.4735 0.181988 11.5821C0.259572 11.6907 0.358158 11.7826 0.471909 11.8525C0.585661 11.9223 0.712267 11.9686 0.844235 11.9887C0.976203 12.0087 1.11085 12.0021 1.24021 11.9692C1.36957 11.9363 1.49101 11.8777 1.59735 11.797L8.58266 6.80753C8.71179 6.71518 8.81703 6.59336 8.88963 6.45217C8.96223 6.31099 9.00011 6.15452 9.00011 5.99577C9.00011 5.83701 8.96223 5.68054 8.88963 5.53936C8.81703 5.39817 8.71179 5.27635 8.58266 5.184L1.59735 0.194488C1.43534 0.0751266 1.24103 0.00752727 1.03992 0.000560047Z"
                    fill={arrowColor || '#423B3B'}/>
            </Svg>
        </Pressable>
    );
}

const styles = {
    main: StyleSheet.create({
        container: {
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        },
        separator: {
            height: 1,
            width: '100%',
            backgroundColor: '#F5F5F5',
        },
    }),
    row: StyleSheet.create({
        container: {
            paddingHorizontal: 20,
            paddingVertical: 15,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        iconAndLabel: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 12,
        },
        iconContainer: {
            width: 30,
            height: 30,
            justifyContent: 'center',
            alignItems: 'center',
        },
        text: {
            color: '#E0E8E8',
            fontSize: 15,
            fontFamily: font600,
        },
        topStyle: {
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
        },
        bottomStyle: {
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
        },
    })
}
