import React, {useEffect, useRef} from 'react';
import {View, Text, StyleSheet, Animated} from 'react-native';
import {
    neutral100, neutral200
} from "../colors";
import {font200, font300, font400, font500} from "../fonts";
import Svg, {Path} from "react-native-svg";
import {Avatar} from "./Avatar";

export interface MessageBubbleProps {
    // display avatar for received messages; does not apply to sent
    Avatar?: any;
    perspective: Perspective;
    message: string;
    creationTime: Date;
    showTime: boolean;
    animate: boolean;
    delivered?: boolean;
}

export enum Perspective {
    SENT,
    RECEIVED
}

export const MessageBubble: React.FC<MessageBubbleProps> = ({
                                                                perspective,
                                                                message,
                                                                creationTime,
                                                                showTime = true,
                                                                animate,
                                                                delivered = false,
                                                                Avatar,
                                                            }) => {
    const isSentMessage = perspective === Perspective.SENT;
    const scaleX = useRef(new Animated.Value(0)).current;
    const scaleY = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        if (!animate) {
            return;
        }

        Animated.parallel([
            Animated.timing(scaleX, {
                toValue: 1,
                duration: 150,
                useNativeDriver: false,
            }),
            Animated.timing(scaleY, {
                toValue: 1,
                duration: 150,
                useNativeDriver: false,
            }),
        ]).start();
    }, []);

    const animatedStyle = {
        transform: [{ scaleX }, { scaleY }],
    };

    const formatTime = (date: Date) => {
        let formattedTime = date.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit', hour12: true});
        formattedTime = formattedTime.replace('AM', 'am').replace('PM', 'pm');
        return formattedTime;
    };

    return (
        <Animated.View style={[
            styles.container,
            animate ? animatedStyle : {},
            // todo: kill me
            !showTime && perspective === Perspective.RECEIVED ? {marginLeft: 43,} : {}
        ]}>
            {(perspective === Perspective.RECEIVED && Avatar && showTime) &&
                <Avatar />
            }

            <View style={{
                flex: 1,
            }}>
                <View
                    style={[
                        styles.messageBubble,
                        isSentMessage ? styles.sentMessage : styles.receivedMessage,
                        {marginBottom: showTime ? 5 : 0}
                    ]}
                >
                    <Text style={styles.messageText}>
                        {message}
                    </Text>
                </View>
                {showTime &&
                    <View style={isSentMessage ? styles.timestampContainerSent : styles.timestampContainerReceived}>
                        {(delivered && isSentMessage) &&
                            <Svg width="14" height="8" viewBox="0 0 14 8" fill="none">
                                <Path
                                    d="M10.7264 0.944539C10.6053 0.948149 10.4903 0.998785 10.4058 1.08572L6.07197 5.41955L4.54083 3.88841C4.53932 3.88688 4.5378 3.88536 4.53628 3.88385C4.44883 3.79639 4.33023 3.74724 4.20655 3.74723C4.08286 3.74723 3.96424 3.79636 3.87679 3.88382C3.78933 3.97128 3.74019 4.0899 3.74019 4.21358C3.74021 4.33726 3.78936 4.45587 3.87682 4.54331L5.41251 6.079L4.6729 6.81861L1.73814 3.88385C1.69517 3.8391 1.6437 3.80336 1.58674 3.77875C1.52979 3.75413 1.46849 3.74112 1.40645 3.74049C1.34441 3.73986 1.28286 3.75162 1.22541 3.77507C1.16797 3.79852 1.11578 3.8332 1.07191 3.87708C1.02803 3.92095 0.993356 3.97314 0.969903 4.03058C0.946451 4.08803 0.934696 4.14957 0.935327 4.21162C0.935958 4.27366 0.948963 4.33496 0.973579 4.39191C0.998196 4.44887 1.03393 4.50034 1.07869 4.54331L4.34318 7.8078C4.43064 7.89522 4.54924 7.94434 4.6729 7.94434C4.79657 7.94434 4.91517 7.89522 5.00263 7.8078L11.0653 1.74518C11.1326 1.67967 11.1787 1.59534 11.1973 1.50322C11.2159 1.41111 11.2063 1.31551 11.1697 1.22896C11.1331 1.14241 11.0711 1.06895 10.992 1.01819C10.9129 0.967442 10.8204 0.941768 10.7264 0.944539ZM13.5337 0.949093C13.41 0.949112 13.2914 0.998258 13.2039 1.08572L7.47104 6.81861L7.33441 6.68199L6.67495 7.34144L7.14131 7.8078C7.22877 7.89522 7.34737 7.94434 7.47104 7.94434C7.5947 7.94434 7.7133 7.89522 7.80076 7.8078L13.8588 1.74973C13.9035 1.70627 13.939 1.6543 13.9633 1.59688C13.9875 1.53947 14 1.47777 14 1.41545C14 1.29176 13.9509 1.17314 13.8634 1.08569C13.776 0.998227 13.6573 0.949093 13.5337 0.949093Z"
                                    fill="#7D7D7D"/>
                            </Svg>
                        }
                        <Text style={styles.timestampText}>
                            {formatTime(creationTime)}
                        </Text>
                    </View>
                }
            </View>
        </Animated.View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingBottom: 5,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 10,
    },
    messageBubble: {
        maxWidth: '70%',
        paddingVertical: 7,
        paddingHorizontal: 11,
        borderRadius: 12,
        alignSelf: 'flex-start',
        //borderWidth: 1,
        //borderColor: '#e6e6e6',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)'
    },
    sentMessage: {
        marginRight: 5,
        backgroundColor: '#85b4cc',
        marginLeft: 'auto',
    },
    receivedMessage: {
        marginLeft: 5,
        backgroundColor: '#86bfb4',
    },
    messageText: {
        fontSize: 15,
        fontFamily: font500,
        color: 'white',
    },
    timestampContainerSent: {
        paddingRight: 8,
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        gap: 5,
        paddingBottom: 5,
    },
    timestampContainerReceived: {
        paddingLeft: 8,
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingBottom: 5,
        gap: 5,
        flexDirection: 'row',
    },
    timestampText: {
        fontSize: 11,
        color: '#7D7D7D',
        fontFamily: font400,
        letterSpacing: -0.5,
        opacity: 0.75
    },
});
