import {getAuthToken} from "./auth";
import {apiBaseUrl, msgApiUrl} from "./utils";

const getConversationsApiPath = `${apiBaseUrl}/messages`;
const pageSize = 25;

export async function getConversations(offset: number): Promise<GetConversationsResponse> {
    let res = await fetch(`${getConversationsApiPath}?pageSize=${pageSize}&pageOffset=${offset}`, {
        headers: {
            'Authorization': `Bearer ${getAuthToken()}`
        }
    });

    if (!res.ok) {
        throw new Error(`error fetching conversations: status ${res.status}`);
    }

    let body = await res.json();
    let hasMore = body.conversations.length === pageSize;
    return {
        hasMore: hasMore,
        nextOffset: hasMore ? offset + pageSize : 0,
        conversations: body.conversations,
    };
}

export interface GetConversationsResponse {
    hasMore: boolean;
    nextOffset: number;
    conversations: Conversation[];
}

export interface Conversation {
    userId: string;
    username: string;
    userAvatarUri: string;
    chatId: string;
    lastMessage: LastMessage;
}

export interface LastMessage {
    text: string;
    sentAt: string;
    sentByUserId: string;
}

export async function getConversation(chatId: string, cursor: string): Promise<ConversationHistory> {
    try {
        const response = await fetch(`${msgApiUrl}/conversations/${chatId}/messages?pageSize=${pageSize}&pageCursor=${cursor}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        let res = await response.json();
        let newCursor = undefined;
        let hasMore = res?.messages?.length === pageSize;
        if (hasMore) {
            newCursor = res.messages[res.messages.length - 1].id;
        }

        return {
            cursor: newCursor,
            messages: res.messages
        };
    } catch (error) {
        console.error('error fetching chat history:', error);
        throw error;
    }
}

export interface ConversationHistory {
    messages: Message[];
    cursor?: string;  // present if more messages can be fetched
}

export interface Message {
    id: string;
    cid: string;
    user_id: string;
    message_type: MessageType;
    sent_at: number;
    content: string;
    metadata: object;
}

export enum MessageType {
    USER = 1,
    ASSISTANT,
    TIP_NOTIFICATION,
    SKU_DELIVERY
}

export async function getConversationId(withUser: string): Promise<string> {
    try {
        const response = await fetch(`${msgApiUrl}/conversations`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`,
            },
            body: JSON.stringify({withUser: withUser})
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        let res = await response.json();
        return res.conversationId;
    } catch (error) {
        console.error('error fetching conversation id:', error);
        throw error;
    }
}

export async function sendMessage(token: string, cid: string, content: string): Promise<void> {
    const response = await fetch(`${msgApiUrl}/conversations/${cid}/messages`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            content: content,
        }),
    });

    if (!response.ok) {
        throw new Error(`bad status from message api: ${response.status}`);
    }
}
