import {apiBaseUrl} from "./utils";
import {getAuthToken} from "./auth";

export async function sendReport(report: string, offenderUserId: string) {
    const response = await fetch(`${apiBaseUrl}/report`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${getAuthToken()}`
        },
        body: JSON.stringify({
            offenderId: offenderUserId,
            details: report,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to send report');
    }
}