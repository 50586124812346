import {StyleSheet, View, Text, TextInput} from "react-native";
import React, {useState} from "react";
import Svg, {Path} from "react-native-svg";
import {NeonButton} from "../../../common/NeonButton";
import {BottomModal} from "../../../common/BottomModal";
import {font400, font500} from "../../../fonts";
import {sendReport} from "../../../../lib/report";
import {ProfileButton} from "../../common/ProfileButton";

export interface ReportModalViewProps {
    userId: string;
    closeModal: () => void;
}

export const ReportModalView: React.FC<ReportModalViewProps> = ({userId, closeModal}) => {
    const [reason, setReason] = useState('');

    const onSend = () => {

        // todo: handle error
        // todo: show confirmation that we got their report and what they should expect next
        sendReport(reason, userId)
            .then(closeModal);
    };

    return (
        <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 15,
            paddingHorizontal: 10,
            paddingTop: 10,
        }}>
            <View style={styles.textBox}>
                <TextInput
                    style={styles.textInput}
                    value={reason}
                    onChangeText={setReason}
                    multiline={true}
                    placeholder="Explain the issue"
                    placeholderTextColor='#958C8B'
                    maxLength={2048}/>
            </View>

            <ProfileButton
                buttonText={'Send'}
                buttonStyle={{paddingHorizontal: 30}}
                // @ts-ignore
                onPress={onSend}/>
        </View>
    );
}

const styles = StyleSheet.create({
    buttonTextStyle: {
        fontSize: 14,
    },
    buttonStyle: {
        borderColor: '#FA6A6A',
        width: 110,
        height: 35,
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    modalContent: {
        height: 192,
        backgroundColor: 'transparent',
        width: '100%',
    },
    modalInnerContent: {
        height: 192,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: '#353d4b',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px -1px 15px rgba(0, 0, 0, 0.4)',
    },
    blockText: {
        fontSize: 15,
        fontFamily: font500,
        color: '#BFBFBF',
    },
    sendButtonStyle: {
        borderColor: '#6AE0FA',
        width: 110,
        height: 35,
    },
    sendButtonTextStyle: {
        fontSize: 14,
        textAlign: 'center',
    },
    textBox: {
        backgroundColor: '#e6e6e6',
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 10,
        width: '100%',
        height: 120,
    },
    textInput: {
        color: '#424242',
        fontFamily: font400,
        fontSize: 14,
        flex: 1,

        // styles to remove border when focused
        borderWidth: 0,
        outlineWidth: 0,
        outlineStyle: 'none',
        boxShadow: 'none',
    },
});
