import React from "react";
import {StyleSheet, View} from "react-native";
import {Perspective} from "./MessageBubble";
import {SKUPreview} from "../common/SkuPreview";

export interface SKUDisplayProps {
    perspective: Perspective;
    uris: string[];
}

export const SKUDisplay: React.FC<SKUDisplayProps> = ({perspective, uris}) => {
    return (
        <View style={[
            styles.container,
            {
                alignItems: perspective === Perspective.RECEIVED ? 'flex-start': 'flex-end',
            },
        ]}>
            <SKUPreview
                // @ts-ignore
                sku={{
                    // todo: pass actual sku into the SKUDisplay component so
                    //       we get all of the props here.
                    unlocked: true,
                    media: uris.map(uri => ({id: 'todo', uri: uri})),
                }}
                cardStyle={styles.image} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingHorizontal: 6,
    },
    image: {
        width: 200,
        height: 270,
        resizeMode: 'cover',
        borderRadius: 5,
        overflow: 'hidden',
    },
});
