import React, {useEffect, useState} from "react";
import {Pressable, StyleSheet, Text, View} from "react-native";
import {font600} from "../../components/fonts";
import {CatalogSection} from "../../components/content/creator/CatalogSection";
import {useNavigation} from "@react-navigation/native";
import {getSkus, SKU} from "../../lib/sku";
import {getAuthToken} from "../../lib/auth";
import {useApp} from "../../state/AppProvider";
import {refreshContentState, setContentState} from "../../state/content";

export interface CreatorContentPageProps {

}

export const CreatorContentPage: React.FC<CreatorContentPageProps> = ({}) => {
    const {data, setData} = useApp();
    if (!data.profile) {
        // todo: handle this in a better way
        return <View style={{flex: 1}}/>
    }

    useEffect(() => {
        if (data.content.catalogStale) {
            refreshContentState(data, setData);
        }
    }, [data.content]);

    return (
        <View style={styles.container}>
            <UploadContentButton />
            <CatalogSection skus={data.content.myCatalog ? data.content.myCatalog.slice(0, 10) : []} />
        </View>
    )
}

const UploadContentButton: React.FC = () => {
    const navigation = useNavigation();
    const [isPressedDown, setIsPressedDown] = useState(false);

    return (
        <Pressable
            onPress={() => {
                // @ts-ignore
                navigation.navigate('ContentUploadPage');
            }}
            onPressIn={() => setIsPressedDown(true)}
            onPressOut={() => setIsPressedDown(false)}
            style={[
                styles.uploadBtn,
                isPressedDown ? {backgroundColor: '#d4d4d4'} : {}
            ]}>
            <Text
                selectable={false}
                style={styles.uploadBtnText}>Upload Content</Text>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingVertical: 40,
        flexDirection: 'column',
        gap: 40,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    uploadBtn: {
        paddingHorizontal: 35,
        paddingVertical: 10,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 22,
        backgroundColor: '#e6e6e6',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.12)',
        borderWidth: 1,
        borderColor: '#dadada'
    },
    uploadBtnText: {
        color: '#595959',
        fontFamily: font600,
        fontSize: 14,
        letterSpacing: -0.5,
    },
});
