import {apiBaseUrl} from "./utils";

export interface TxAndBalance {
    balance: number;
    transactions: WalletTransaction[];
}

export interface WalletTransaction {
    dateTime: string;
    status: string;
    summary: string;
}

export async function getTransactionsAndBalance(token?: string): Promise<TxAndBalance> {
    if (!token) {
        throw new Error('missing token');
    }

    try {
        const response = await fetch(`${apiBaseUrl}/wallet/balance?includeTransactions=true`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error retrieving wallet:', error);
        throw error;
    }
}

export async function depositFunds(amount: number, token?: string) {
    if (!token) {
        throw new Error('missing auth token');
    }

    try {
        const response = await fetch(`${apiBaseUrl}/wallet/deposit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({amount: amount}),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error submitting deposit:', error);
        throw error;
    }
}