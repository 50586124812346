import React, {useState} from "react";
import {Pressable, StyleSheet, Text} from "react-native";
import {font400, font500} from "../../fonts";

export interface ProfileButtonProps {
    Icon?: (props: {color: string}) => React.JSX.Element;
    buttonStyle?: object;

    textStyle?: object;
    buttonText?: string;

    onPress: () => void;
}

const buttonContentColor = '#000';

export const ProfileButton: React.FC<ProfileButtonProps> = ({
    buttonStyle = {},
    buttonText,
    textStyle = {},
    Icon,
    onPress = () => {},
                                                      }) => {
    const [isPressedDown, setPressedDown] = useState(false);

    return (
        <Pressable
            onPressIn={() => setPressedDown(true)}
            onPressOut={() => setPressedDown(false)}
            onPress={onPress}
            style={[
                styles.container,
                buttonStyle,
            ]}>
            {Icon && <Icon color={buttonContentColor} />}
            {buttonText &&
                <Text style={[
                    styles.text,
                    textStyle,
                ]}>
                    {buttonText}
                </Text>
            }
        </Pressable>
    );
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 21,
        height: 31,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 8,
        paddingHorizontal: 18,
        paddingVertical: 20,
        backgroundColor: '#e3e6e6',
    },
    text: {
        userSelect: 'none',
        color: buttonContentColor,
        fontFamily: font500,
        fontSize: 14,
    },
});
