import * as React from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import Settings from './src/screens/settings/Settings';
import {Dimensions, Image, SafeAreaView, StyleSheet, Text, View} from "react-native";
import Conversation from "./src/screens/inbox/Conversation";
import BlockedUsers from "./src/screens/settings/BlockedUsers";
import {neutral100, neutral800} from "./src/components/colors";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import * as Playfair from '@expo-google-fonts/playfair-display';
import {
    PlayfairDisplay_600SemiBold,
    PlayfairDisplay_500Medium,
} from "@expo-google-fonts/playfair-display";

import * as Inter from '@expo-google-fonts/inter';
import {
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
} from "@expo-google-fonts/inter";

import * as Quicksand from '@expo-google-fonts/quicksand';
import {
    Quicksand_300Light,
    Quicksand_400Regular,
    Quicksand_500Medium,
    Quicksand_600SemiBold,
    Quicksand_700Bold,
} from '@expo-google-fonts/quicksand';
import MainScreen from "./src/screens/MainScreen";
import {AppProvider} from "./src/state/AppProvider";
import {Home} from "./src/screens/Home";
import {PeerProfile} from "./src/screens/profile/PeerProfile";
import {UnauthenticatedHome} from "./src/screens/UnauthenticatedHome";
import {EditProfile} from "./src/screens/profile/EditProfile";
import {CreatorProgram} from "./src/screens/profile/CreatorProgram";
import {Wallet} from "./src/screens/wallet/Wallet";
import {TermsScreen} from "./src/components/menu/TermsOfService";
import {PrivacyPolicyScreen} from "./src/components/menu/PrivacyPolicy";
import {ContentUploadPage} from "./src/screens/content/ContentUploadPage";
import {ContentDisplay} from "./src/components/content/ContentDisplay";
import {CreatorCatalog} from "./src/screens/content/CreatorCatalog";
import {AssistantSettings} from "./src/screens/assistant/AssistantSettings";

const firebaseConfig = {
    apiKey: "AIzaSyDRwXMMPn51JspgVZHRIns6ENXLRLkv54Y",
    authDomain: "punchcardco.firebaseapp.com",
    projectId: "punchcardco",
    storageBucket: "punchcardco.appspot.com",
    messagingSenderId: "1090275212683",
    appId: "1:1090275212683:web:92e69b28ca00a5da17438e",
    measurementId: "G-RT6HQF7LPL"
};
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const Stack = createStackNavigator();

export default function App() {
    const window = Dimensions.get('window');
    const isPhone = window.width < 768;

    // Good for headers or bold content
    Playfair.useFonts({
        PlayfairDisplay_600SemiBold,
        PlayfairDisplay_500Medium,
    });

    Inter.useFonts({
        Inter_100Thin,
        Inter_200ExtraLight,
        Inter_300Light,
        Inter_400Regular,
        Inter_500Medium,
        Inter_600SemiBold,
        Inter_700Bold,
        Inter_800ExtraBold,
        Inter_900Black,
    })

    // Good for copy, interactive text, or buttons
    Quicksand.useFonts({
        Quicksand_300Light,
        Quicksand_400Regular,
        Quicksand_500Medium,
        Quicksand_600SemiBold,
        Quicksand_700Bold,
    });

    if (isPhone) {
        return (
            <AppProvider>
                <NavigationContainer>
                    <Stack.Navigator initialRouteName="UnauthenticatedHome" screenOptions={{
                        headerShown: false,
                        title: 'sqyze',
                    }}>
                        <Stack.Screen name="Settings" component={Settings}/>
                        <Stack.Screen name="Settings.BlockedUsers" component={BlockedUsers}/>
                        <Stack.Screen name="Conversation" component={Conversation}/>
                        <Stack.Screen name="Wallet" component={Wallet}/>
                        <Stack.Screen name="MainScreen" component={MainScreen}/>
                        <Stack.Screen name="Home" component={Home}/>
                        <Stack.Screen name='PeerProfile' component={PeerProfile}/>
                        <Stack.Screen name='EditProfile' component={EditProfile}/>
                        <Stack.Screen name='CreatorProgram' component={CreatorProgram}/>
                        <Stack.Screen name='UnauthenticatedHome' component={UnauthenticatedHome}/>
                        <Stack.Screen name='TermsScreen' component={TermsScreen}/>
                        <Stack.Screen name='PrivacyPolicy' component={PrivacyPolicyScreen}/>
                        <Stack.Screen name='ContentUploadPage' component={ContentUploadPage}/>
                        <Stack.Screen name='ContentDisplay' component={ContentDisplay}/>
                        <Stack.Screen name='CreatorCatalog' component={CreatorCatalog}/>
                        <Stack.Screen name='AssistantSettings' component={AssistantSettings}/>
                    </Stack.Navigator>
                </NavigationContainer>
            </AppProvider>
        );
    } else {
        return (
            <SafeAreaView style={styles.desktopContainer}>
                <View style={styles.desktopTextContainer}>
                    <Text style={{fontSize: 18, fontWeight: '400', color: neutral100}}>
                        Hello and thanks for stopping by. For now, this site only works
                        on mobile browsers. Scan this code with your phone to be directed
                        to the mobile experience.
                    </Text>
                </View>
                <Image
                    style={styles.desktopQrCode}
                    source={require('./assets/qr-code.png')}/>
            </SafeAreaView>
        );
    }
}

const styles = StyleSheet.create({
    desktopContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: neutral800,
        gap: 50,
    },
    desktopTextContainer: {
        width: '30%',
        alignItems: 'center',
    },
    desktopQrCode: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 300,
        height: 300,
        resizeMode: 'contain'
    },
});
