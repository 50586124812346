import React from "react";
import {StyleSheet, View, Text, Dimensions, SafeAreaView} from "react-native";
import shareState from "../../lib/shareState";
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import {neutral100} from "../colors";
import {font500} from "../fonts";
import {GenericMedia} from "../common/GenericMedia";
import {useApp} from "../../state/AppProvider";
import {DetachedMenuBar} from "../MenuBar";
import {SKU} from "../../lib/sku";

export interface ContentDisplayProps {
    route: {
        params: {
            sku: SKU;
        }
    }
}

export function displayContent(navigation: any, sku: SKU) {
    // @ts-ignore
    navigation.navigate('ContentDisplay', { sku: sku });
}

export const ContentDisplay: React.FC<ContentDisplayProps> = ({route}) => {
    const {data, setData} = useApp();
    const currentIndex = shareState(0);
    const sku = route.params.sku;

    // @ts-ignore
    const onSwipe = ({ nativeEvent }) => {
        // todo: replace swipe behavior with directional buttons
        // there should be buttons on the center left side and center right side of the image
        // additionally, the index presentation (ex. 2/5) should be overlayed on the top right
        // section of the image

        if (nativeEvent.state === State.END) {
            if (nativeEvent.velocityX > 0 && currentIndex.value > 0) {
                // Swiped right
                currentIndex.set(prev => prev - 1);
            } else if (nativeEvent.velocityX < 0 && currentIndex.value < sku.media.length - 1) {
                // Swiped left
                currentIndex.set(prev => prev + 1);
            }
        }
    };

    return (
        <SafeAreaView style={{flex: 1, backgroundColor: '#f2f2f2'}}>
            {/* @ts-ignore */}
            <DetachedMenuBar onBackPressed={(n) => n.goBack()} />
            <View style={styles.container}>
                <PanGestureHandler onHandlerStateChange={onSwipe}>
                    <View style={styles.mediaSection}>
                        <GenericMedia
                            uri={sku.media[currentIndex.value].uri}
                            imageResizeMode={'contain'}
                            imageStyle={styles.imageMedia}
                            videoStyle={styles.imageMedia}
                            token={data.authToken || ''}/>

                        {sku.media.length > 1 &&
                            <Text style={styles.pageText}>
                                {currentIndex.value + 1}/{sku.media.length}
                            </Text>
                        }
                    </View>
                </PanGestureHandler>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeIcon: {
        padding: 4,
    },
    topOrBottomBar: {
        height: 46,
        width: '100%',
        alignItems: 'flex-end',
        justifyContent: 'center',
        padding: 10,
    },
    mediaSection: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        flex: 1,
    },
    imageMedia: {
        resizeMode: 'contain',
        flex: 1,
        // I don't like this at all, but it's the only way it works :(
        width: Dimensions.get('window').width,
        // sigh.. this needs to match the aspect ratio set by the server when transcoding
        maxHeight: Dimensions.get('window').height * 0.8,
    },
    videoMedia: {

    },
    pageText: {
        fontSize: 17,
        fontFamily: font500,
        color: '#707070',
    },
});
