import React, {useState} from "react";
import {StyleSheet, Text, View} from "react-native";
import {ProfileButton} from "../profile/common/ProfileButton";
import {font500} from "../fonts";
import {DepositModal} from "./DepositModal";

export interface BalanceHeaderProps {
    balance: number;
    onBalanceChange: () => void;
}

export const BalanceHeader: React.FC<BalanceHeaderProps> = ({balance, onBalanceChange}) => {
    const [depositModalVisible, setDepositModalVisible] = useState(false);

    return (
        <View style={styles.container}>
            <View style={styles.balanceContainer}>
                <Text style={styles.avText}>Available Balance</Text>
                <Text style={styles.balanceText}>${balance}</Text>
            </View>

            <View style={styles.buttons}>
                <ProfileButton
                    textStyle={styles.buttonText}
                    buttonStyle={styles.button}
                    buttonText={'Add Funds'}
                    onPress={() => setDepositModalVisible(true)} />
                <DepositModal
                    visible={depositModalVisible}
                    setVisible={setDepositModalVisible}
                    onCompleteDeposit={onBalanceChange} />

                <ProfileButton
                    textStyle={styles.buttonText}
                    buttonStyle={styles.button}
                    buttonText={'Cash Out'}
                    onPress={() => {}} />

                <ProfileButton
                    textStyle={styles.buttonText}
                    buttonStyle={styles.button}
                    buttonText={'Saved Cards'}
                    onPress={() => {}} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        padding: 10,
        flexDirection: 'column',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    balanceContainer: {
        flexDirection: 'column',
        gap: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttons: {
        flexDirection: 'row',
        gap: 10,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    avText: {
        color: '#4d2f2f',
        fontSize: 16,
        fontFamily: font500,
        letterSpacing: -0.5,
    },
    balanceText: {
        color: '#404040',
        fontFamily: font500,
        fontSize: 75,
        letterSpacing: -3,
    },
    button: {
        backgroundColor: '#dee0e0',
        paddingVertical: 21,
    },
    buttonText: {
        color: '#4d4d4d',
        fontSize: 15,
    },
});
