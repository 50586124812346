import {apiBaseUrl} from "./utils";
import {getAuthToken} from "./auth";

export async function sendTip(amount: number, recipientID: string, chatId: string = '') {
    try {
        let res = await fetch(`${apiBaseUrl}/share/gift`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`,
            },
            body: JSON.stringify({
                amount: amount,
                to: recipientID,
                chatId: chatId,
            }),
        });

        if (!res.ok) {
            throw new Error(`bad status: ${res.status}`);
        }
    } catch (ex) {
        throw new Error(`failed to send gift: ${ex}`);
    }
}