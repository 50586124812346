export enum MediaType {
    IMAGE,
    VIDEO,
}

export function getMediaType(uri: string): MediaType {
    const extension = uri.split('.').pop()?.toLowerCase();
    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        return MediaType.IMAGE;
    } else if (extension === 'mp4' || extension === 'mov') {
        return MediaType.VIDEO;
    } else {
        throw new Error(`cannot detect media type for uri: ${uri}`);
    }
}

export interface MediaCounts {
    image: number;
    video: number;
}

export function getCounts(uris: string[]): MediaCounts {
    const mediaCounts: MediaCounts = {
        image: 0,
        video: 0,
    };

    uris.forEach((uri) => {
        const mediaType = getMediaType(uri);
        if (mediaType === MediaType.IMAGE) {
            mediaCounts.image++;
        } else if (mediaType === MediaType.VIDEO) {
            mediaCounts.video++;
        }
    });

    return mediaCounts;
}

