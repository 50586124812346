import {Animated, Dimensions, Modal, Pressable, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import Svg, {Path} from "react-native-svg";
import React, {useEffect, useRef, useState} from "react";
import {font500} from "./fonts";
import {useNavigation} from "@react-navigation/native";
import {Menu} from "./menu/Menu";

export default function MenuBar({
    // activeScreenName is the name of the screen that is currently
    // being displayed in MainScreen. This variable determines which
    // menu item is selected when the component mounts. It is one of:
    // - home
    // - inbox
    // - profile
    activeScreenName,

    // onSelect is called when a menu item on the bar is selected.
    // It is passed the name of the screen, one of:
    // - home
    // - inbox
    // - profile
    onSelect,

    // Replaces leftmost icon with a back button
    showBackButton = false,
    onBackPressed = (navigation) => {navigation.goBack()},
    onLayout = (event) => {},
}) {
    const navigation = useNavigation();
    const isActive = (screen) => screen === activeScreenName;
    const getOpacity = (screen) => isActive(screen) ? 0.8 : 0.4;
    const iconColor = '#311903';

    const scaleValue = () => React.useRef(new Animated.Value(1)).current;
    const menuIconScale = scaleValue();
    const messagesIconScale = scaleValue();
    const profileIconScale = scaleValue();
    const contentIconScale = scaleValue();

    const [menuVisible, setMenuVisible] = useState(false);
    const menuAnimation = useRef(new Animated.Value(Dimensions.get('window').width)).current; // Start off-screen

    useEffect(() => {
        Animated.timing(menuAnimation, {
            toValue: menuVisible ? 0 : Dimensions.get('window').width,
            duration: 250,
            useNativeDriver: false,
        }).start();
    }, [menuVisible]);

    const onPressItem = (screen, sv) => {
        Animated.sequence([
            Animated.timing(sv, {
                toValue: 1.1,
                duration: 100,
                useNativeDriver: false
            }),
            Animated.timing(sv, {
                toValue: 1,
                duration: 100,
                useNativeDriver: false
            })
        ]).start();

        onSelect(screen);
    };

    return (
        <View onLayout={onLayout} style={styles.navBar}>
            {!showBackButton &&
                <Pressable
                    onPress={() => onPressItem('profile', profileIconScale)}
                    style={[
                        styles.navItem,
                        { opacity : getOpacity('profile')}
                    ]}>
                    <Animated.View style={[
                        styles.icon,
                        {transform: [{scale: profileIconScale}]},
                    ]}>
                        <Svg width="19" height="20" viewBox="0 0 19 20" fill="none">
                            <Path
                                d="M9.50092 0.443848C6.76327 0.443848 4.5444 2.66272 4.5444 5.40037V6.22646C4.5444 8.96411 6.76327 11.183 9.50092 11.183C12.2386 11.183 14.4574 8.96411 14.4574 6.22646V5.40037C14.4574 2.66272 12.2386 0.443848 9.50092 0.443848ZM9.49931 13.6612C6.19 13.6612 1.94392 15.4512 0.722133 17.0398C-0.0329107 18.022 0.685849 19.4438 1.92415 19.4438H17.0761C18.3144 19.4438 19.0331 18.022 18.2781 17.0398C17.0563 15.4521 12.8086 13.6612 9.49931 13.6612Z"
                                fill={iconColor}/>
                        </Svg>
                    </Animated.View>
                    <Text style={styles.navText}>Profile</Text>
                </Pressable>
            }

            {showBackButton &&
                <Pressable
                    onPress={() => onBackPressed(navigation)}
                    style={[
                        styles.navItem,
                        { opacity : 1}
                    ]}>
                    {/* not animated because back nav will interrupt animation */}
                    <View style={styles.icon}>
                        <Svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <Path
                                d="M5.18189 0L0.00012207 5.18177L5.18189 10.3635V6.90903H11.2273C13.6326 6.90903 15.5454 8.82186 15.5454 11.2272C15.5454 13.6325 13.6326 15.5453 11.2273 15.5453H8.63641C8.40753 15.5421 8.18028 15.5844 7.96788 15.6697C7.75549 15.7551 7.56217 15.8818 7.39917 16.0425C7.23617 16.2032 7.10674 16.3947 7.01839 16.6059C6.93004 16.817 6.88455 17.0437 6.88455 17.2726C6.88455 17.5015 6.93004 17.7281 7.01839 17.9393C7.10674 18.1504 7.23617 18.3419 7.39917 18.5027C7.56217 18.6634 7.75549 18.7901 7.96788 18.8754C8.18028 18.9608 8.40753 19.0031 8.63641 18.9998H11.2273C15.4996 18.9998 19 15.4994 19 11.2272C19 6.95491 15.4996 3.45451 11.2273 3.45451H5.18189V0Z"
                                fill={iconColor}/>
                        </Svg>
                    </View>
                    <Text style={styles.navText}>Back</Text>
                </Pressable>
            }

            <Pressable
                onPress={() => onPressItem('content', contentIconScale)}
                style={[
                    styles.navItem,
                    {opacity: getOpacity('content')},
                ]}>
                <Animated.View style={[
                    styles.icon,
                    {transform: [{scale: contentIconScale}]},
                ]}>
                    <Svg width="20" height="19" viewBox="0 0 20 19" fill="none">
                        <Path
                            d="M2.87499 7.91722e-05C2.77009 -0.00140444 2.66593 0.0179765 2.56858 0.0570956C2.47123 0.0962146 2.38263 0.154292 2.30792 0.227951C2.23321 0.301611 2.17389 0.389385 2.13339 0.486172C2.0929 0.582958 2.07205 0.686827 2.07205 0.791743C2.07205 0.896658 2.0929 1.00053 2.13339 1.09731C2.17389 1.1941 2.23321 1.28187 2.30792 1.35553C2.38263 1.42919 2.47123 1.48727 2.56858 1.52639C2.66593 1.56551 2.77009 1.58489 2.87499 1.58341H17.1249C17.2298 1.58489 17.334 1.56551 17.4313 1.52639C17.5287 1.48727 17.6173 1.42919 17.692 1.35553C17.7667 1.28187 17.826 1.1941 17.8665 1.09731C17.907 1.00053 17.9279 0.896658 17.9279 0.791743C17.9279 0.686827 17.907 0.582958 17.8665 0.486172C17.826 0.389385 17.7667 0.301611 17.692 0.227951C17.6173 0.154292 17.5287 0.0962146 17.4313 0.0570956C17.334 0.0179765 17.2298 -0.00140444 17.1249 7.91722e-05H2.87499ZM2.08333 3.16673C1.97842 3.16525 1.87427 3.18463 1.77692 3.22375C1.67957 3.26287 1.59097 3.32095 1.51626 3.39461C1.44155 3.46827 1.38222 3.55604 1.34173 3.65283C1.30124 3.74961 1.28039 3.85348 1.28039 3.9584C1.28039 4.06331 1.30124 4.16718 1.34173 4.26397C1.38222 4.36075 1.44155 4.44853 1.51626 4.52219C1.59097 4.59585 1.67957 4.65392 1.77692 4.69304C1.87427 4.73216 1.97842 4.75154 2.08333 4.75006H17.9166C18.0215 4.75154 18.1257 4.73216 18.223 4.69304C18.3204 4.65392 18.409 4.59585 18.4837 4.52219C18.5584 4.44853 18.6177 4.36075 18.6582 4.26397C18.6987 4.16718 18.7195 4.06331 18.7195 3.9584C18.7195 3.85348 18.6987 3.74961 18.6582 3.65283C18.6177 3.55604 18.5584 3.46827 18.4837 3.39461C18.409 3.32095 18.3204 3.26287 18.223 3.22375C18.1257 3.18463 18.0215 3.16525 17.9166 3.16673H2.08333ZM2.08333 6.33339C1.20854 6.33339 0.5 7.04193 0.5 7.91671V17.4167C0.5 18.2915 1.20854 19 2.08333 19H17.9166C18.7914 19 19.4999 18.2915 19.4999 17.4167V7.91671C19.4999 7.04193 18.7914 6.33339 17.9166 6.33339H2.08333Z"
                            fill={iconColor}/>
                    </Svg>
                </Animated.View>
                <Text style={styles.navText}>Content</Text>
            </Pressable>

            <Pressable
                onPress={() => onPressItem('inbox', messagesIconScale)}
                style={[
                    styles.navItem,
                    {opacity: getOpacity('inbox')}
                ]}>
                <Animated.View style={[
                    styles.icon,
                    {transform: [{scale: messagesIconScale}]},
                ]}>
                    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <Path
                            d="M9.24 0.943848C4.137 0.943848 0 4.70453 0 9.34385C0 11.7266 1.09883 13.8696 2.85305 15.3961C2.60143 16.2201 2.07657 17.0397 1.11234 17.7701C1.1118 17.7706 1.11125 17.7712 1.1107 17.7717C1.03117 17.802 0.962707 17.8557 0.914367 17.9257C0.866027 17.9957 0.840093 18.0788 0.84 18.1638C0.84 18.2752 0.88425 18.3821 0.963015 18.4608C1.04178 18.5396 1.14861 18.5838 1.26 18.5838C1.2887 18.5835 1.31729 18.5802 1.34531 18.574C2.97497 18.5693 4.36543 17.8731 5.47148 17.0006C5.99636 17.2141 6.54425 17.3901 7.11539 17.5125C6.86003 16.7901 6.72 16.021 6.72 15.2238C6.72 11.0549 10.4882 7.66385 15.12 7.66385C16.2809 7.66385 17.3875 7.87712 18.3947 8.26268C17.81 4.13492 13.939 0.943848 9.24 0.943848ZM15.12 9.34385C13.3377 9.34385 11.6285 9.96335 10.3682 11.0661C9.108 12.1688 8.4 13.6644 8.4 15.2238C8.4 16.7833 9.108 18.2789 10.3682 19.3816C11.6285 20.4843 13.3377 21.1038 15.12 21.1038C15.9792 21.1027 16.8302 20.9573 17.6269 20.6756C18.657 21.3953 19.9037 21.929 21.3314 21.934C21.3605 21.9404 21.3902 21.9437 21.42 21.9438C21.5314 21.9438 21.6382 21.8996 21.717 21.8208C21.7958 21.7421 21.84 21.6352 21.84 21.5238C21.8399 21.4379 21.8134 21.3541 21.7641 21.2836C21.7148 21.2132 21.6451 21.1596 21.5644 21.1301C20.7956 20.5464 20.3029 19.904 20.0074 19.2499C21.1818 18.161 21.8372 16.7211 21.84 15.2238C21.84 13.6644 21.132 12.1688 19.8718 11.0661C18.6115 9.96335 16.9023 9.34385 15.12 9.34385Z"
                            fill={iconColor}/>
                    </Svg>
                </Animated.View>
                <Text style={styles.navText}>Chat</Text>
            </Pressable>

            <Pressable
                onPress={() => setMenuVisible(true)}
                style={[
                    styles.navItem,
                    { opacity : getOpacity('menu')}
                ]}>
                <Animated.View style={[
                    styles.icon,
                    {transform: [{scale: menuIconScale}]},
                ]}>
                    <Svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                        <Path
                            d="M2.55629 0.943848C1.65219 0.943848 0.919922 1.67612 0.919922 2.58021V5.03476C0.919922 5.48639 1.28647 5.85294 1.7381 5.85294H18.1017C18.5534 5.85294 18.9199 5.48639 18.9199 5.03476V2.58021C18.9199 1.67612 18.1876 0.943848 17.2836 0.943848H2.55629ZM1.7381 7.4893C1.28647 7.4893 0.919922 7.85585 0.919922 8.30748V11.5802C0.919922 12.0318 1.28647 12.3984 1.7381 12.3984H18.1017C18.5534 12.3984 18.9199 12.0318 18.9199 11.5802V8.30748C18.9199 7.85585 18.5534 7.4893 18.1017 7.4893H1.7381ZM1.7381 14.0348C1.28647 14.0348 0.919922 14.4013 0.919922 14.8529V17.3075C0.919922 18.2116 1.65219 18.9438 2.55629 18.9438H17.2836C18.1876 18.9438 18.9199 18.2116 18.9199 17.3075V14.8529C18.9199 14.4013 18.5534 14.0348 18.1017 14.0348H1.7381Z"
                            fill={iconColor}/>
                    </Svg>
                </Animated.View>
                <Text style={styles.navText}>Menu</Text>
            </Pressable>
            <Modal
                transparent={true}
                visible={menuVisible}
                onRequestClose={() => {
                    setMenuVisible(!menuVisible);
                }}
            >
                <Pressable
                    style={styles.overlay}
                    onPress={() => setMenuVisible(false)}
                >
                    <Animated.View
                        style={[
                            styles.menu,
                            {transform: [{translateX: menuAnimation}]}
                        ]}
                    >
                        {/* this pressable exists to prevent the menu from closing when it is pressed */}
                        <Pressable style={{flex: 1}}>
                            <Menu hideMenu={() => setMenuVisible(false)}/>
                        </Pressable>
                    </Animated.View>
                </Pressable>
            </Modal>
        </View>
    );
};

export function DetachedMenuBar({onBackPressed, onLayout = (event) => {}}) {
    const navigation = useNavigation();
    return (
        <MenuBar onLayout={onLayout} onBackPressed={onBackPressed} showBackButton={true} onSelect={(screenName) => {
            navigation.navigate('MainScreen', {
                initialScreen: screenName,
            })
        }}/>
    );
}

const styles = StyleSheet.create({
    navBar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
        backgroundColor: 'white',
        paddingHorizontal: 20,
        zIndex: 2, // added to allow shadow to overlap adjacent view
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        width: '100%',
    },
    navItem: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 5,
        width: 50,
    },
    icon: {
        width: 22,
        height: 22,
        alignItems: 'center',
        justifyContent: 'center',
    },
    navText: {
        fontSize: 12,
        fontFamily: font500,
        color: '#311903',
        letterSpacing: -0.4,
    },
    overlay: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.15)',
    },
    menu: {
        width: '65%',
        height: '100%',
        backgroundColor: 'white',
        position: 'absolute',
        right: 0,
    },
});
