import React from "react";
import {StyleSheet, Text, View} from "react-native";
import {font800} from "../fonts";

export interface PageHeaderProps {
    title: string;
    onBackPressed?: () => void;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
                                                          title,
                                                          onBackPressed
                                                      }) => {
    return (
        <View style={styles.titleRow}>
            <Text style={styles.title}>{title}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 20,
        fontFamily: font800,
        color: '#8f8e8d',
        letterSpacing: -0.5,
    },
    titleRow: {
        paddingVertical: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    backButton: {
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: '#242C3A',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 2,
    },
});
