import React from 'react';
import {Text, StyleSheet, ScrollView, SafeAreaView} from 'react-native';
import {DetachedMenuBar} from "../MenuBar";
import {PageHeader} from "../common/PageHeader";

export const TermsScreen: React.FC = () => {
    return (
        <SafeAreaView style={{flex: 1, backgroundColor: '#f2f2f2'}}>
            {/* @ts-ignore */}
            <DetachedMenuBar />
            <PageHeader title={'Terms of Service'}/>
            <TermsOfService />
        </SafeAreaView>
    )
}

const TermsOfService: React.FC = () => {
    return (
        <ScrollView style={styles.container}>
            <Text style={styles.updated}>Last updated: March 16, 2024</Text>

            <Text style={styles.section}>1. Introduction</Text>
            <Text style={styles.content}>
                Welcome to sqyze.com, a marketing and sales solution for adult content, owned and operated by Punchcard Mafia LLC. By accessing or using our website, you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree to these terms, please do not use our website.
            </Text>

            <Text style={styles.section}>2. User Eligibility</Text>
            <Text style={styles.content}>
                By using sqyze.com, you represent and warrant that you are at least 18 years old and are accessing the website from within the United States.
            </Text>

            <Text style={styles.section}>3. Account Registration</Text>
            <Text style={styles.content}>
                To access certain features of sqyze.com, you must register for an account. When registering, you agree to provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account and its credential(s), and you agree to accept responsibility for all activities that occur under your account.
            </Text>

            <Text style={styles.section}>4. Creator Program</Text>
            <Text style={styles.content}>
                If you opt into the creator program, you must provide proof of age in accordance with 18 U.S. Code § 2257. By uploading content to sqyze.com, you represent and warrant that you have the right to publish the content and that it does not violate any third-party rights or applicable laws.
            </Text>

            <Text style={styles.section}>5. Intellectual Property</Text>
            <Text style={styles.content}>
                All rights to sqyze.com are retained by its parent company Punchcard Mafia LLC. Creators reserve rights to the content they publish on the website. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from sqyze.com without prior written consent.
            </Text>

            <Text style={styles.section}>6. User Conduct</Text>
            <Text style={styles.content}>
                When using sqyze.com, you agree to comply with all applicable laws and regulations. You may not attempt to hack the website, introduce viruses, or engage in any other activity that may disrupt or interfere with the proper functioning of the website.
            </Text>

            <Text style={styles.section}>7. Privacy</Text>
            <Text style={styles.content}>
                Please refer to our Privacy Policy for information on how we collect, use, and disclose information from our users.
            </Text>

            <Text style={styles.section}>8. Termination</Text>
            <Text style={styles.content}>
                We reserve the right to terminate your access to sqyze.com at any time, for any reason, without notice.
            </Text>

            <Text style={styles.section}>9. Governing Law</Text>
            <Text style={styles.content}>
                These Terms of Service shall be governed by and construed in accordance with the laws of the state in which Punchcard Mafia LLC is incorporated.
            </Text>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
    },
    heading: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    updated: {
        fontSize: 14,
        marginBottom: 20,
    },
    section: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 10,
    },
    content: {
        fontSize: 16,
        lineHeight: 24,
        marginBottom: 10,
    },
});
