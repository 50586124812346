import React, {useRef, useState} from "react";
import {Pressable, SafeAreaView, StyleSheet, Text, View} from "react-native";
import {font500, font600} from "../components/fonts";
import {getAuth, signInWithPopup, GoogleAuthProvider} from 'firebase/auth';
import {signIn} from "../lib/auth";
import {useApp} from "../state/AppProvider";
import {saveAuthToken} from "../state/auth";
import {useNavigation} from "@react-navigation/native";
import {AgeVerificationModal} from "../components/AgeVerificationModal";
// @ts-ignore
import LinearGradient from 'react-native-web-linear-gradient';

export interface UnauthenticatedHomeProps {

}

export const UnauthenticatedHome: React.FC<UnauthenticatedHomeProps> = ({}) => {
    const {data, setData} = useApp();
    const navigation = useNavigation();
    if (data.authToken && data.authToken != '') {
        // @ts-ignore
        navigation.navigate('MainScreen');
        return;
    }

    const [ageModalVisible, setAgeModalVisible] = useState(true);
    const auth = useRef(getAuth()).current;
    const provider = useRef(new GoogleAuthProvider()).current;

    const onSignInPressed = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                if (!result) {
                    return;
                }

                const credential = GoogleAuthProvider.credentialFromResult(result);
                if (!credential) {
                    throw new Error('missing credential');
                }
                const user = result.user;
                const firstTime = localStorage.getItem('onboarding') === 'true';
                localStorage.removeItem('onboarding');

                return user.getIdToken().then((idToken) => {
                    return signIn({
                        firebaseAccessToken: idToken,
                        firebaseUserId: user.uid,
                        firstTime: firstTime,
                    }).then((token) => {
                        saveAuthToken(token, data, setData);
                        if (firstTime) {
                            // @ts-ignore
                            navigation.navigate('MainScreen', {
                                initialScreen: 'profile',
                            })
                        } else {
                            // @ts-ignore
                            navigation.navigate('MainScreen', {
                                initialScreen: 'content',
                            });
                        }
                    });
                });
            })
    }

    const onCreateAccountPressed = () => {
        localStorage.setItem('onboarding', 'true');
        onSignInPressed();
    }

    return (
        <SafeAreaView style={styles.container}>
            <LinearGradient
                colors={['#faf5f5', '#f2f2fc']}
                start={{x: 0, y: 0}} end={{x: 0, y: 1}}
                style={{flex: 1}}>
                <AgeVerificationModal visible={ageModalVisible} close={() => setAgeModalVisible(false)}/>
                <View style={styles.main}>
                    <Text style={styles.headerText}>sqyze</Text>
                    <View style={styles.buttons}>
                        <Button
                            text={'Create an account'}
                            onPress={onCreateAccountPressed}/>

                        <Button
                            text={'Sign in'}
                            style={{paddingVertical: 10,}}
                            onPress={onSignInPressed}/>
                    </View>
                </View>
                {
                    /*
                    <View style={styles.footer}>
                    <Footer>
                        <FooterLink onPress={() => {}}>Terms of Use</FooterLink>
                        <FooterLink onPress={() => {}}>Privacy Policy</FooterLink>
                    </Footer>
                </View>
                     */
                }
            </LinearGradient>
        </SafeAreaView>
    );
}

interface ButtonProps {
    text: string;
    onPress: () => void;
    style?: object;
}

const Button: React.FC<ButtonProps> = ({
                                           text,
                                           onPress,
                                           style = {},
                                       }) => {
    return (
        <LinearGradient
            colors={['#fff8f0', '#fff0fa']}
            start={{x: 0, y: 0}} end={{x: 1, y: 0}}
            style={{borderRadius: 10,}}>
            <Pressable
                onPress={onPress}
                style={[
                    styles.button,
                    style,
                ]}>
                <Text style={styles.buttonText}>{text}</Text>
            </Pressable>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: '#ECE1D7',
    },
    main: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 78,
        paddingHorizontal: 60,
        paddingVertical: 10,
    },
    headerText: {
        fontSize: 57,
        fontFamily: font600,
        color: '#f3add0',
        letterSpacing: -2.6,
    },
    buttons: {
        flexDirection: 'column',
        gap: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        width: '100%',
        paddingHorizontal: 7,
    },
    button: {
        paddingHorizontal: 30,
        paddingVertical: 15,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
    },
    buttonText: {
        fontSize: 17,
        fontFamily: font500,
        color: '#8d8d8d',
    },
});
