import React, {useEffect, useState} from "react";
import {StyleSheet, Text, TextInput, View} from "react-native";
import {font400, font500} from "../fonts";
import {apiBaseUrl} from "../../lib/utils";
import Svg, {Path} from "react-native-svg";

export interface SearchBarProps {
    onSearchCompleted: (sr: SearchResult) => void;
    onInputCleared: () => void;
}

export interface SearchResult {
    userFound: boolean;
    user?: HomePageUser;
}

export interface HomePageUser {
    id: string;
    displayName: string;
    avatarUri: string;
    bio: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
                                                        onSearchCompleted,
                                                        onInputCleared
                                                    }) => {
    const [username, setUsername] = useState('');

    useEffect(() => {
        if (username === '') {
            onInputCleared();
        }
    }, [username]);

    const getUser = async (name: string): Promise<SearchResult> => {
        let res = await fetch(`${apiBaseUrl}/search?u=${name}`, {
            method: 'GET',
        });
        if (!res.ok) {
            return {userFound: false};
        }

        let body = await res.json();
        return {
            userFound: true,
            user: {
                id: body.id,
                displayName: body.displayName,
                avatarUri: body.avatarUri,
                bio: body.bio,
            }
        };
    };

    const onDoneTyping = () => {
        getUser(username)
            .then((res) => {
                onSearchCompleted(res);
                if (res.userFound) {
                    setUsername('');
                }
            })
            .catch((err) => {
                console.log(`search failed: ${err}`);
                onSearchCompleted({userFound: false});
            })
    };

    return (
        <View style={styles.container}>
            <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                <Path
                    d="M6.66264 0C2.99086 0 0 2.99086 0 6.66264C0 10.3344 2.99086 13.3253 6.66264 13.3253C8.25935 13.3253 9.72572 12.7583 10.8749 11.8171L14.853 15.7951C14.9144 15.8591 14.9879 15.9101 15.0693 15.9453C15.1507 15.9805 15.2382 15.9991 15.3269 16C15.4155 16.0009 15.5035 15.9841 15.5855 15.9506C15.6676 15.9171 15.7422 15.8675 15.8048 15.8048C15.8675 15.7422 15.9171 15.6676 15.9506 15.5855C15.9841 15.5035 16.0009 15.4155 16 15.3269C15.9991 15.2382 15.9805 15.1507 15.9453 15.0693C15.9101 14.9879 15.8591 14.9144 15.7951 14.853L11.8171 10.8749C12.7583 9.72572 13.3253 8.25935 13.3253 6.66264C13.3253 2.99086 10.3344 0 6.66264 0ZM6.66264 1.33253C9.61428 1.33253 11.9928 3.71101 11.9928 6.66264C11.9928 9.61428 9.61428 11.9928 6.66264 11.9928C3.71101 11.9928 1.33253 9.61428 1.33253 6.66264C1.33253 3.71101 3.71101 1.33253 6.66264 1.33253Z"
                    fill="#919191"/>
            </Svg>
            <TextInput
                style={styles.text}
                value={username}
                onChangeText={setUsername}
                placeholder="Find users"
                maxLength={15}
                autoFocus={true}
                autoCapitalize={'none'}
                onEndEditing={onDoneTyping}
                onSubmitEditing={onDoneTyping}
                placeholderTextColor={'#919191'}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        gap: 10,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.04)',
        paddingHorizontal: 15,
        paddingVertical: 11,
        borderRadius: 20,
    },
    text: {
        color: '#8c8c8c',
        fontFamily: font500,
        fontSize: 15,
        letterSpacing: -1,

        // styles to remove border when focused
        borderWidth: 0,
        outlineWidth: 0,
        outlineStyle: 'none',
        boxShadow: 'none',
    },
});
