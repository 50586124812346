import React from "react";
import {SafeAreaView, StyleSheet, Text, View} from "react-native";
import {PageHeader} from "../../components/common/PageHeader";
import {font600} from "../../components/fonts";
import {useNavigation} from "@react-navigation/native";
import {getVerificationMethod} from "../../lib/verification";
import {useApp} from "../../state/AppProvider";
import {DetachedMenuBar} from "../../components/MenuBar";
import {ProfileButton} from "../../components/profile/common/ProfileButton";

export interface CreatorProgramProps {

}

export const CreatorProgram: React.FC<CreatorProgramProps> = ({}) => {
    const navigation = useNavigation();
    const {data, setData} = useApp();

    const startIdentityVerification = () => {
        const verify = getVerificationMethod(data.authToken);
        verify(data.authToken || '')
            // @ts-ignore
            .then(() => navigation.goBack())
            .catch((ex) => {
                console.log(`auto verify failed: ${ex}`);
                alert('auto verify failed');
            });
    }

    return (
        <SafeAreaView style={{flex: 1, backgroundColor: '#f2f2f2'}}>
            {/* @ts-ignore */}
            <DetachedMenuBar />
            <View style={styles.container}>
                <PageHeader title={'Creator Program'}/>

                <Text style={styles.bodyText}>
                    copy explaining program and relevant legal text
                    more advertisement than documentation or legal requirement
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                </Text>

                <ProfileButton
                    buttonText={'Get Verified'}
                    onPress={startIdentityVerification}/>
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingHorizontal: 15,
        paddingVertical: 10,
        gap: 25,
    },
    textContent: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: 10,
    },
    bodyText: {
        fontSize: 15,
        fontFamily: font600,
        color: '#5d5c5c'
    },
    verifyIdentityButton: {
        borderRadius: 7,
        paddingHorizontal: 18,
        paddingVertical: 11,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.2)'
    },
    verifyIdentityText: {
        fontSize: 14,
        fontFamily: font600,
        color: '#c5cbcb',
    },
});
