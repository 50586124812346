import {PatronCatalogState, State} from "./state";
import React from "react";
import _ from "lodash";
import {getFeed} from "../lib/sku";

export function setFeedState(
    state: PatronCatalogState,
    data: State,
    setData: React.Dispatch<React.SetStateAction<State>>
) {
    if (_.isEqual(state, data.feed)) return;

    setData(prev => ({...prev, feed: state}));
}

export async function refreshFeed(data: State, setData: React.Dispatch<React.SetStateAction<State>>) {
    const pageSize = 25;
    try {
        // @ts-ignore
        let skus = await getFeed(data.authToken || '',
            pageSize, data.feed.skus.length);
        let state = {
            skus: [...data.feed.skus, ...skus],
            serverHasMore: skus.length === pageSize,
            stale: false,
        };
        // @ts-ignore
        setFeedState(state, data, setData);
    } catch (ex) {
        console.log(ex);
        alert(`could not fetch catalog from server: ${ex}`);
    }
}