import React from "react";
import {StyleSheet, Switch, Text, View} from "react-native";

export interface SwitchItemProps {
    enabled: boolean;
    onToggle: (state: boolean) => void;
    enabledText: string;
    disabledText: string;
    style?: object;
    textStyle?: object;
}

export const SwitchItem: React.FC<SwitchItemProps> = ({
                                                   enabled,
                                                   onToggle,
                                                   enabledText,
                                                   disabledText,
                                                   style = {},
                                                   textStyle = {},
                                               }) => {
    return (
        <View style={[styles.switchItem, style]}>
            <Text style={textStyle}>
                {enabled ? enabledText : disabledText}
            </Text>

            <Switch
                value={enabled}
                onValueChange={onToggle} />
        </View>
    )
}

const styles = StyleSheet.create({
    switchItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
});
