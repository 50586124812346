import React, {createContext, useContext, useEffect, useState} from "react";
import {getAuthToken} from "../lib/auth";
import {State} from "./state";
import {refreshProfileData} from "./profileData";
import {sendHeartbeat} from "./heartbeat";
import {refreshInbox} from "./inbox";
import {refreshAssistantState} from "./assistant";

export interface AppContextProps {
    data: State;
    setData: React.Dispatch<React.SetStateAction<State>>;
}

// @ts-ignore
const AppContext = createContext<AppContextProps>();
export const useApp = () => useContext(AppContext);

// @ts-ignore
export const AppProvider = ({children}) => {
    const [data, setData] = useState<State>({
        authToken: getAuthToken() || undefined,
        content: {
            catalogStale: true,
        },
        feed: {
            skus: [],
            serverHasMore: true,
            stale: true,
        }
    });

    const hasValidToken = () => data.authToken && data.authToken !== '';

    // todo: manage auth token here so that it is always
    //       up to date or the user is logged out

    // pre-populate stores for faster page loads
    useEffect(() => {
        if (!hasValidToken()) {
            return;
        }

        refreshProfileData(data ,setData);
        refreshInbox(data, setData);
        refreshAssistantState(data, setData);
    }, [data.authToken]);

    // send heartbeat to server
    useEffect(() => {
        if (!hasValidToken()) {
            return;
        }
        const interval = setInterval(() => {
            sendHeartbeat();
        }, 5000);
        return () => clearInterval(interval);
    }, [data.authToken]);

    return (
        <AppContext.Provider value={{data, setData}}>
            {children}
        </AppContext.Provider>
    );
}