import {
    Pressable,
    SafeAreaView,
    StyleSheet, View,
} from "react-native";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Svg, {Path} from "react-native-svg";
import {chatServerBaseUrl} from "../../lib/utils";
import ReconnectingWebSocket from 'reconnecting-websocket';
import {MessageHistory} from "../../components/inbox/MessageHistory";
import {Avatar} from "../../components/inbox/Avatar";
import {MessageComposer} from "../../components/inbox/MessageComposer";
import {useApp} from "../../state/AppProvider";
import {sendMessage} from "../../lib/inbox";
import { v4 as uuidv4 } from 'uuid';

export default function Conversation({route, navigation}) {
    const {
        userId,
        username,
        avatarUri,
        chatId,
    } = route.params;
    const {data, setData} = useApp();
    const websocketRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [messageDelivered, setMessageDelivered] = useState(true);

    const addToHead = (msg) => setMessages(prev => ([msg, ...prev]));

    useEffect(() => {
        websocketRef.current = new ReconnectingWebSocket(`${chatServerBaseUrl}/chat/${chatId}?token=${data.authToken}`);

        websocketRef.current.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.cid !== chatId) {
                // This websocket conn will receive all messages sent to us, but we're
                // only interested in the ones for this chat.
                return;
            }

            if (message.user_id === data.profile.me.userId && message.message_type === 1) {
                setMessageDelivered(true);
            } else {
                addToHead(message);
            }
        };

        websocketRef.current.onerror = (error) => {
            console.error('chat server error:', error);
        };

        return () => {
            websocketRef.current.close();
        };
    }, []);

    const onSendTextMessage = (message) => {
        setMessageDelivered(false);
        addToHead({
            id: uuidv4(),
            cid: "local",
            user_id: data.profile.me.userId,
            message_type: 1,
            sent_at: Math.floor(new Date().getTime() / 1000),
            content: message,
        });

        sendMessage(data.authToken || '', chatId, message)
            .catch(ex => {
                console.log(`failed to send message to server: ${ex}`);
                alert('Cannot connect to server');
            });
    };

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.topBar}>
                <View style={styles.backButtonContainer}>
                    <Pressable
                        //style={styles.button}
                        onPress={() => navigation.goBack()}>
                        <Svg width="27" height="20" viewBox="0 0 27 20" fill="none">
                            <Path
                                d="M9.99875 0C9.6144 0 9.22963 0.149341 8.93672 0.443001L0.44049 8.93923C-0.14683 9.52655 -0.14683 10.4775 0.44049 11.0633L8.93672 19.5595C9.52404 20.1468 10.475 20.1468 11.0608 19.5595L11.1899 19.4304C11.7772 18.8431 11.7772 17.8922 11.1899 17.3064L5.38684 11.5034H25.0197C25.8489 11.5034 26.5218 10.8304 26.5218 10.0013C26.5218 9.1721 25.8489 8.49916 25.0197 8.49916H5.38684L11.1899 2.69614C11.7772 2.10882 11.7772 1.1579 11.1899 0.572087L11.0608 0.443001C10.7671 0.149341 10.3831 0 9.99875 0Z"
                                fill="#646465"/>
                        </Svg>
                    </Pressable>
                </View>

                <Avatar userId={userId} displayName={username} uri={avatarUri}/>
                <View style={styles.backButtonContainer}/>
            </View>

            <MessageHistory
                peerUserId={userId}
                peerDisplayName={username}
                peerAvatarUri={avatarUri}
                chatId={chatId}
                messageDelivered={messageDelivered}
                messages={messages}
                setMessages={setMessages}/>

            <MessageComposer onSendText={onSendTextMessage} peerUserId={userId} chatId={chatId}/>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f2f2f2',
    },
    topBar: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 12,
        paddingBottom: 5,
        paddingHorizontal: 10,
        alignItems: 'center',
    },
    backButtonContainer: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: 38,
        height: 38,
        borderRadius: 19,
        backgroundColor: '#fcfcfc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 3,
        paddingRight: 5,
        paddingVertical: 5,
    },
});