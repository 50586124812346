export const BoldHeader = '#31708e';

export const InteractiveWidget = '#5fbaff';

export const InteractiveText = '#49a1ff';

export const bliss = '#464264';

// base layer for 3-level screens
export const layer100 = '#272640';
// base layer for 2-level screens
// second layer for 3-level screens
export const layer200 = '#3A3952';
// highest layer for any screen
export const layer300 = '#504F6C';

// Added code
export const neutral100 = '#F4F2FF';
export const neutral200 = '#C9C3E5';
export const neutral300 = '#A199CC';
export const neutral400 = '#7E74B2';
export const neutral500 = '#605980';
export const neutral600 = '#443D66';
export const neutral700 = '#2D264D';
export const neutral800 = '#191433';
export const neutral900 = '#0B081A';

export const brand100 = '#F5F2FF';
export const brand300 = '#A599CC';
export const brand400 = '#765CCC';
export const brand500 = '#613FD1';
export const brand600 = '#4524B2';
export const brand700 = '#362273';
export const brand900 = '#140640';

export const red100 = '#FFF2F2';
export const red300 = '#CC9999';
export const red400 = '#B27474';
export const red500 = '#D13F3F';
export const red700 = '#732222';
export const red900 = '#400606';

export const green100 = '#F2FFF3';
export const green300 = '#99CC9E';
export const green400 = '#7CBF83';
export const green500 = '#3FD14D';
export const green700 = '#22732A';
export const green900 = '#06400C';

export const orange100 = '#FFF5F2';
export const orange300 = '#CCA599';
export const orange500 = '#D1623F';
export const orange700 = '#733522';
export const orange900 = '#401406';

export const blue100 = '#F2F2FF';
export const blue300 = '#999BCC';
export const blue400 = '#7476B2';
export const blue500 = '#3F45D1';
export const blue700 = '#222573';
export const blue900 = '#060840';