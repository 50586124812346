import React, {useState} from "react";
import {StyleSheet, Text, TextInput, View} from "react-native";
import {font400} from "../../fonts";

export interface LongformInputProps {
    style?: object;
    header: string;
    headerStyle?: object;
    placeholder: string;
    text: string;
    setText: React.Dispatch<React.SetStateAction<string>>;
}

export const LongformInput: React.FC<LongformInputProps> = ({
                                                                header,
                                                                text, setText,
                                                                style = {},
                                                                headerStyle = {},
                                                                placeholder,
                                                            }) => {
    const [focused, setFocused] = useState(false);

    // todo: add help button to the right of the header, help text can be passed as props
    return (
        <View style={[styles.container, style]}>
            <Text style={headerStyle}>{header}</Text>
            <View style={[
                styles.inputContainer,
                { borderColor: focused ? 'black' : '#BABABA'}
            ]}>
                <TextInput
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}
                    value={text}
                    onChangeText={setText}
                    placeholder={placeholder}
                    style={styles.input}
                    multiline={true}/>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 10,
        width: '100%',
    },
    inputContainer: {
        width: '100%',
        height: 200,
        borderRadius: 8,
        borderWidth: 1,
        padding: 10,
    },
    input: {
        fontSize: 14,
        color: 'black',
        fontFamily: font400,
        flex: 1,
        borderWidth: 0,
        outlineWidth: 0,
        outlineStyle: 'none',
        boxShadow: 'none',
    },
});
