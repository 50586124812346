import React from "react";
import {Pressable, StyleSheet, Text, View} from "react-native";

export interface MenuItemProps {
    text: string;
    Icon: any;
    onPress: () => void;
    textStyle?: object;
}

export const MenuItem: React.FC<MenuItemProps> = ({
                                                      text,
                                                      Icon,
                                                      onPress,
                                                      textStyle,
                                                  }) => {
    return (
        <Pressable
            onPress={onPress}
            style={styles.container}>
            <Text style={textStyle}>{text}</Text>

            <View style={styles.icon}>
                <Icon />
            </View>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 5,
    },
    icon: {
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
