import React from "react";
import {Image, Pressable, StyleSheet, Text, View} from "react-native";
import {font300, font400} from "../fonts";
import {makeAvatarURL} from "../../lib/utils";
import {useNavigation} from "@react-navigation/native";

export interface ProfileProps {
    userId: string;
    avatarUri: string;
    displayName: string;
    bio: string;
}

export const Profile: React.FC<ProfileProps> = ({
                                                    userId,
                                                    avatarUri,
                                                    bio,
                                                    displayName,
                                                }) => {
    const navigation = useNavigation();
    return (
        <Pressable
            onPress={() => {
                // @ts-ignore
                navigation.navigate('PeerProfile', {
                    userId: userId,
                });
            }}
            style={styles.container}>
            <View
                style={[
                    styles.avatarShadow,
                    styles.avatar,
                ]}>
                <Image
                    resizeMode="cover"
                    source={{uri: makeAvatarURL(avatarUri)}}
                    // @ts-ignore
                    style={styles.avatar}/>
            </View>

            <View style={styles.textContainer}>
                <Text style={styles.displayNameText}>{displayName}</Text>
                <View style={styles.bioSection}>
                    {bio.replace(/\n+/g, '\n \n').split("\n").map((item, index) => (
                        <Text key={index} style={styles.bioText}>{item}</Text>
                    ))}
                </View>
            </View>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        gap: 5,
        flexDirection: 'column',
        paddingHorizontal: 30,
        paddingVertical: 25,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    avatarShadow: {
        boxShadow: '0px 4px 7px rgb(0, 0, 0, 0.5)',
        marginBottom: 10,
    },
    avatar: {
        width: 170,
        height: 170,
        borderRadius: 85,
    },
    displayNameText: {
        color: '#F4F2FF',
        fontSize: 22,
        fontFamily: font400,
        textAlign: 'center',
    },
    textSection: {
        flexDirection: 'column',
    },
    bioSection: {
        paddingVertical: 10,
    },
    textContainer: {},
    bioText: {
        color: '#CFCED9',
        fontSize: 12,
        fontFamily: font300,
        textAlign: 'center',
    },
});
