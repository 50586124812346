import React, {useEffect, useState} from "react";
import {Dimensions, StyleSheet, Text, View} from "react-native";
import {font300, font400} from "../fonts";

export interface TitleProps {

}

export const Title: React.FC<TitleProps> = ({}) => {
    return (
        <View style={styles.container}>
            <View style={styles.title}>
                <Text style={styles.titleText}>sqyze</Text>
            </View>

            <View style={styles.subtitle}>
                <Text style={styles.subtitleStaticText}>it's giving</Text>
                <DynamicText
                    options={dynamicText}
                    intervalMs={800} />
            </View>
        </View>
    );
}

interface DynamicTextProps {
    options: string[];
    intervalMs: number;
}

const DynamicText: React.FC<DynamicTextProps> = ({
                                                     options,
                                                     intervalMs = 2000
                                                 }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % options.length);
        }, intervalMs);
        return () => clearInterval(timer);
    }, [intervalMs, options.length]);

    return (
        <Text style={styles.subtitleDynamicText}>{options[activeIndex]}.</Text>
    );
}

const styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    title: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleText: {
        fontSize: 25,
        fontFamily: font300,
        color: '#e6e4f1',
        textShadowColor: 'rgba(0, 0, 0, 0.6)',
        textShadowOffset: {width: 2, height: 2},
        textShadowRadius: 10
    },
    subtitle: {
        flexDirection: 'row',
        gap: 4,
        position: 'absolute',
        left: Dimensions.get('window').width / 2 + 12,
        top: 26,
        justifyContent: 'center',
        alignItems: 'center',
    },
    subtitleStaticText: {
        fontSize: 11,
        fontFamily: font300,
        color: '#958fbe',
    },
    subtitleDynamicText: {
        fontSize: 12,
        fontFamily: font400,
        color: '#5bcede',
    },
});

const dynamicText = [
    'rainbows',
    'summer fridays',
    'open roads',
    'kittens',
    'sleeping in',
    'ice cream',
    'sexy loungewear',
    'fetch',
    'beach days',
    'pay day',
    "fresh sneaks"
];
