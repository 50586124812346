import React from "react";
import {Image, StyleSheet, Text, View} from "react-native";
import {makeAvatarURL} from "../../../lib/utils";
import {EditAvatarButton} from "../personal/edit/EditAvatarButton";
import {font400, font600} from "../../fonts";

export interface ProfileHeaderProps {
    avatarUri: string;
    displayName: string;
    username: string;
}

export const ProfileHeader: React.FC<ProfileHeaderProps> = ({
    avatarUri,
    displayName,
    username,
                                                            }) => {
    return (
        <View style={styles.container}>
            <View style={styles.avatarContainer}>
                <View style={[
                    styles.avatar,
                    styles.avatarShadow,
                ]}>
                    <Image
                        resizeMode="contain"
                        source={{uri: makeAvatarURL(avatarUri)}}
                        // @ts-ignore
                        style={styles.avatar}
                    />
                </View>
            </View>

            <View style={styles.namesContainer}>
                <Text style={styles.displayNameText}>{displayName}</Text>
                <Text style={styles.usernameText}>@{username}</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        gap: 8,
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatarContainer: {
        paddingBottom: 10,
        alignItems: 'center',
    },
    avatar: {
        width: 160,
        height: 160,
        borderRadius: 80,
    },
    avatarShadow: {
        // @ts-ignore
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)'
    },
    namesContainer: {
        flexDirection: 'column',
        gap: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    displayNameText: {
        fontSize: 24,
        fontFamily: font600,
        color: '#2B2929'
    },
    usernameText: {
        fontSize: 12,
        fontFamily: font400,
        color: '#918D98'
    },
});
