import React, {useEffect} from 'react';
import {Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import Svg, {Path} from "react-native-svg";
import {
    green300,
    layer100,
    layer300,
    neutral100,
    neutral200,
    neutral300,
    neutral400,
    neutral600,
    red300, red500
} from "../colors";
import {font300, font400, font500} from "../fonts";

export interface MessageInputProps {
    messageText: string;
    setMessageText: React.Dispatch<React.SetStateAction<string>>;
}

export enum Type {
    TEXT,
    TIP,
    REQUEST,
    MEDIA
}

export const MessageInput: React.FC<MessageInputProps> = ({
                                                              messageText,
                                                              setMessageText,
                                                          }) => {

    return (
        <TextInput
            multiline={true}
            style={styles.input}
            value={messageText}
            onChangeText={setMessageText}
            placeholder="Message"
            placeholderTextColor={'#A8A8A8'}/>
    );
}

const styles = StyleSheet.create({
    input: {
        flex: 1,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        color: '#7B7B7B',
        fontFamily: font500,
        verticalAlign: 'middle',

        // hide scrollbar in browser
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        // end hide scrollbar in browser

        // styles to remove border of input when focused
        // @ts-ignore
        outlineWidth: 0,
        outlineStyle: 'none',
        boxShadow: 'none',
    },
});
