import React from "react";
import {Image, ImageResizeMode} from "react-native";
import {apiBaseUrl} from "../../lib/utils";
import ReactPlayer from 'react-player';
import {getMediaType, MediaType} from "../../lib/mediatype";

export interface GenericMediaProps {
    uri: string;
    token: string;

    imageResizeMode?: ImageResizeMode;
    imageStyle?: object;

    videoStyle?: object;
}

export const GenericMedia: React.FC<GenericMediaProps> = ({
                                                            uri,
                                                            token,
                                                            imageResizeMode = 'contain',
                                                            imageStyle = {},
                                                            videoStyle = {},
                                                        }) => {
    switch (getMediaType(uri)) {
        case MediaType.IMAGE:
            return <Image
                resizeMode={imageResizeMode}
                source={{uri: makeURL(uri, token)}}
                style={imageStyle}/>

        case MediaType.VIDEO:
            return <ReactPlayer
                url={makeURL(uri, token)}
                controls={true}
                style={videoStyle}
                // @ts-ignore
                width={videoStyle.width}
                // @ts-ignore
                height={videoStyle.height}
            />

        default:
            throw new Error(`unknown media type: ${getMediaType(uri)}`);
    }
}

function makeURL(uri: string, token: string): string {
    return `${apiBaseUrl}${uri}?token=${token}`;
}
