import {StyleSheet, Text, View} from "react-native";
import formatTransactionTime from "./formatTransactionTime";
import {font400, font500, font600} from "../fonts";
import React from "react";

export interface TransactionRecordProps {
    dateTime: Date;
    summary: string;
    status: string;
}

export const TransactionRecord: React.FC<TransactionRecordProps> = ({
                                              dateTime,
                                              summary,
                                              status,
                                          }) => {
    return (
        <View style={styles.container}>
            <Text style={styles.dateText}>{formatTransactionTime(dateTime)}</Text>

            <View style={styles.detailsSection}>
                <Text style={styles.summaryText}>{summary}</Text>
                <Text style={styles.statusText}>{status}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column',
        paddingHorizontal: 15,
        paddingVertical: 10,
        gap: 16,
        backgroundColor: '#fcfcfc',
        borderRadius: 20,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.11)',
        margin: 5,
    },
    dateText: {
        fontSize: 12,
        color: '#666161',
        fontFamily: font400,
        textAlign: 'center',
    },
    detailsSection: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    summaryText: {
        color: '#2B2929',
        fontSize: 14,
        fontFamily: font500,
        textAlign: 'left',
    },
    statusText: {
        // todo: use red300 if status is a failure type
        //       status types don't really exist right now and that's
        //       why it isn't added yet
        color: '#56ad7e',
        fontSize: 14,
        fontFamily: font600,
        textAlign: 'right',
    },
});