import React from 'react';
import {View, StyleSheet, SafeAreaView} from 'react-native';
import {useNavigation} from "@react-navigation/native";
import {DetachedMenuBar} from "../../components/MenuBar";
import sendDeleteRequest from "../../lib/deleteUser";
import Swal from "sweetalert2";
import {deleteAuthToken, getAuthToken} from "../../lib/auth";
import {font500} from "../../components/fonts";
import {useApp} from "../../state/AppProvider";
import {clearAuthToken} from "../../state/auth";
import Svg, {Path} from "react-native-svg";
import {PageHeader} from "../../components/common/PageHeader";
import {MultiButton, MultiButtonRow} from "../../components/common/MultiButton";

export default function Settings() {
    const navigation = useNavigation();
    const {data, setData} = useApp();

    const signOut = () => {
        deleteAuthToken();
        clearAuthToken(data, setData);
        navigation.navigate('UnauthenticatedHome');
    }

    const deleteAccount = async () => {
        let token = getAuthToken();
        if (!token || token === '') {
            console.log('cannot delete account: missing token')
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await sendDeleteRequest(token);
                // todo: error message if request fails
                navigation.navigate('Home');
            }
        });
    };

    return (
        <SafeAreaView style={{flex: 1, backgroundColor: '#f2f2f2'}}>
            <DetachedMenuBar onBackPressed={(n) => n.goBack()} />
            <View style={styles.container}>
                <PageHeader title={'Settings'}/>

                <MultiButton>
                    <MultiButtonRow
                        Icon={() => (
                            <Svg width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <Path
                                    d="M10.768 0.5C7.28324 0.5 5.83907 2.73526 5.83907 5.42892C5.83907 6.33584 6.27228 7.24677 6.27228 7.24677C6.09812 7.34699 5.81171 7.66513 5.884 8.23031C6.01872 9.28428 6.47565 9.55267 6.76645 9.57485C6.87735 10.5582 7.93386 11.816 8.30353 11.9992V13.6422C7.48204 16.1066 0.910156 14.4637 0.910156 20.2141H10.3765C10.1029 19.4419 9.9465 18.6154 9.9465 17.7496C9.9465 14.8424 11.6286 12.3348 14.0684 11.1264C14.4044 10.6581 14.712 10.0817 14.7695 9.57485C15.0603 9.55267 15.5173 9.28428 15.652 8.23031C15.7243 7.66431 15.4379 7.34699 15.2637 7.24677C15.2637 7.24677 15.6969 6.42373 15.6969 5.42892C15.6969 3.43435 14.914 1.73223 13.2324 1.73223C13.2324 1.73223 12.6484 0.5 10.768 0.5ZM17.3399 11.9992C14.164 11.9992 11.5895 14.5737 11.5895 17.7496C11.5895 20.9255 14.164 23.5 17.3399 23.5C20.5157 23.5 23.0903 20.9255 23.0903 17.7496C23.0903 14.5737 20.5157 11.9992 17.3399 11.9992ZM15.6969 15.2851C15.907 15.2851 16.1171 15.3652 16.2777 15.5258L17.3399 16.588L18.402 15.5258C18.7232 15.2046 19.2425 15.2046 19.5637 15.5258C19.8849 15.847 19.8849 16.3662 19.5637 16.6874L18.5015 17.7496L19.5637 18.8118C19.8849 19.133 19.8849 19.6522 19.5637 19.9734C19.4035 20.1336 19.1931 20.2141 18.9828 20.2141C18.7725 20.2141 18.5622 20.1336 18.402 19.9734L17.3399 18.9112L16.2777 19.9734C16.1175 20.1336 15.9072 20.2141 15.6969 20.2141C15.4866 20.2141 15.2763 20.1336 15.1161 19.9734C14.7949 19.6522 14.7949 19.133 15.1161 18.8118L16.1782 17.7496L15.1161 16.6874C14.7949 16.3662 14.7949 15.847 15.1161 15.5258C15.2767 15.3652 15.4868 15.2851 15.6969 15.2851Z"
                                    fill="#423B3B"/>
                            </Svg>
                        )}
                        text={'Blocked Users'}
                        style={styles.button}
                        textStyle={styles.text}
                        onPress={() => navigation.navigate('Settings.BlockedUsers')}/>

                    <MultiButtonRow
                        Icon={() => (
                            <Svg width="18" height="24" viewBox="0 0 18 24"
                                 fill="none">
                                <Path
                                    d="M8.08965 0.500377C8.02613 0.502227 7.96299 0.510915 7.90133 0.526293L0.824647 2.29546C0.633129 2.34329 0.463107 2.45379 0.341635 2.60938C0.220163 2.76498 0.154219 2.95674 0.154297 3.15413V20.8458C0.154219 21.0432 0.220163 21.235 0.341635 21.3906C0.463107 21.5462 0.633129 21.6567 0.824647 21.7045L7.90133 23.4737C8.03177 23.5062 8.1679 23.5086 8.29939 23.4807C8.43089 23.4527 8.55428 23.3952 8.66021 23.3124C8.76613 23.2296 8.8518 23.1238 8.91071 23.003C8.96961 22.8821 9.0002 22.7495 9.00015 22.615H16.9614C17.4497 22.615 17.846 22.2187 17.846 21.7304V2.26955C17.846 1.78126 17.4497 1.38496 16.9614 1.38496H9.00015C9.00019 1.26659 8.97647 1.14941 8.9304 1.04037C8.88433 0.931324 8.81685 0.832637 8.73194 0.75015C8.64704 0.667663 8.54645 0.603054 8.43612 0.560151C8.32579 0.517247 8.20798 0.496921 8.08965 0.500377ZM7.23098 2.51834V21.4816L1.92347 20.1548V3.84522L7.23098 2.51834ZM5.46181 11.1154C5.2272 11.1154 5.00221 11.2086 4.83631 11.3745C4.67042 11.5404 4.57723 11.7654 4.57723 12C4.57723 12.2346 4.67042 12.4596 4.83631 12.6255C5.00221 12.7914 5.2272 12.8846 5.46181 12.8846C5.69642 12.8846 5.92142 12.7914 6.08731 12.6255C6.2532 12.4596 6.3464 12.2346 6.3464 12C6.3464 11.7654 6.2532 11.5404 6.08731 11.3745C5.92142 11.2086 5.69642 11.1154 5.46181 11.1154Z"
                                    fill="#423B3B"/>
                            </Svg>
                        )}
                        text={'Log out'}
                        style={styles.button}
                        textStyle={styles.text}
                        onPress={signOut}/>

                    <MultiButtonRow
                        Icon={() => (
                            <Svg width="20" height="24" viewBox="0 0 20 24"
                                 fill="none">
                                <Path
                                    d="M9.98424 0.500119C9.72515 0.504169 9.47826 0.610846 9.29774 0.796738C9.11722 0.982629 9.01783 1.23255 9.02138 1.49164V1.98072H3.64156C3.51199 1.97896 3.38336 2.00296 3.26314 2.05133C3.14292 2.09969 3.03352 2.17146 2.94127 2.26247C2.84902 2.35347 2.77577 2.4619 2.72578 2.58144C2.67578 2.70099 2.65003 2.82928 2.65003 2.95887H1.19618C1.06657 2.95703 0.937878 2.98098 0.817596 3.02931C0.697315 3.07765 0.58784 3.1494 0.495532 3.24042C0.403224 3.33143 0.329926 3.43988 0.279895 3.55946C0.229865 3.67905 0.204102 3.80739 0.204102 3.93701C0.204102 4.06664 0.229865 4.19498 0.279895 4.31457C0.329926 4.43415 0.403224 4.5426 0.495532 4.63361C0.58784 4.72463 0.697315 4.79638 0.817596 4.84472C0.937878 4.89305 1.06657 4.917 1.19618 4.91516H18.8029C18.9325 4.917 19.0612 4.89305 19.1815 4.84472C19.3017 4.79638 19.4112 4.72463 19.5035 4.63361C19.5958 4.5426 19.6691 4.43415 19.7192 4.31457C19.7692 4.19498 19.7949 4.06664 19.7949 3.93701C19.7949 3.80739 19.7692 3.67905 19.7192 3.55946C19.6691 3.43988 19.5958 3.33143 19.5035 3.24042C19.4112 3.1494 19.3017 3.07765 19.1815 3.02931C19.0612 2.98098 18.9325 2.95703 18.8029 2.95887H17.349C17.349 2.82928 17.3233 2.70099 17.2733 2.58144C17.2233 2.4619 17.15 2.35347 17.0578 2.26247C16.9655 2.17146 16.8561 2.09969 16.7359 2.05133C16.6157 2.00296 16.4871 1.97896 16.3575 1.98072H10.9777V1.49164C10.9795 1.36078 10.955 1.23088 10.9057 1.10965C10.8564 0.988416 10.7832 0.878312 10.6906 0.785856C10.5979 0.693401 10.4877 0.620473 10.3664 0.571392C10.245 0.522311 10.1151 0.498075 9.98424 0.500119ZM1.19618 6.87146L2.94997 21.773C3.06539 22.758 3.89914 23.5 4.89099 23.5H15.1081C16.0999 23.5 16.9327 22.758 17.0491 21.773L18.8029 6.87146H1.19618Z"
                                    fill="#423B3B"/>
                            </Svg>
                        )}
                        text={'Delete Account'}
                        style={styles.button}
                        textStyle={styles.text}
                        onPress={deleteAccount}/>
                </MultiButton>
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 15,
        paddingVertical: 10,
        flexDirection: 'column',
        gap: 25,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    button: {
        backgroundColor: '#e3e6e6',
    },
    text: {
        fontSize: 16,
        fontFamily: font500,
        color: '#423B3B',
    },
});
