import React, {useState} from "react";
import {ScrollView, StyleSheet, View} from "react-native";
import {AccountType, User} from "../../../../lib/profile";
import {ProfileHeader} from "../../common/ProfileHeader";
import Svg, {Path} from "react-native-svg";
import {getConversationId} from "../../../../lib/inbox";
import {useNavigation} from "@react-navigation/native";
import {DetachedMenuBar} from "../../../MenuBar";
import {ProfileButton} from "../../common/ProfileButton";
import {MoreModal} from "../common/MoreModal";

export interface PeerPatronProfilePropsV2 {
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
}

export const PeerPatronProfileV2: React.FC<PeerPatronProfilePropsV2> = ({
                                                                            user,
                                                                            setUser,
                                                                        }) => {
    const navigation = useNavigation();
    const [moreModalVisible, setMoreModalVisible] = useState(false);

    const onMessage = () => {
        const navigate = (chatId: string) => {
            // @ts-ignore
            navigation.navigate('Conversation', {
                userId: user.userId,
                username: user.displayName,
                avatarUri: user.avatarUri,
                chatId: chatId,
            });
        }

        getConversationId(user.userId).then(navigate).catch(ex => {
            alert('server error');
            console.log('unable to start conversation with user: ', ex);
        });
    };

    return (
        <ScrollView style={{flex: 1, backgroundColor: '#f2f2f2'}}>
            {/* @ts-ignore */}
            <DetachedMenuBar/>
            <View style={styles.container}>
                <ProfileHeader
                    avatarUri={user.avatarUri}
                    displayName={user.displayName}
                    username={user.username}/>

                <View style={styles.buttonsContainer}>
                    <ProfileButton
                        buttonText={'Chat'}
                        buttonStyle={{paddingHorizontal: 30}}
                        Icon={(props: { color: string }) => (
                            <Svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                                <Path
                                    d="M7.23995 0C3.35195 0 0.199951 2.86528 0.199951 6.4C0.199951 8.21543 1.03715 9.84817 2.3737 11.0112C2.18199 11.6391 1.7821 12.2635 1.04745 12.82L1.0462 12.8212C0.985608 12.8443 0.933442 12.8852 0.896612 12.9385C0.859781 12.9919 0.840022 13.0552 0.839951 13.12C0.839951 13.2049 0.873665 13.2863 0.933677 13.3463C0.993689 13.4063 1.07508 13.44 1.15995 13.44C1.18182 13.4397 1.2036 13.4372 1.22495 13.4325C2.46659 13.4289 3.52599 12.8985 4.3687 12.2337C4.7686 12.3964 5.18604 12.5305 5.6212 12.6237C5.42664 12.0733 5.31995 11.4874 5.31995 10.88C5.31995 9.95968 5.56679 9.09248 5.99495 8.32H4.67995C4.32667 8.32 4.03995 8.03328 4.03995 7.68C4.03995 7.32672 4.32667 7.04 4.67995 7.04H6.9637C7.48338 6.51904 8.1036 6.0832 8.8012 5.76H4.67995C4.32667 5.76 4.03995 5.47328 4.03995 5.12C4.03995 4.76672 4.32667 4.48 4.67995 4.48H9.79995C10.1532 4.48 10.44 4.76672 10.44 5.12C10.44 5.16288 10.4239 5.20093 10.4162 5.24125C10.8373 5.16253 11.2732 5.12 11.72 5.12C12.6044 5.12 13.4476 5.28249 14.215 5.57625C13.7695 2.43129 10.8201 0 7.23995 0ZM11.72 6.4C8.89243 6.4 6.59995 8.40576 6.59995 10.88C6.59995 13.3542 8.89243 15.36 11.72 15.36C12.3958 15.36 13.0392 15.242 13.63 15.0337C14.4148 15.582 15.3647 15.9887 16.4525 15.9925C16.4746 15.9974 16.4972 15.9999 16.52 16C16.6048 16 16.6862 15.9663 16.7462 15.9063C16.8062 15.8463 16.84 15.7649 16.84 15.68C16.8398 15.6145 16.8197 15.5506 16.7821 15.497C16.7446 15.4433 16.6914 15.4025 16.63 15.38C16.0442 14.9353 15.6688 14.4458 15.4437 13.9475C16.3069 13.1456 16.84 12.0686 16.84 10.88C16.84 8.40576 14.5475 6.4 11.72 6.4ZM9.79995 8.96H13.64C13.9932 8.96 14.28 9.24672 14.28 9.6C14.28 9.95328 13.9932 10.24 13.64 10.24H9.79995C9.44667 10.24 9.15995 9.95328 9.15995 9.6C9.15995 9.24672 9.44667 8.96 9.79995 8.96ZM9.79995 11.52H13.64C13.9932 11.52 14.28 11.8067 14.28 12.16C14.28 12.5133 13.9932 12.8 13.64 12.8H9.79995C9.44667 12.8 9.15995 12.5133 9.15995 12.16C9.15995 11.8067 9.44667 11.52 9.79995 11.52Z"
                                    fill={props.color}/>
                            </Svg>
                        )}
                        // @ts-ignore
                        onPress={onMessage}/>

                    <ProfileButton
                        Icon={(props: { color: string }) => (
                            <Svg width="18" height="5" viewBox="0 0 18 5" fill="none">
                                <Path
                                    d="M2.33337 0.5C1.22871 0.5 0.333374 1.39533 0.333374 2.5C0.333374 3.60467 1.22871 4.5 2.33337 4.5C3.43804 4.5 4.33337 3.60467 4.33337 2.5C4.33337 1.39533 3.43804 0.5 2.33337 0.5ZM9.00004 0.5C7.89537 0.5 7.00004 1.39533 7.00004 2.5C7.00004 3.60467 7.89537 4.5 9.00004 4.5C10.1047 4.5 11 3.60467 11 2.5C11 1.39533 10.1047 0.5 9.00004 0.5ZM15.6667 0.5C14.562 0.5 13.6667 1.39533 13.6667 2.5C13.6667 3.60467 14.562 4.5 15.6667 4.5C16.7714 4.5 17.6667 3.60467 17.6667 2.5C17.6667 1.39533 16.7714 0.5 15.6667 0.5Z"
                                    fill={props.color}/>
                            </Svg>
                        )}
                        onPress={() => setMoreModalVisible(true)}/>
                </View>

                <MoreModal
                    user={user}
                    setUser={setUser}
                    visible={moreModalVisible}
                    setVisible={setMoreModalVisible}/>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 65,
        flexDirection: 'column',
        gap: 15,
    },
    buttons: {
        flexDirection: 'column',
        gap: 25,
        paddingVertical: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonsContainer: {
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    bottomButtons: {
        flexDirection: 'row',
        gap: 10,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonTextStyle: {
        fontSize: 14,
    },
    badButtonStyle: {
        borderColor: '#FA6A6A',
        width: 110,
        height: 35,
    },
});
