const assistantApiUrl = 'https://assistant-api-krpybarrda-uc.a.run.app';

export class NotFoundError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export interface GetAssistantResponse {
    identity: {
        id: string;
        name: string;
        creatorId: string;
    }

    settings: {
        enabled: boolean;
    }

    creatorKnowledge: {
        servicesOffered: string;
        servicesNotOffered: string;
        servicePriceDetails: string;
    }
}

export async function getAssistant(token: string): Promise<GetAssistantResponse> {
    const res = await fetch(`${assistantApiUrl}/assistants`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });

    if (!res.ok) {
        if (res.status === 404) {
            throw new NotFoundError('assistant not found');
        } else {
            throw new Error(`got bad status from api: ${res.status}`);
        }
    }
    return await res.json();
}

export interface SaveAssistantRequest {
    enabled: boolean;
}

export interface SaveAssistantResponse {
    assistantId: string;
}

export async function saveAssistant(token: string, req: SaveAssistantRequest): Promise<SaveAssistantResponse> {
    const res = await fetch(`${assistantApiUrl}/assistants`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(req),
    });

    if (!res.ok) {
        throw new Error(`got bad status from api: ${res.status}`);
    }
    return await res.json();
}

export interface SaveServiceDetailsRequest {
    servicesOffered: string;
    servicesNotOffered: string;
    priceDetails: string;
}

export async function saveServiceDetails(token: string, req: SaveServiceDetailsRequest) {
    const res = await fetch(`${assistantApiUrl}/creators/services`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(req),
    });

    if (!res.ok) {
        throw new Error(`got bad status from api: ${res.status}`);
    }
}
