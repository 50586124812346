import {SafeAreaView, StyleSheet, Text, View} from "react-native";
import React, {ReactNode, useState} from "react";
import {font600} from "../../components/fonts";
import {EditableAvatar} from "../../components/profile/personal/edit/EditableAvatar";
import {useApp} from "../../state/AppProvider";
import {setProfileData} from "../../state/profileData";
import {PrettyTextInput} from "../../components/profile/personal/edit/PrettyTextInput";
import {updateProfile} from "../../lib/profile";
import {DetachedMenuBar} from "../../components/MenuBar";
import type {NavigationProp} from "@react-navigation/core/src/types";

export interface EditProfileProps {

}

export const EditProfile: React.FC<EditProfileProps> = ({}) => {
    const {data, setData} = useApp();
    if (!data.profile?.me) {
        return <SafeAreaView />
    }

    const [displayName, setDisplayName] = useState(data.profile.me.displayName);
    const [username, setUsername] = useState(data.profile.me.username);

    const saveProfile = (navigation: NavigationProp<ReactNavigation.RootParamList>) => {
        if (displayName === data.profile?.me.displayName
            && username === data.profile?.me.username) {
            // nothing to save
            navigation.goBack();
        } else {
            updateProfile(data.authToken || '', {
                username: username,
                displayName: displayName,
            })
                .then(() => {
                    let updated = {
                        ...data.profile?.me,
                        username: username,
                        displayName: displayName,
                    };
                    // @ts-ignore
                    setProfileData(updated, data, setData);
                    navigation.goBack();
                })
                .catch((ex) => {
                    console.log(`failed to update profile: ${ex}`);
                    alert(`Unable to update profile\n${ex}`);
                });
        }
    };

    return (
        <SafeAreaView style={{flex: 1, backgroundColor: '#f2f2f2'}}>
            <DetachedMenuBar onBackPressed={saveProfile} />
            <View style={styles.container}>
                <ProfileSettingFrame title={'Avatar'}>
                    <View style={{width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <EditableAvatar
                            avatarUri={data.profile.me.avatarUri}
                            setAvatarUri={(uri) => {
                                const updated = {...data.profile?.me, avatarUri: uri};
                                // @ts-ignore
                                setProfileData(updated, data, setData);
                            }}/>
                    </View>
                </ProfileSettingFrame>

                <ProfileSettingFrame title={'Display Name'}>
                    <PrettyTextInput text={displayName} setText={setDisplayName}/>
                </ProfileSettingFrame>

                <ProfileSettingFrame title={'Username'}>
                    <PrettyTextInput text={username} setText={setUsername}/>
                </ProfileSettingFrame>
            </View>
        </SafeAreaView>
    );
}

interface ProfileSettingFrameProps {
    title: string;
    children: ReactNode;
}

const ProfileSettingFrame: React.FC<ProfileSettingFrameProps> = ({
    title,
    children,
                                                                 }) => {
    return (
        <View style={styles.frame}>
            <Text style={styles.frameTitle}>{title}</Text>
            {children}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 25,
        paddingHorizontal: 15,
        paddingBottom: 10,
        paddingTop: 32,
    },
    frame: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: 10,
        width: '100%',
    },
    frameTitle: {
        fontSize: 14,
        fontFamily: font600,
        color: '#A0A0A0',
    },
});
