import React from "react";
import {StyleSheet, View} from "react-native";
import Svg, {Path} from "react-native-svg";
import {apiBaseUrl} from "../../../../lib/utils";
import {getAuthToken} from "../../../../lib/auth";

export interface EditAvatarButtonProps {
    setAvatarUri: React.Dispatch<React.SetStateAction<string>>;
}

export const EditAvatarButton: React.FC<EditAvatarButtonProps> = ({
    setAvatarUri,
                                                                  }) => {
    const handleAvatarSelection = async (event: any) => {
        let res = await uploadAvatar(event.target.files[0]);
        if (res && res.uri) {
            setAvatarUri(res.uri);
        }
    };

    const uploadAvatar = async (selectedFile: any) => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch(`${apiBaseUrl}/media/avatar`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getAuthToken()}`
                },
                body: formData,
            });
            return await response.json();
        } catch (error) {
            console.error('Upload failed:', error);
        }
    };

    return (
        <View style={styles.container}>
            <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                <Path
                    d="M13.2189 0C12.8777 0 12.5365 0.130293 12.2761 0.390628L10.6667 2.00002L14.0001 5.33338L15.6095 3.72399C16.1302 3.20332 16.1302 2.35923 15.6095 1.83856L14.1616 0.390628C13.9012 0.130293 13.56 0 13.2189 0ZM9.33341 3.33336L1.50652 11.1602C1.50652 11.1602 2.11837 11.1054 2.34637 11.3334C2.57437 11.5614 2.38669 13.0534 2.66669 13.3334C2.94669 13.6134 4.4293 13.4163 4.64196 13.629C4.85463 13.8417 4.83988 14.4936 4.83988 14.4936L12.6668 6.66672L9.33341 3.33336ZM0.666672 13.3334L0.0377607 15.1147C0.013037 15.185 0.000273226 15.2589 0 15.3335C0 15.5103 0.0702384 15.6798 0.195264 15.8049C0.320289 15.9299 0.489859 16.0001 0.666672 16.0001C0.741187 15.9999 0.815129 15.9871 0.885424 15.9624C0.887598 15.9615 0.889768 15.9606 0.891934 15.9598L0.908861 15.9546L0.912768 15.952L2.66669 15.3335L1.66668 14.3334L0.666672 13.3334Z"
                    fill="#84AFC6"/>
            </Svg>
            <input
                style={{
                    opacity: 0,
                    position: 'absolute',
                    // width and height must match parent container
                    width: 26,
                    height: 26,
                    flex: 1,
                }}
                type="file"
                accept="image/*"
                onChange={handleAvatarSelection}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        // this is relative to the circular avatar at the top of the personal profile page
        position: 'absolute',
        bottom: 15,
        left: 108,
        width: 40,
        height: 40,
        borderRadius: 10,
        backgroundColor: '#f2f2f2',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.9,
    },
});
