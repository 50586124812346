import {apiBaseUrl} from "./utils";
import {getAuthToken} from "./auth";

export interface SKUUploadRequest {
    // todo: add other items like segment and price

    // list of user ids that should receive the sku
    userAudience: string[];
    files: File[];
}

export async function createSku(sku: SKUUploadRequest) {
    const formData = new FormData();

    sku.files.forEach(file => {
        formData.append('files', file);
    });

    formData.append('metadata', JSON.stringify({
        users: sku.userAudience,
    }));

    try {
        const response = await fetch(`${apiBaseUrl}/studio/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`bad status response from api ${response.status}`);
        }
    } catch (error) {
        console.error('Upload failed:', error);
    }
}

export interface SKU {
    id: string;
    creationTime: Date;
    summary: string;
    price: string;
    unlocked: boolean;
    media: SKUMedia[];
}

export interface SKUMedia {
    id: string;
    uri: string;
}

export async function getSkus(token: string, userId: string): Promise<SKU[]> {
    // todo: pagination
    const response = await fetch(`${apiBaseUrl}/users/${userId}/skus`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });

    if (!response.ok) {
        throw new Error(`bad status response from api ${response.status}`);
    }

    const body = await response.json();
    // @ts-ignore
    return body?.skus?.map(sku => ({
        id: sku.id,
        creationTime: new Date(sku.creationTime),
        summary: sku.summary,
        price: sku.price,
        unlocked: sku.unlocked,
        media: sku.media,
    }));
}

export async function getFeed(token: string, pageSize: number, pageOffset: number): Promise<SKU[]> {
    const response = await fetch(`${apiBaseUrl}/feed?pageSize=${pageSize}&pageOffset=${pageOffset}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });

    if (!response.ok) {
        throw new Error(`bad status response from api ${response.status}`);
    }

    const body = await response.json();
    // @ts-ignore
    return body?.posts?.map(sku => ({
        id: sku.id,
        creationTime: new Date(sku.creationTime),
        summary: sku.summary,
        price: sku.price,
        unlocked: sku.unlocked,
        media: sku.media,
    }));
}

export async function getShared(by?: string): Promise<MediaSKU[]> {
    try {
        let url = `${apiBaseUrl}/studio/shared`;
        if (by) {
            url = `${url}?by=${by}`;
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`
            },
        });

        if (!response.ok) {
            throw new Error(`bad status response from api ${response.status}`);
        }

        let body = await response.json();
        let skus: MediaSKU[] = [];
        for (let sku of body.content) {
            skus.push({
                skuId: sku.skuId,
                createdByUser: sku.createdByUser,
                creationTime: new Date(sku.creationTime),
                mediaUris: sku.mediaUris,
            });
        }
        return skus;
    } catch (error) {
        console.error('Upload failed:', error);
        throw error;
    }
}

export interface MediaSKU {
    skuId: string;
    createdByUser: string;
    creationTime: Date;
    mediaUris: string[];
}
