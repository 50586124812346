import React, {useEffect, useRef, useState} from "react";
import {Dimensions, SafeAreaView, StyleSheet, View} from "react-native";
import {DetachedMenuBar} from "../../components/MenuBar";
import {PageHeader} from "../../components/common/PageHeader";
import {hydrateSkuGrid, SkuGrid} from "../../components/profile/peer/creator/SkuGrid";
import {getSkus, SKU} from "../../lib/sku";
import {useApp} from "../../state/AppProvider";
import {refreshContentState, setContentState} from "../../state/content";

export interface CreatorCatalogProps {

}

export function openCreatorCatalog(navigation: any) {
    navigation.navigate('CreatorCatalog');
}

export const CreatorCatalog: React.FC<CreatorCatalogProps> = ({}) => {
    const {data, setData} = useApp();
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(Dimensions.get('window').width);

    const getContainerWidth = () => {
        // @ts-ignore
        containerRef.current.measure((fx, fy, width, height, px, py) => {
            setContainerWidth(width);
        });
    }

    useEffect(() => {
        if (data.content.catalogStale) {
            refreshContentState(data, setData);
        }
    }, [data.content]);

    return (
        <SafeAreaView style={{flex: 1, backgroundColor: '#f2f2f2'}}>
            {/* @ts-ignore */}
            <DetachedMenuBar onBackPressed={(n) => n.goBack()} />
            <View
                ref={containerRef}
                onLayout={getContainerWidth}
                style={styles.container}>
                <PageHeader title={'Catalog'}/>
                <SkuGrid skus={data.content.myCatalog || []} width={containerWidth} />
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        gap: 20,
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 15,
    },
});
