import {getAuthToken} from "./auth";
import {apiBaseUrl} from "./utils";

const getProfileApiPath = `${apiBaseUrl}/users`;

export interface User {
    userId: string;
    username: string;
    avatarUri: string;
    displayName: string;
    bio?: string;
    accountType: AccountType;
    creatorNote?: string;
    creatorProfileLinks?: CreatorProfileLink[];
    following: boolean;
}

export interface CreatorProfileLink {
    title: string;
    url: string;
    position: number;
}

export enum AccountType {
    CREATOR = 1,
    PATRON = 2
}

export default async function getUser(userId: string): Promise<User> {
    try {
        const response = await fetch(`${getProfileApiPath}/${userId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        let profile = await response.json();
        return {
            userId: profile.userId,
            username: profile.username,
            avatarUri: profile.avatarUri,
            displayName: profile.displayName,
            bio: profile.bio,
            accountType: profile.accountType,
            creatorNote: profile.creatorNote,
            creatorProfileLinks: profile.creatorProfileLinks,
            following: profile.following || false,
        };
    } catch (error) {
        console.error('error fetching user:', error);
        throw error;
    }
}

export async function followUser(token: string, userId: string) {
    try {
        let res = await fetch(`${apiBaseUrl}/followers/follow`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({userId: userId}),
        });
        if (!res.ok) {
            throw new Error(`got bad response from server: ${res.status}`);
        }
    } catch (ex) {
        alert('server error');
        console.log('error following user:', ex);
    }
}

export async function unfollowUser(token: string, userId: string) {
    try {
        let res = await fetch(`${apiBaseUrl}/followers/unfollow`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({userId: userId}),
        });
        if (!res.ok) {
            throw new Error(`got bad response from server: ${res.status}`);
        }
    } catch (ex) {
        alert('server error');
        console.log('error unfollowing user:', ex);
    }
}

export async function blockUser(userId: string) {
    try {
        let res = await fetch(`${apiBaseUrl}/users/${userId}/block`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`
            }
        });

        if (res.status >= 400) {
            throw new Error('bad status: ' + res.status)
        }
    } catch (err) {
        console.log('error blocking user:', err);
        throw err;
    }
}

export async function changeDisplayName(newName: string) {
    try {
        let res = await fetch(`${apiBaseUrl}/profileUpdates/displayName`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`
            },
            body: JSON.stringify({
                displayName: newName,
            }),
        });

        if (res.status >= 400) {
            throw new Error('bad status: ' + res.status)
        }
    } catch (ex) {
        console.log('error changing display name:', ex);
        throw ex;
    }
}

export async function changeAbout(about: string) {
    try {
        let res = await fetch(`${apiBaseUrl}/profileUpdates/about`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`
            },
            body: JSON.stringify({
                about: about,
            }),
        });

        if (res.status >= 400) {
            throw new Error('bad status: ' + res.status)
        }
    } catch (ex) {
        console.log('error changing about:', ex);
        throw ex;
    }
}

export async function updateCreatorNote(patronId: string, note: string) {
    try {
        let res = await fetch(`${apiBaseUrl}/profileUpdates/creatorNote/${patronId}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`
            },
            body: JSON.stringify({
                note: note,
            }),
        });

        if (res.status >= 400) {
            throw new Error('bad status: ' + res.status)
        }
    } catch (ex) {
        console.log('error updating creator note:', ex);
        throw ex;
    }
}

export async function updateCreatorLinks(links: CreatorProfileLink[]) {
    try {
        let res = await fetch(`${apiBaseUrl}/profileUpdates/links`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`
            },
            body: JSON.stringify({
                links: links,
            }),
        });

        if (res.status >= 400) {
            throw new Error('bad status: ' + res.status)
        }
    } catch (ex) {
        console.log('error updating creator links:', ex);
        throw ex;
    }
}

export interface UpdateProfileRequest {
    username: string;
    displayName: string;
}

export async function updateProfile(token: string, req: UpdateProfileRequest) {
    let res = await fetch(`${apiBaseUrl}/users`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(req),
    });

    if (!res.ok) {
        let body = await res.json();
        throw new Error(`Invalid request: ${body.reason}`);
    }
}
