import React from "react";
import {Dimensions, StyleSheet, Text, View} from "react-native";
import {font300, font400, font500} from "../../fonts";
import Svg, {Path} from "react-native-svg";
import {NeonButton} from "../../common/NeonButton";
import {useNavigation} from "@react-navigation/native";
import {ProfileButton} from "../common/ProfileButton";

export interface BecomeACreatorProps {
}

export const BecomeACreator: React.FC<BecomeACreatorProps> = ({}) => {
    const navigation = useNavigation();

    return (
        <View style={styles.container}>
            <View style={styles.textContainer}>
                <Text style={styles.textHeader}>Become a creator</Text>
                <Text style={styles.textBody}>We give creators tools to publish and monetize content.</Text>
            </View>

            <ProfileButton
                buttonText={'Apply'}
                buttonStyle={{width: '100%'}}
                // @ts-ignore
                onPress={() => navigation.navigate('CreatorProgram')}/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: Dimensions.get('window').width * 0.8,
        borderRadius: 12,
        backgroundColor: 'white',
        flexDirection: 'column',
        gap: 15,
        padding: 15,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.06)',
    },
    textContainer: {
        flexDirection: 'column',
        gap: 8,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    textHeader: {
        fontSize: 14,
        fontFamily: font500,
        color: 'black',
    },
    textBody: {
        fontSize: 13,
        fontFamily: font400,
        color: '#262626',
    },
});
