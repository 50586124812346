import React from "react";
import {FlatList, StyleSheet} from "react-native";
import {getSkus, SKU} from "../../../../lib/sku";
import {SKUPreview} from "../../../common/SkuPreview";

export interface SkuGridProps {
    skus: SKU[];
    loadMore?: () => void;
    width: number;
}

export function hydrateSkuGrid(token: string, userId: string): Promise<SKU[]> {
    try {
        return getSkus(token, userId);
    } catch (ex) {
        alert('cannot fetch images: server error');
        console.log(`failed to hydrate sku grid: ${ex}`);
        return Promise.all([]);
    }
}

export const SkuGrid: React.FC<SkuGridProps> = ({
                                                    width,
                                                    skus,
                                                    loadMore = () => {},
                                                }) => {
    // @ts-ignore
    const renderItem = ({item, index}) => (
        <SKUPreview sku={item} cardStyle={{
            width: width / 3,
            height: 150,
        }} />
    );

    return (
        <FlatList
            data={skus}
            renderItem={renderItem}
            numColumns={3}
            keyExtractor={item => item.id}
            onEndReached={loadMore}
            onEndReachedThreshold={0.5}
        />
    )
}

const styles = StyleSheet.create({
});
