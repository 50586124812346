import React from "react";
import {Modal, StyleSheet, View, Text, Pressable, Linking} from "react-native";
import {Controller, useForm} from "react-hook-form";
import {font400, font500} from "./fonts";
import { Picker } from '@react-native-picker/picker';

export interface AgeVerificationModalProps {
    visible: boolean;
    close: () => void;
}

export const AgeVerificationModal: React.FC<AgeVerificationModalProps> = ({
    visible,
    close,
}) => {
    const {
        control,
        handleSubmit ,
        setError,
        formState: { errors },
    } = useForm();

    // @ts-ignore
    const onSubmit = (data) => {
        const today = new Date();
        const birthDate = new Date(data.year, data.month - 1, data.date);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age < 18) {
            const error = {
                type: 'manual',
                message: 'You must be over 18 to use this site. You will be redirected in 5 seconds.',
            }
            setError('age', error);
            setTimeout(() => {
                window.location.href = 'https://www.google.com';
            }, 5000);
        } else {
            close();
        }
    };

    const generateDateOptions = () => {
        const options = [];
        for (let i = 1; i <= 31; i++) {
            options.push(<Picker.Item key={i} label={`${i}`} value={i} />);
        }
        return options;
    };

    const generateMonthOptions = () => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        return months.map((month, index) => (
            <Picker.Item key={index} label={month} value={index + 1} />
        ));
    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = currentYear - 100;
        const options = [];
        for (let i = currentYear; i >= startYear; i--) {
            options.push(<Picker.Item key={i} label={`${i}`} value={i} />);
        }
        return options;
    };

    return (
        <Modal transparent={true} visible={visible} onRequestClose={close}>
            <View style={styles.overlay}>
                <View style={styles.form}>
                    <Text style={styles.formText}>
                        The website contains content not suitable for all ages.
                        Please provide your birth date to continue:
                    </Text>

                    <View style={styles.inputSection}>
                        <View style={styles.pickers}>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Picker
                                        selectedValue={value}
                                        onValueChange={onChange}
                                        style={styles.picker}
                                    >
                                        {generateMonthOptions()}
                                    </Picker>
                                )}
                                name="month"
                                defaultValue={1}
                            />

                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Picker
                                        selectedValue={value}
                                        onValueChange={onChange}
                                        style={styles.picker}
                                    >
                                        {generateDateOptions()}
                                    </Picker>
                                )}
                                name="date"
                                defaultValue={1}
                            />

                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Picker
                                        selectedValue={value}
                                        onValueChange={onChange}
                                        style={styles.picker}
                                    >
                                        {generateYearOptions()}
                                    </Picker>
                                )}
                                name="year"
                                defaultValue={new Date().getFullYear()}
                            />
                        </View>

                        <ContinueButton onPress={handleSubmit(onSubmit)}/>
                    </View>

                    {/* @ts-ignore */}
                    {errors.age && <Text style={styles.errorText}>{errors.age.message}</Text>}
                </View>
            </View>
        </Modal>
    );
};

interface ContinueButtonProps {
    onPress: () => void;
}

const ContinueButton: React.FC<ContinueButtonProps> = ({onPress}) => {
    return (
        <Pressable style={styles.continueButton} onPress={onPress}>
            <Text style={styles.continueButtonText}>Continue</Text>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    overlay: {
        flex: 1,
        backgroundColor: "rgba(0,0,0,0.20)",
        justifyContent: "center",
        alignItems: "center",
    },
    form: {
        width: "75%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#FAFAFA",
        borderRadius: 10,
        gap: 25,
        paddingHorizontal: 23,
        paddingVertical: 20,
    },
    formText: {
        color: "#1D1D1D",
        textAlign: "center",
        fontFamily: font400,
        fontSize: 16,
    },
    inputSection: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        width: '100%',
    },
    label: {
        fontSize: 16,
        marginBottom: 5,
    },
    picker: {
        fontSize: 17,
        color: '#464646',
        borderWidth: 0,
        backgroundColor: '#e6e6e6',
        borderRadius: 4,
    },
    pickers: {
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    continueButton: {
        backgroundColor: '#e0e0e0',
        borderRadius: 4,
        padding: 10,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    continueButtonText: {
        fontFamily: font500,
        fontSize: 16,
        color: '#575757',
    },
    errorText: {
        fontSize: 14,
        fontFamily: font500,
        color: '#b24848'
    },
});