import React from "react";
import {useApp} from "../../state/AppProvider";
import {View} from "react-native";
import {AccountType} from "../../lib/profile";
import {CreatorContentPage} from "./CreatorContentPage";
import {PatronContentPage} from "./PatronContentPage";

export interface ContentPageProps {

}

export const ContentPage: React.FC<ContentPageProps> = ({}) => {
    const {data, setData} = useApp();

    if (!data.profile) {
        return <View style={{flex: 1}} />
    }

    if (data.profile.me.accountType === AccountType.CREATOR) {
        return <CreatorContentPage />
    } else {
        return <PatronContentPage />
    }
}
