import React, {useState} from "react";
import {Image, Pressable, StyleSheet, Text, View} from "react-native";
import {apiBaseUrl, makeAvatarURL} from "../../lib/utils";
import {font400, font500, font600} from "../fonts";
import {neutral100} from "../colors";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {getAuthToken} from "../../lib/auth";

export interface AvatarProps {
    userId: string;
    displayName: string;
    uri: string;
}

export const Avatar: React.FC<AvatarProps> = ({
    userId,
    displayName,
    uri,
                                              }) => {
    const navigation = useNavigation();
    const [isUserOnline, setIsUserOnline] = useState(false);

    const onAvatarPressed = () => {
        // @ts-ignore
        navigation.navigate('PeerProfile', {
            userId: userId,
        });
    };

    useFocusEffect(
        React.useCallback(() => {
            const fetchUserOnlineStatus = async () => {
                let res = await fetch(`${apiBaseUrl}/statuses`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${getAuthToken()}`
                    },
                    body: JSON.stringify({users: [userId]}),
                })

                if (!res.ok) {
                    throw new Error(`got bad response when fetching partner status: ${res.status}`)
                }
                let body = await res.json();
                let status = body?.statuses[userId];
                setIsUserOnline(status);
            };

            fetchUserOnlineStatus();
            const interval = setInterval(fetchUserOnlineStatus, 2000);
            return () => clearInterval(interval);
        }, [userId])
    );

    return (
        <View style={styles.container}>
            <Pressable
                style={styles.avatarContainer}
                onPress={onAvatarPressed}>
                <View style={[
                    styles.avatar,
                    // @ts-ignore
                    {boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.4)'}
                ]}>
                    <Image
                        resizeMode="contain"
                        source={{uri: makeAvatarURL(uri)}}
                        style={styles.avatar}
                    />
                </View>
                {isUserOnline && <View style={styles.activityIndicator}/>}
            </Pressable>
            <Text style={styles.displayNameText}>{displayName}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
    },
    avatar: {
        width: 40,
        height: 40,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarContainer: {
        position: 'relative',
    },
    activityIndicator: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: '#70CC9A',
        position: 'absolute',
        left: 0,
        bottom: 0,
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.8)',
    },
    displayNameText: {
        fontSize: 14,
        fontFamily: font500,
        color: '#646465',
        textAlign: 'center',
    },
});
