import {apiBaseUrl} from "./utils";

const usersApiPath = `${apiBaseUrl}/users`

export default async function sendDeleteRequest(token: string) {
    await fetch(usersApiPath, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}