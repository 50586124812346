import React, {ReactNode} from "react";
import {StyleSheet, Text, View} from "react-native";

export interface MenuSectionProps {
    title: string;
    titleStyle?: object;
    children: ReactNode;
}

export const MenuSection: React.FC<MenuSectionProps> = ({
                                                            children,
                                                            title,
                                                            titleStyle = {}
                                                        }) => {
    return (
        <View style={styles.container}>
            <Text style={titleStyle}>{title}</Text>
            <View style={styles.items}>
                {children}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        gap: 20,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    items: {
        flexDirection: 'column',
        gap: 20,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
});
