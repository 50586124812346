import React from "react";
import {Image, StyleSheet, View} from "react-native";
import {makeAvatarURL} from "../../../../lib/utils";
import {EditAvatarButton} from "./EditAvatarButton";

export interface EditableAvatarProps {
    avatarUri: string;
    setAvatarUri: React.Dispatch<React.SetStateAction<string>>;
}

export const EditableAvatar: React.FC<EditableAvatarProps> = ({avatarUri, setAvatarUri}) => {
    return (
        <View style={styles.avatarContainer}>
            <View style={[
                styles.avatar,
                styles.avatarShadow,
            ]}>
                <Image
                    resizeMode="contain"
                    source={{uri: makeAvatarURL(avatarUri)}}
                    // @ts-ignore
                    style={styles.avatar}
                />
            </View>

            <EditAvatarButton setAvatarUri={setAvatarUri}/>
        </View>
    );
}

const styles = StyleSheet.create({
    avatarContainer: {
        paddingBottom: 10,
        alignItems: 'center',
    },
    avatar: {
        width: 160,
        height: 160,
        borderRadius: 80,
    },
    avatarShadow: {
        // @ts-ignore
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.5)'
    },
});
