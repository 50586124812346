import React from 'react';
import {Text, StyleSheet, ScrollView, SafeAreaView} from 'react-native';
import {DetachedMenuBar} from "../MenuBar";
import {PageHeader} from "../common/PageHeader";

export const PrivacyPolicyScreen: React.FC = () => {
    return (
        <SafeAreaView style={{flex: 1, backgroundColor: '#f2f2f2'}}>
            {/* @ts-ignore */}
            <DetachedMenuBar />
            <PageHeader title={'Privacy Policy'}/>
            <PrivacyPolicy />
        </SafeAreaView>
    )
}

const PrivacyPolicy = () => {
    return (
        <ScrollView style={styles.container}>
            <Text style={styles.updated}>Last updated: March 16, 2024</Text>

            <Text style={styles.section}>1. Information We Collect</Text>
            <Text style={styles.content}>
                We collect various types of information from users, including:
                {'\n'}- Email addresses when users sign up for an account.
                {'\n'}- Website usage information, such as which sections of the website users interact with.
                {'\n'}- Device information, such as IP addresses, to enforce geographic restrictions and fight spam.
                {'\n'}- Proof of age for users who opt into the creator program, in compliance with 18 U.S. Code § 2257.
            </Text>

            <Text style={styles.section}>2. How We Use Your Information</Text>
            <Text style={styles.content}>
                We use the information we collect to:
                {'\n'}- Provide, maintain, and improve our services.
                {'\n'}- Allow users to sign in to their accounts using their email addresses.
                {'\n'}- Contact users about matters they have opted into receiving communications for.
                {'\n'}- Enforce geographic restrictions and fight spam.
                {'\n'}- Comply with legal obligations, such as 18 U.S. Code § 2257.
                {'\n'}- Communicate with you about our services and updates to our policies.
            </Text>

            <Text style={styles.section}>3. Information Sharing and Disclosure</Text>
            <Text style={styles.content}>
                We may share your information with third parties in the following circumstances:
                {'\n'}- With your consent.
                {'\n'}- To comply with legal obligations or to respond to legal process.
                {'\n'}- To protect our rights, property, or safety, or that of our users or the public.
            </Text>

            <Text style={styles.section}>4. Data Security</Text>
            <Text style={styles.content}>
                We take reasonable measures to protect your information from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
            </Text>

            <Text style={styles.section}>5. Your Choices</Text>
            <Text style={styles.content}>
                You may update or delete your account information at any time. If you have questions about your information or would like to request that we delete your information, please contact us at contact@punchcardco.com.
            </Text>

            <Text style={styles.section}>6. Changes to This Policy</Text>
            <Text style={styles.content}>
                We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by email or by posting a notice on our website.
            </Text>

            <Text style={styles.section}>7. Contact Us</Text>
            <Text style={styles.content}>
                If you have any questions about this Privacy Policy, please contact us at contact@punchcardco.com.
            </Text>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
    },
    heading: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    updated: {
        fontSize: 14,
        color: '#777',
        marginBottom: 20,
    },
    section: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 10,
    },
    content: {
        fontSize: 16,
        lineHeight: 24,
        marginBottom: 10,
    },
});

export default PrivacyPolicy;