import React, {ReactNode} from "react";
import {Pressable, StyleSheet, Text, View} from "react-native";
import {font600, font700} from "../../fonts";

export interface ContentSectionProps {
    title: string;
    viewMoreText?: string;
    onViewMorePressed: () => void;
    children: ReactNode;
}

export const ContentSection: React.FC<ContentSectionProps> = ({
                                                                  title,
                                                                  viewMoreText = 'View more',
                                                                  onViewMorePressed,
                                                                  children,
                                                              }) => {
    return (
        <View style={styles.container}>
            <View style={styles.textContainer}>
                <Text style={styles.titleText}>{title}</Text>
                <Pressable onPress={onViewMorePressed}>
                    <Text style={styles.viewMoreText}>{viewMoreText}</Text>
                </Pressable>
            </View>

            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 8,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        gap: 10,
    },
    textContainer: {
        flexDirection: 'row',
        gap: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleText: {
        fontSize: 28,
        fontFamily: font600,
        color: '#4f4f4f',
        letterSpacing: -0.5,
    },
    viewMoreText: {
        fontSize: 14,
        fontFamily: font700,
        color: '#669dee',
    },
});
