import {StyleSheet, Text, View} from "react-native";
import * as React from "react";
import {green300, green500} from "../colors";
import {font300, font400} from "../fonts";

export interface GiftNotificationProps {
    username: string;
    direction: GiftDirection;
    amount: string;
}

export enum GiftDirection {
    SentTo,
    ReceivedFrom
}

export const GiftNotification: React.FC<GiftNotificationProps> = ({username, direction, amount}) => {
    const sent = () => direction === GiftDirection.SentTo;

    return (
        <View style={[styles.container, sent() ? styles.sentStyles : styles.receivedStyles]}>
            <Text style={styles.text}>
                {sent() ? 'You' : username} tipped {sent() ? username: 'you'} {amount}.
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingTop: 5,
        paddingBottom: 10,
        paddingHorizontal: 6,
    },
    sentStyles: {
        alignItems: 'flex-end'
    },
    receivedStyles: {
        alignItems: 'flex-start'
    },
    text: {
        fontFamily: font400,
        fontSize: 15,
        color: '#4a9150',
    },
});
