import {apiBaseUrl} from "./utils";
import {getAuthToken} from "./auth";

export interface SupportChat {
    userId: string;
    displayName: string;
    avatarUri: string;
    chatId: string;
}

export async function getSupportChat(): Promise<SupportChat> {
    let res = await fetch(`${apiBaseUrl}/support`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getAuthToken()}`,
        },
    });

    if (!res.ok) {
        throw new Error(`bad status from api: ${res.status}`);
    }
    return await res.json();
}