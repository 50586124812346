import {apiBaseUrl} from "./utils";
import {AccountType} from "./profile";

export function signedIn(): boolean {
    let token = getAuthToken();
    return token !== null && token !== '';
}

export function getAuthToken(): string | null {
    // todo: validate token and send to log in page if expired
    return localStorage.getItem('token');
}

export function setAuthToken(token: string) {
    localStorage.setItem('token', token);
}

export function deleteAuthToken() {
    localStorage.removeItem('token');
}

export function getUserId(): string {
    let token = getAuthToken();
    if (token === null) {
        throw new Error('cannot fetch user id: missing token');
    }
    return getAudFromJWT(token);
}

export function canAccessProject(token: string, projectId: string): boolean {
    let projects = getJwtClaims(token).projects;
    if (!projects) {
        return false;
    }
    return projects.includes(projectId);
}

export function getAccountType(): AccountType {
    let token = getAuthToken();
    if (token === null) {
        throw new Error('cannot fetch account type: missing token');
    }
    return getJwtClaims(token).accountType;
}

interface JWTClaims {
    aud: string;
    exp: number;
    iat: number;
    accountType: AccountType;
    projects?: string[];
}

function getAudFromJWT(token: string): string {
    return getJwtClaims(token).aud;
}

function getJwtClaims(token: string): JWTClaims {
    try {
        const parts = token.split('.');
        if (parts.length !== 3) {
            throw new Error('Invalid JWT: The token should consist of three parts');
        }

        const payload = parts[1];
        const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));
        return JSON.parse(decodedPayload);
    } catch (error) {
        console.error('Error in extracting aud from JWT:', error);
        throw error;
    }
}

export interface SignInRequest {
    firebaseAccessToken: string;
    firebaseUserId: string;
    firstTime: boolean;
}

export async function signIn(req: SignInRequest) {
    let res = await fetch(`${apiBaseUrl}/login`, {
        method: 'POST',
        body: JSON.stringify(req),
    })

    if (!res.ok) {
        throw new Error(`got bad status from api: ${res.status}`);
    }

    const data = await res.json();
    return data.token;
}
