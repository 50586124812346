import React, {useEffect} from "react";
import {Animated, SafeAreaView, StyleSheet, View} from "react-native";
import {layer200} from "../components/colors";
import {Title} from "../components/home/Title";
import {SearchBar, SearchResult} from "../components/home/SearchBar";
import {Profile} from "../components/home/Profile";
import shareState from "../lib/shareState";
import {NotFoundCard} from "../components/home/NotFoundCard";

export interface HomeProps {
}

export const Home: React.FC<HomeProps> = ({}) => {
    const firstSearch = shareState(true);
    const emptySearchResult = {
        userFound: false,
        user: {
            id: '',
            displayName: '',
            avatarUri: '',
            bio: '',
        }
    };
    const searchResult = shareState<SearchResult>(emptySearchResult);

    const profileOpacity = new Animated.Value(0);
    useEffect(() => {
        Animated.timing(profileOpacity, {
            toValue: 1,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }, [searchResult.value]);

    const onSearch = (res: SearchResult) => {
        firstSearch.set(false);
        searchResult.set(res);
    };

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.title}>
                <Title/>
            </View>

            <View style={styles.searchBar}>
                <SearchBar
                    onInputCleared={() => {
                        firstSearch.set(true)
                        searchResult.set(emptySearchResult);
                    }}
                    onSearchCompleted={onSearch}/>
            </View>

            <Animated.View style={styles.mainContent}>
                <Animated.View style={{opacity: profileOpacity, flex: 1,}}>
                    {searchResult.value.userFound &&
                        <Profile
                            // pls dnt ignore me
                            // @ts-ignore
                            userId={searchResult.value.user.id}
                            // @ts-ignore
                            avatarUri={searchResult.value.user.avatarUri}
                            // @ts-ignore
                            displayName={searchResult.value.user.displayName}
                            // @ts-ignore
                            bio={searchResult.value.user.bio}/>
                    }

                    {(!firstSearch.value && !searchResult.value.userFound) &&
                        <NotFoundCard/>
                    }
                </Animated.View>
            </Animated.View>
        </SafeAreaView>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 10,
        paddingBottom: 10,
        paddingTop: 50,
        backgroundColor: layer200,
    },
    title: {
        width: '100%',
        height: 50,
    },
    footer: {
        width: '100%',
        paddingHorizontal: 7,
    },
    searchBar: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 20,
    },
    mainContent: {
        flex: 1,
        flexDirection: 'column',
        gap: 10,
        paddingHorizontal: 30,
        paddingVertical: 25,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
});
