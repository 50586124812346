import {FlatList, SafeAreaView, StyleSheet, View} from "react-native";
import {DetachedMenuBar} from "../../components/MenuBar";
import {useEffect, useState} from "react";
import BlockedUser from "../../components/settings/BlockedUser";
import * as React from "react";
import {apiBaseUrl} from "../../lib/utils";
import {getAuthToken} from "../../lib/auth";
import {PageHeader} from "../../components/common/PageHeader";

export default function BlockedUsers() {
    const [blockedUsers, setBlockedUsers] = useState([]);

    useEffect(() => {
        const getBlockedUsers = async () => {
            try {
                let res = await fetch(`${apiBaseUrl}/blockedUsers`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${getAuthToken()}`
                    }
                });

                if (res.statusCode >= 400) {
                    throw new Error('bad status: ' + res.statusCode)
                }

                let body = await res.json();
                if (body.users !== null && body.users.length > 0) {
                    setBlockedUsers(body.users);
                }
            } catch (err) {
                console.log('error getting blocked users:', err);
            }
        }
        getBlockedUsers();
    }, []);

    const removeUserFromList = (userId) => {
        setBlockedUsers(prev => {
            return prev.filter(item => item.id !== userId);
        });
    }

    const renderBlockedUser = ({item}) => {
        return <BlockedUser
            onUnBlock={removeUserFromList}
            userId={item.id}
            username={item.username}
            avatarUri={item.avatarUri}/>
    }

    const ItemSeparator = () => {
        return (
            <View style={{
                height: 10,
            }}/>
        );
    }

    return (
        <SafeAreaView style={styles.container}>
            <DetachedMenuBar />
            <View style={styles.content}>
                <PageHeader title={'Blocked Users'}/>

                <FlatList
                    style={styles.userList}
                    keyExtractor={(item) => item.id}
                    data={blockedUsers}
                    ItemSeparatorComponent={ItemSeparator}
                    renderItem={renderBlockedUser}/>
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f2f2f2',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        paddingHorizontal: 15,
        paddingVertical: 10,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 20,
    },
    userList: {
        flexDirection: 'column',
        width: '100%',
        height: 100,
    },
});