import {StyleSheet, View, Text} from "react-native";
import React from "react";
import {font800} from "../../../fonts";
import {blockUser} from "../../../../lib/profile";
import {ProfileButton} from "../../common/ProfileButton";

export interface BlockModalViewProps {
    userId: string;
    username: string;
}

export const BlockModalView: React.FC<BlockModalViewProps> = ({
                                                                  userId,
                                                                  username,
                                                              }) => {
    const onConfirm = () => {
        // todo: handle error
        blockUser(userId)
            // @ts-ignore
            .then(() => navigation.navigate('MainScreen'));
    };

    return (
        <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 25,
            paddingTop: 10,
        }}>
            <Text style={styles.blockText}>Block @{username}?</Text>
            <ProfileButton
                buttonText={'Say less'}
                buttonStyle={{paddingHorizontal: 30}}
                // @ts-ignore
                onPress={onConfirm}/>
        </View>
    );
}

const styles = StyleSheet.create({
    buttonTextStyle: {
        fontSize: 14,
    },
    buttonStyle: {
        borderColor: '#FA6A6A',
        width: 110,
        height: 35,
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    modalContent: {
        height: 192,
        backgroundColor: 'transparent',
        width: '100%',
    },
    modalInnerContent: {
        height: 192,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: '#353d4b',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px -1px 15px rgba(0, 0, 0, 0.4)',
    },
    blockText: {
        fontSize: 20,
        fontFamily: font800,
        color: '#8f8e8d',
        letterSpacing: -0.5,
    },
    confirmButtonStyle: {
        borderColor: '#FA6A6A',
        width: 230,
        height: 48,
    },
    confirmButtonTextStyle: {
        fontSize: 14,
        textAlign: 'center',
    }
});
