import React, {useEffect, useState} from "react";
import {SafeAreaView, StyleSheet, View} from "react-native";
import {DetachedMenuBar} from "../../components/MenuBar";
import {PageHeader} from "../../components/common/PageHeader";
import {useApp} from "../../state/AppProvider";
import {refreshAssistantState, setAssistantState} from "../../state/assistant";
import {font500} from "../../components/fonts";
import {SwitchItem} from "../../components/assistant/settings/SwitchItem";
import {LongformInput} from "../../components/assistant/settings/LongformInput";
import {saveAssistant, saveServiceDetails} from "../../lib/assistant";

export interface AssistantSettingsProps {

}

export const AssistantSettings: React.FC<AssistantSettingsProps> = ({}) => {
    const {data, setData} = useApp();
    const [enabled, setEnabled] = useState<boolean>(data.assistant?.enabled || false);
    const [servicesOffered, setServicesOffered] = useState<string>(data.assistant?.servicesOffered || '');
    const [servicesNotOffered, setServicesNotOffered] = useState<string>(data.assistant?.servicesNotOffered || '');
    const [priceDetails, setPriceDetails] = useState<string>(data.assistant?.servicePriceDetails || '');

    useEffect(() => {
        if (!data.assistant) {
            refreshAssistantState(data, setData);
        }
    }, [data.assistant]);

    const handleSave = async () => {
        if (!data.assistant) {
            return
        }

        try {
            if (data.assistant.enabled !== enabled) {
                await saveAssistant(data.authToken || '', {enabled: enabled});
            }

            if (data.assistant.servicesOffered !== servicesOffered ||
                data.assistant.servicesNotOffered !== servicesNotOffered ||
                data.assistant.servicePriceDetails !== priceDetails) {
                await saveServiceDetails(data.authToken || '', {
                    servicesOffered: servicesOffered,
                    servicesNotOffered: servicesNotOffered,
                    priceDetails: priceDetails,
                });
            }

            if (data.assistant) {
                setAssistantState({
                    id: data.assistant.id,
                    name: data.assistant.name,
                    enabled: enabled,
                    servicesOffered: servicesOffered,
                    servicesNotOffered: servicesNotOffered,
                    servicePriceDetails: priceDetails,
                }, data, setData)
            }
        } catch (error) {
            console.error(error);
            alert('could not save assistant data: server error');
        }
    }

    // todo: on unmount, if changed: (1) update shared app state and (2) send new data to api
    // todo: create save/cancel buttons? kind of complex so building save on exit for now
    // todo: fix scrolling; the entire page shouldn't be scrollable; the menu bar needs to stay at the top

    return (
        <SafeAreaView style={{
            flex: 1,
            backgroundColor: '#f2f2f2',
        }}>
            {/* @ts-ignore */}
            <DetachedMenuBar onBackPressed={(n) => {
                handleSave();
                n.goBack()
            }}/>
            <View style={styles.container}>
                <PageHeader title={'Assistant'}/>
                {data.assistant &&
                    <View style={styles.island}>
                        <SwitchItem
                            textStyle={styles.settingText}
                            enabled={enabled}
                            onToggle={setEnabled}
                            enabledText={'Enabled'}
                            disabledText={'Disabled'}/>

                        <LongformInput
                            header={'Services Offered'}
                            headerStyle={styles.settingText}
                            placeholder={'A description of the types of services you offer'}
                            text={servicesOffered}
                            setText={setServicesOffered}/>

                        <LongformInput
                            header={'Limits'}
                            headerStyle={styles.settingText}
                            placeholder={'A description of the types of services you don\'t offer'}
                            text={servicesNotOffered}
                            setText={setServicesNotOffered}/>

                        <LongformInput
                            header={'Price Details'}
                            headerStyle={styles.settingText}
                            placeholder={'Pricing information for your services'}
                            text={priceDetails}
                            setText={setPriceDetails}/>
                    </View>
                }
                <View style={{height: 20, width: '100%'}} />
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 20,
        gap: 20,
        paddingHorizontal: 40,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    island: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        width: '100%',
        padding: 20,
    },
    settingText: {
        color: '#363636',
        fontFamily: font500,
        fontSize: 16,
    },
});
