import {State} from "./state";
import React from "react";
import {deleteAuthToken, setAuthToken} from "../lib/auth";

export const saveAuthToken = (token: string, data: State, setData: React.Dispatch<React.SetStateAction<State>>) => {
    if (token === data.authToken) {
        return;
    }
    setAuthToken(token);
    setData(prev => ({...prev, authToken: token}));
}

export const clearAuthToken = (data: State, setData: React.Dispatch<React.SetStateAction<State>>) => {
    if (!data.authToken) {
        return;
    }
    setData(prev => ({...prev, authToken: undefined}));
    deleteAuthToken();
}
