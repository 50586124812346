import React, {ReactNode, useEffect, useRef, useState} from "react";
import {Animated, Dimensions, Modal, Pressable, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {font700} from "../fonts";
import Svg, {Path} from "react-native-svg";

const {height: screenHeight} = Dimensions.get('window');

export interface BottomModalProps {
    isVisible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    headerText?: string;
    children: ReactNode;
    hideHeader?: boolean;
}

export const BottomModal: React.FC<BottomModalProps> = ({
                                                            isVisible,
                                                            setVisible,
                                                            children,
                                                            headerText = '',
                                                            hideHeader = false,
                                                        }) => {
    const [isModalMeasured, setIsModalMeasured] = useState(false);
    const modalY = useRef(new Animated.Value(screenHeight)).current; // Initialize off-screen

    useEffect(() => {
        if (isVisible && isModalMeasured) {
            // Slide up animation to natural position
            Animated.timing(modalY, {
                toValue: 0, // Slide up to its natural position
                duration: 150,
                useNativeDriver: false,
            }).start();
        } else if (!isVisible) {
            // Slide down animation to off-screen
            Animated.timing(modalY, {
                toValue: screenHeight, // Slide down off-screen
                duration: 150,
                useNativeDriver: false,
            }).start();
        }
    }, [isVisible, isModalMeasured]);

    // this was put on its own line so that my IDE would let me
    // edit it. no other reason its here
    const xColor = "#64b8e3";

    return (
        <Modal
            transparent={true}
            animationType="none"
            visible={isVisible}
            onRequestClose={() => setVisible(false)}
        >
            <TouchableOpacity
                style={styles.modalOverlay}
                activeOpacity={1}
                onPressOut={() => setVisible(false)}
            >
                <Animated.View
                    style={[
                        styles.modalContent,
                        {
                            transform: [{translateY: modalY}],
                        },
                    ]}
                    onLayout={(event) => {
                        if (!isModalMeasured) {
                            setIsModalMeasured(true); // Set flag to true after first measurement
                        }
                    }}
                >
                    <TouchableOpacity activeOpacity={1} style={styles.modalInnerContent}>
                        {!hideHeader &&
                            <View style={styles.headerRow}>
                                <View style={styles.headerIcon}/>

                                <Text style={styles.headerText}>{headerText}</Text>

                                <Pressable
                                    onPress={() => setVisible(false)}
                                    style={[
                                        styles.headerIcon,
                                        {backgroundColor: '#2d3749'}
                                    ]}>
                                    <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <Path
                                            d="M1.55637 0.0040921C1.24804 0.00447389 0.946824 0.0967614 0.691199 0.269165C0.435574 0.44157 0.237146 0.686263 0.121263 0.971987C0.00538085 1.25771 -0.0226955 1.5715 0.0406212 1.87325C0.103938 2.17501 0.255773 2.45105 0.47673 2.66609L4.8074 6.99677L0.47673 11.3274C0.327917 11.4703 0.209109 11.6415 0.127263 11.8308C0.0454174 12.0202 0.00217885 12.224 8.0245e-05 12.4303C-0.00201836 12.6366 0.0370652 12.8412 0.115042 13.0322C0.193018 13.2232 0.30832 13.3967 0.454195 13.5426C0.60007 13.6884 0.773585 13.8037 0.964579 13.8817C1.15557 13.9597 1.36021 13.9988 1.56649 13.9967C1.77278 13.9946 1.97658 13.9513 2.16594 13.8695C2.35531 13.7877 2.52645 13.6688 2.66932 13.52L7 9.18936L11.3307 13.52C11.4735 13.6688 11.6447 13.7877 11.834 13.8695C12.0234 13.9514 12.2272 13.9946 12.4335 13.9967C12.6398 13.9988 12.8444 13.9597 13.0354 13.8817C13.2264 13.8038 13.3999 13.6885 13.5458 13.5426C13.6917 13.3967 13.807 13.2232 13.885 13.0322C13.9629 12.8412 14.002 12.6366 13.9999 12.4303C13.9978 12.224 13.9546 12.0202 13.8727 11.8308C13.7909 11.6414 13.6721 11.4703 13.5233 11.3274L9.19259 6.99677L13.5233 2.66609C13.7473 2.4483 13.9004 2.16789 13.9623 1.86163C14.0243 1.55536 13.9923 1.23752 13.8705 0.949756C13.7487 0.661994 13.5428 0.417734 13.2798 0.248988C13.0169 0.080242 12.709 -0.00511882 12.3967 0.0040921C11.9938 0.0160952 11.6115 0.184454 11.3307 0.473501L7 4.80417L2.66932 0.473501C2.52465 0.324783 2.3516 0.206609 2.16043 0.125979C1.96926 0.045349 1.76385 0.00390165 1.55637 0.0040921Z"
                                            fill={xColor}/>
                                    </Svg>
                                </Pressable>
                            </View>
                        }
                        <View style={{width: '100%'}}>
                            {children}
                        </View>
                    </TouchableOpacity>
                </Animated.View>
            </TouchableOpacity>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalOverlay: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    modalContent: {
        backgroundColor: 'transparent',
        width: '100%',
    },
    modalInnerContent: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: '#f5f5f5',
        flexDirection: 'column',
        gap: 20,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px -1px 8px rgba(0, 0, 0, 0.2)',
        paddingHorizontal: 10,
        paddingTop: 10,
        paddingBottom: 30,
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        gap: 10,
    },
    headerIcon: {
        width: 36,
        height: 36,
        borderRadius: 18,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerText: {
        fontSize: 24,
        fontFamily: font700,
        color: '#C8C8C8',
    },
});
