import React, {useState} from "react";
import {BottomModal} from "../common/BottomModal";
import {StyleSheet, TextInput, View} from "react-native";
import {ProfileButton} from "../profile/common/ProfileButton";
import {font700} from "../fonts";
import {useApp} from "../../state/AppProvider";
import {depositFunds} from "../../lib/wallet";

export interface DepositModalProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onCompleteDeposit: () => void;
}

export const DepositModal: React.FC<DepositModalProps> = ({
                                                              visible,
                                                              setVisible,
                                                              onCompleteDeposit,
                                                          }) => {
    const {data, setData} = useApp();
    const [amount, setAmount] = useState('0');
    const handleAmountChange = (inputValue: string) => {
        let numericValue = inputValue.replace(/[^0-9]/g, '').replace(/^0+/, '');

        const numeric = parseInt(numericValue, 10);
        if (!isNaN(numeric) && numeric < 1000) {
            setAmount(numeric.toString());
        } else if (numeric >= 1000) {
            setAmount('999');
        } else {
            setAmount('0');
        }
    };

    const deposit = async () => {
        try {
            await depositFunds(parseInt(amount), data.authToken);
            setVisible(false);
            onCompleteDeposit();
        } catch (error) {
            console.error('deposit failed:', error);
        }
    }

    return (
        <BottomModal hideHeader={true} isVisible={visible} setVisible={setVisible}>
            <View style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 15,
                paddingTop: 25,
            }}>
                <View style={styles.textBox}>
                    <TextInput
                        style={styles.textInput}
                        value={`$${amount}`}
                        onChangeText={handleAmountChange}
                        placeholder="$0"
                        keyboardType={'numeric'}
                        placeholderTextColor='#958C8B'
                        maxLength={4}/>
                </View>

                <ProfileButton
                    buttonText={'Deposit'}
                    buttonStyle={{paddingHorizontal: 30}}
                    onPress={deposit}/>
            </View>
        </BottomModal>
    );
}

const styles = StyleSheet.create({
    sendButtonStyle: {
        borderColor: '#6AE0FA',
        width: 110,
        height: 35,
    },
    sendButtonTextStyle: {
        fontSize: 14,
        textAlign: 'center',
    },
    textBox: {
        backgroundColor: '#e6e6e6',
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 10,
        width: 210,
        height: 39,
        alignItems: 'center',
        justifyContent: 'center',
    },
    textInput: {
        color: '#424242',
        fontFamily: font700,
        fontSize: 24,
        flex: 1,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',

        // styles to remove border when focused
        borderWidth: 0,
        outlineWidth: 0,
        outlineStyle: 'none',
        boxShadow: 'none',
    },
});
