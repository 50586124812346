import React, {useEffect} from "react";
import {Dimensions, StyleSheet, View} from "react-native";
import {useApp} from "../../state/AppProvider";
import {refreshFeed} from "../../state/feed";
import {SkuGrid} from "../../components/profile/peer/creator/SkuGrid";

export interface PatronContentPageProps {

}

export const PatronContentPage: React.FC<PatronContentPageProps> = ({}) => {
    const {data, setData} = useApp();
    // -40 accounts for 20 padding on left and right
    const containerWidth = Dimensions.get('window').width - 40;

    useEffect(() => {
        if (data.feed.stale) {
            refreshFeed(data, setData);
        }
    }, []);

    return (
        <View style={styles.container}>
            <SkuGrid
                skus={data.feed.skus}
                loadMore={() => {
                    if (data.feed.serverHasMore) {
                        refreshFeed(data, setData);
                    }
                }}
                width={containerWidth} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 20,
    },
});
