import React, {useState} from "react";
import {StyleSheet, View} from "react-native";
import Svg, {Path} from "react-native-svg";
import {BecomeACreator} from "../../components/profile/personal/BecomeACreator";
import {useNavigation} from "@react-navigation/native";
import {ProfileHeader} from "../../components/profile/common/ProfileHeader";
import {useApp} from "../../state/AppProvider";
import {AccountType} from "../../lib/profile";
import {ProfileButton} from "../../components/profile/common/ProfileButton";
import {MoreModal} from "../../components/profile/personal/MoreModal";

export interface PersonalProfileProps {

}

export const PersonalProfile: React.FC<PersonalProfileProps> = ({}) => {
    const navigation = useNavigation();
    const {data, setData} = useApp();
    const [moreModalVisible, setMoreModalVisible] = useState(false);

    if (!data.profile) {
        return <View style={styles.container}/>;
    }

    return (
        <View style={styles.container}>
            <View style={styles.main}>
                <ProfileHeader
                    avatarUri={data.profile.me.avatarUri}
                    displayName={data.profile.me.displayName}
                    username={data.profile.me.username}/>

                <View style={styles.buttonsContainer}>
                    <ProfileButton
                        buttonText={'Edit Profile'}
                        Icon={(props: { color: string }) => (
                            <Svg width="15" height="15" viewBox="0 0 15 15"
                                 fill="none">
                                <Path
                                    d="M12.3926 0C12.0727 0 11.7529 0.122148 11.5088 0.366211L10 1.875L13.125 5L14.6338 3.49121C15.1219 3.00309 15.1219 2.21176 14.6338 1.72363L13.2764 0.366211C13.0323 0.122148 12.7124 0 12.3926 0ZM8.75 3.125L1.41235 10.4626C1.41235 10.4626 1.98596 10.4112 2.19971 10.625C2.41346 10.8388 2.2375 12.2375 2.5 12.5C2.7625 12.7625 4.15243 12.5777 4.35181 12.7771C4.55118 12.9765 4.53735 13.5876 4.53735 13.5876L11.875 6.25L8.75 3.125ZM0.625 12.5L0.0354004 14.1699C0.0122221 14.2358 0.000256147 14.3051 0 14.375C0 14.5408 0.065848 14.6997 0.183058 14.8169C0.300268 14.9342 0.45924 15 0.625 15C0.694857 14.9997 0.764177 14.9878 0.830078 14.9646C0.832117 14.9638 0.834151 14.963 0.836182 14.9622L0.852051 14.9573C0.853274 14.9565 0.854495 14.9557 0.855713 14.9548L2.5 14.375L1.5625 13.4375L0.625 12.5Z"
                                    fill={props.color}/>
                            </Svg>
                        )}
                        // @ts-ignore
                        onPress={() => navigation.navigate('EditProfile')}/>

                        <ProfileButton
                            Icon={(props: { color: string }) => (
                                <Svg width="18" height="5" viewBox="0 0 18 5" fill="none">
                                    <Path d="M2.33337 0.5C1.22871 0.5 0.333374 1.39533 0.333374 2.5C0.333374 3.60467 1.22871 4.5 2.33337 4.5C3.43804 4.5 4.33337 3.60467 4.33337 2.5C4.33337 1.39533 3.43804 0.5 2.33337 0.5ZM9.00004 0.5C7.89537 0.5 7.00004 1.39533 7.00004 2.5C7.00004 3.60467 7.89537 4.5 9.00004 4.5C10.1047 4.5 11 3.60467 11 2.5C11 1.39533 10.1047 0.5 9.00004 0.5ZM15.6667 0.5C14.562 0.5 13.6667 1.39533 13.6667 2.5C13.6667 3.60467 14.562 4.5 15.6667 4.5C16.7714 4.5 17.6667 3.60467 17.6667 2.5C17.6667 1.39533 16.7714 0.5 15.6667 0.5Z"
                                          fill={props.color}/>
                                </Svg>
                            )}
                            onPress={() => setMoreModalVisible(true)}/>
                </View>
            </View>

            <View style={styles.notifications}>
                {data.profile.me.accountType === AccountType.PATRON &&
                    <BecomeACreator />
                }
            </View>

            <MoreModal visible={moreModalVisible} setVisible={setMoreModalVisible}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f2f2f2',
        flexDirection: 'column',
        gap: 30,
        alignItems: 'center',
        paddingTop: 15,
    },
    main: {
        flexDirection: 'column',
        paddingTop: 50,
        alignItems: 'center',
        gap: 18,
    },
    buttonsContainer: {
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    notifications: {
        flex: 1,
        paddingVertical: 20,
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 50,
    },
});
