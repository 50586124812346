import React, {useEffect, useState} from "react";
import {SafeAreaView, StyleSheet, View} from "react-native";
import getUser, {AccountType, User} from "../../lib/profile";
import {PeerPatronProfileV2} from "../../components/profile/peer/patron/PeerPatronProfileV2";
import {PeerCreatorProfileV2} from "../../components/profile/peer/creator/PeerCreatorProfileV2";

export interface PeerProfileProps {
    route: {
        params: {
            userId: string;
        }
    }
}

export const PeerProfile: React.FC<PeerProfileProps> = ({route}) => {
    const [user, setUser] = useState<User>();

    const fetchUser = async () => {
        try {
            let res = await getUser(route.params.userId);
            setUser(res);
        } catch (err) {
            // todo: display error message
            console.log(`failed to get user: ${err}`);
        }
    };

    useEffect(() => {
        fetchUser();
    }, [route.params.userId]);

    if (!user) {
        return <View style={styles.container} />
    }

    // todo: determine where scrollview should be placed: at base (replacing
    //       SafeAreaView) or within SafeAreaView
    return (
        <SafeAreaView style={styles.container}>
            {(user && user.accountType === AccountType.PATRON) &&
                // @ts-ignore
                <PeerPatronProfileV2 user={user} setUser={setUser} />
            }

            {(user && user.accountType === AccountType.CREATOR) &&
                // @ts-ignore
                <PeerCreatorProfileV2 user={user} setUser={setUser} />
            }
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
});
