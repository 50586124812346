import React, {useEffect, useRef, useState} from 'react';
import {Animated, Dimensions, Modal, SafeAreaView, StyleSheet, TouchableOpacity, View} from 'react-native';
import MenuBar from '../components/MenuBar';
import {layer100} from '../components/colors';
import Inbox from './inbox/Inbox';
import shareState from '../lib/shareState';
import {Home} from './Home';
import {PersonalProfile} from './profile/PersonalProfile';
import {Menu} from "../components/menu/Menu";
import {ContentPage} from "./content/ContentPage";

export default function MainScreen({route}) {
    const initialScreen = route?.params?.initialScreen || 'content';
    const screens = {
        profile: <PersonalProfile />,
        content: <ContentPage />,
        inbox: <Inbox />,
    };

    const activeScreen = {
        name: shareState(initialScreen),
        Component: shareState(screens[initialScreen])
    };

    const navigate = (screenName) => {
        activeScreen.name.set(screenName);
    };

    useEffect(() => {
        activeScreen.Component.set(screens[activeScreen.name.value]);
    }, [activeScreen.name.value]);

    useEffect(() => {
        activeScreen.name.set(route?.params?.initialScreen || 'content');
    }, [route]);

    return (
        <SafeAreaView style={styles.container}>
            <MenuBar
                activeScreenName={activeScreen.name.value}
                onSelect={navigate}
            />
            <View style={styles.content}>
                {activeScreen.Component.value}
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
        backgroundColor: '#f2f2f2',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        flexDirection: 'column',
    },
});
