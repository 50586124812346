import React from "react";
import {FlatList, StyleSheet, Text, View} from "react-native";
import {WalletTransaction} from "../../lib/wallet";
import {font400, font500, font600} from "../fonts";
import {neutral100} from "../colors";
import {TransactionRecord} from "./TransactionRecord";
import Svg, {Path} from "react-native-svg";

export interface TransactionLogProps {
    transactions: WalletTransaction[];
}

export const TransactionLog: React.FC<TransactionLogProps> = ({transactions}) => {
    // @ts-ignore
    const renderTransaction = ({item}) => (
        <TransactionRecord
            summary={item.summary}
            dateTime={new Date(item.dateTime)}
            status={item.status}
        />
    )

    const NoTransactionsDisplay = () => (
        <View style={styles.noTransactionsDisplay}>
            <Svg width="28" height="24" viewBox="0 0 28 24" fill="none">
                <Path
                    d="M4.5 0C2.30267 0 0.5 1.80267 0.5 4V5C0.500027 5.26521 0.605392 5.51955 0.792922 5.70708C0.980453 5.89461 1.23479 5.99997 1.5 6H3.5V20C3.5 22.1973 5.30267 24 7.5 24H22.5H23.5C23.5531 23.999 23.606 23.9938 23.6582 23.9844C25.7816 23.8986 27.5 22.1432 27.5 20V19C27.5 18.7348 27.3946 18.4805 27.2071 18.2929C27.0195 18.1054 26.7652 18 26.5 18H21.5V5C21.5 2.25048 19.2495 0 16.5 0H4.5ZM16.5 2C18.1685 2 19.5 3.33152 19.5 5V18.832C19.4822 18.94 19.4822 19.0502 19.5 19.1582V20C19.5 20.7427 19.7617 21.4019 20.1191 22H7.5C6.38333 22 5.5 21.1167 5.5 20V6H13.5C13.7652 5.99997 14.0195 5.89461 14.2071 5.70708C14.3946 5.51955 14.5 5.26521 14.5 5V4C14.5 2.88333 15.3833 2 16.5 2ZM7.5 9V11H9.5V9H7.5ZM11.5 9V11H17.5V9H11.5ZM7.5 13V15H9.5V13H7.5ZM11.5 13V15H17.5V13H11.5ZM7.5 17V19H9.5V17H7.5ZM11.5 17V19H17.5V17H11.5Z"
                    fill="#787575"/>
            </Svg>
            <Text style={styles.noTransactionsText}>Your financial transactions will appear here.</Text>
        </View>
    );

    return (
        <View style={styles.container}>
            <Text style={styles.headerText}>Transactions</Text>
            <View style={styles.transactions}>
                {transactions?.length > 0 ? (
                    <FlatList
                        style={{width: '100%', height: 330}}
                        data={transactions}
                        renderItem={renderTransaction}
                        keyExtractor={item => item.dateTime}
                        showsVerticalScrollIndicator={false}
                        ItemSeparatorComponent={() => (
                            <View style={{height: 5}}/>
                        )}
                    />
                ) : (
                    <NoTransactionsDisplay />
                )}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 13,
        paddingHorizontal: 15,
    },
    headerText: {
        fontSize: 20,
        fontFamily: font500,
        color: '#4d4d4d',
        letterSpacing: -0.5,
        alignSelf: 'center',
    },
    transactions: {
        flex: 1,
    },
    noTransactionsDisplay: {
        alignItems: 'center',
        flexDirection: 'column',
        gap: 10,
        paddingTop: 30,
    },
    noTransactionsText: {
        color: '#787575',
        fontFamily: font500,
        fontSize: 13,
        textAlign: 'center',
    },
});
