import {getAuthToken} from "./auth";

export const apiBaseUrl = 'https://api-krpybarrda-uc.a.run.app';
export const chatServerBaseUrl = 'wss://wssvr-krpybarrda-uc.a.run.app';
export const msgApiUrl = 'https://message-api-krpybarrda-uc.a.run.app';

// todo: stop adding urls here, put them near the code that uses them; doing this now with assistant api

export interface HashedPayload {
    hash: ArrayBuffer;
    salt: string;
}

export async function hashPassword(password: string, username: string): Promise<HashedPayload> {
    let hashedPwd = await hashText(password, username)
    return {
        hash: hashedPwd.hash,
        salt: hashedPwd.salt
    }
}

export async function hashText(text: string, salt: string): Promise<{ salt: string, hash: ArrayBuffer }> {
    const saltedPassword = text + salt;
    const encoder = new TextEncoder();
    const encodedPassword = encoder.encode(saltedPassword);
    const hash = await crypto.subtle.digest('SHA-256', encodedPassword);
    return { salt, hash };
}

export function bufferToBase64(buffer: ArrayBuffer): string {
    return window.btoa(String.fromCharCode(...new Uint8Array(buffer)));
}

export function makeImageURL(uri: string): string {
    return `${apiBaseUrl}${uri}?token=${getAuthToken()}`
}

export function makeAvatarURL(uri: string): string {
    return `${apiBaseUrl}${uri}`
}
