import {SKU} from "../../lib/sku";
import React from "react";
import {useNavigation} from "@react-navigation/native";
import {Image, Pressable, StyleSheet, Text, View} from "react-native";
import {displayContent} from "../content/ContentDisplay";
import {makeImageURL} from "../../lib/utils";
import Svg, {Path} from "react-native-svg";
import {font500} from "../fonts";

export interface SKUPreviewProps {
    sku: SKU;
    cardStyle?: object;
}

export const SKUPreview: React.FC<SKUPreviewProps> = ({
                                                          sku,
                                                          cardStyle = {},
                                                      }) => {
    const navigation = useNavigation();

    if (sku.unlocked) {
        return (
            <Pressable
                onPress={() => displayContent(navigation, sku)}
                style={[styles.itemPreview, cardStyle]}>
                <Image
                    style={[styles.itemPreview, cardStyle]}
                    source={{uri: makeImageURL(sku.media[0].uri)}}/>

                <View style={styles.itemPreviewOverlay}>
                    <Text style={styles.itemPreviewText}>{sku.media.length}</Text>
                    <Svg width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <Path
                            d="M2.12499 7.08382e-05C2.03113 -0.00125661 1.93794 0.0160842 1.85084 0.0510855C1.76373 0.0860868 1.68446 0.13805 1.61761 0.203957C1.55077 0.269863 1.49769 0.348397 1.46146 0.434996C1.42523 0.521594 1.40657 0.61453 1.40657 0.708401C1.40657 0.802273 1.42523 0.895208 1.46146 0.981807C1.49769 1.06841 1.55077 1.14694 1.61761 1.21285C1.68446 1.27875 1.76373 1.33072 1.85084 1.36572C1.93794 1.40072 2.03113 1.41806 2.12499 1.41673H14.8749C14.9688 1.41806 15.062 1.40072 15.1491 1.36572C15.2362 1.33072 15.3155 1.27875 15.3823 1.21285C15.4492 1.14694 15.5022 1.06841 15.5385 0.981807C15.5747 0.895208 15.5934 0.802273 15.5934 0.708401C15.5934 0.61453 15.5747 0.521594 15.5385 0.434996C15.5022 0.348397 15.4492 0.269863 15.3823 0.203957C15.3155 0.13805 15.2362 0.0860868 15.1491 0.0510855C15.062 0.0160842 14.9688 -0.00125661 14.8749 7.08382e-05H2.12499ZM1.41666 2.83339C1.3228 2.83207 1.22961 2.84941 1.14251 2.88441C1.0554 2.91941 0.976127 2.97137 0.909282 3.03728C0.842437 3.10318 0.789358 3.18172 0.753128 3.26832C0.716899 3.35492 0.698242 3.44785 0.698242 3.54172C0.698242 3.63559 0.716899 3.72853 0.753128 3.81513C0.789358 3.90173 0.842437 3.98026 0.909282 4.04617C0.976127 4.11207 1.0554 4.16404 1.14251 4.19904C1.22961 4.23404 1.3228 4.25138 1.41666 4.25005H15.5833C15.6771 4.25138 15.7703 4.23404 15.8574 4.19904C15.9445 4.16404 16.0238 4.11207 16.0906 4.04617C16.1575 3.98026 16.2106 3.90173 16.2468 3.81513C16.283 3.72853 16.3017 3.63559 16.3017 3.54172C16.3017 3.44785 16.283 3.35492 16.2468 3.26832C16.2106 3.18172 16.1575 3.10318 16.0906 3.03728C16.0238 2.97137 15.9445 2.91941 15.8574 2.88441C15.7703 2.84941 15.6771 2.83207 15.5833 2.83339H1.41666ZM1.41666 5.66671C0.633956 5.66671 0 6.30067 0 7.08337V15.5833C0 16.366 0.633956 17 1.41666 17H15.5833C16.366 17 16.9999 16.366 16.9999 15.5833V7.08337C16.9999 6.30067 16.366 5.66671 15.5833 5.66671H1.41666Z"
                            fill="white"/>
                    </Svg>
                </View>
            </Pressable>
        );
    } else {
        return (
            <View style={[styles.itemPreview, styles.lockedSku, cardStyle]}>
                <Svg width="29" height="39" viewBox="0 0 29 39" fill="none">
                    <Path
                        d="M14.4999 0.820312C9.48147 0.820312 5.37994 4.92184 5.37994 9.94031V14.9634C2.58731 17.4679 0.819946 21.0934 0.819946 25.1403C0.819946 32.6962 6.94402 38.8203 14.4999 38.8203C22.0559 38.8203 28.1799 32.6962 28.1799 25.1403C28.1799 21.0934 26.4126 17.4679 23.6199 14.9634V9.94031C23.6199 5.11326 19.7956 1.22665 15.0403 0.930156C14.8684 0.860912 14.6852 0.823686 14.4999 0.820312ZM14.4999 3.86031C17.8749 3.86031 20.5799 6.56534 20.5799 9.94031V12.8972C18.7469 11.9853 16.6864 11.4603 14.4999 11.4603C12.3134 11.4603 10.2529 11.9853 8.41994 12.8972V9.94031C8.41994 6.56534 11.125 3.86031 14.4999 3.86031ZM14.4999 22.1003C16.1795 22.1003 17.5399 23.4607 17.5399 25.1403C17.5399 26.2621 16.9243 27.2313 16.0199 27.7587V29.7003C16.0199 30.5394 15.339 31.2203 14.4999 31.2203C13.6609 31.2203 12.9799 30.5394 12.9799 29.7003V27.7587C12.0755 27.2328 11.4599 26.2636 11.4599 25.1403C11.4599 23.4607 12.8203 22.1003 14.4999 22.1003Z"
                        fill="#747474"/>
                </Svg>
                <Text style={styles.priceText}>{sku.price}</Text>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    itemPreview: {
        width: 160,
        height: 200,
        borderRadius: 2,
        overflow: 'hidden',
    },
    itemPreviewOverlay: {
        position: 'absolute',
        top: 10,
        right: 10,
        flexDirection: 'row',
        gap: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemPreviewText: {
        fontSize: 11,
        color: 'white',
        fontFamily: font500,
    },
    lockedSku: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 10,
        backgroundColor: '#DEEAEE',
    },
    priceText: {
        fontSize: 20,
        fontFamily: font500,
        color: '#435864',
    },
});
