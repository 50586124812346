import React, {useEffect, useRef, useState} from "react";
import {Pressable, StyleSheet, View} from "react-native";
import {MessageInput} from "./MessageInput";
import Svg, {Path} from "react-native-svg";
import {getAccountType} from "../../lib/auth";
import {createSku} from "../../lib/sku";
import {SupportModal} from "../common/SupportModal";
import {AccountType} from "../../lib/profile";
import {BottomModal} from "../common/BottomModal";

export interface MessageComposerProps {
    onSendText: (text: string) => void;
    peerUserId: string;
    chatId: string;
}

export const MessageComposer: React.FC<MessageComposerProps> = ({
                                                                    onSendText,
                                                                    peerUserId,
                                                                    chatId,
                                                                }) => {
    const accountType = getAccountType();
    const [messageText, setMessageText] = useState('');
    const [isTipModalVisible, setIsTipModalVisible] = useState(false);

    const handleSend = () => {
        onSendText(messageText);
        setMessageText('');
    };

    const TipButton = () => (
        <View>
            <BottomModal hideHeader={true} isVisible={isTipModalVisible} setVisible={setIsTipModalVisible}>
                <SupportModal
                    userId={peerUserId}
                    closeModal={() => setIsTipModalVisible(false)}/>
            </BottomModal>

            <Pressable onPress={() => setIsTipModalVisible(true)}>
                <Svg width="28" height="29" viewBox="0 0 28 29" fill="none">
                    <Path
                        d="M14 0.444336C6.2685 0.444336 0 6.71284 0 14.4443C0 22.1758 6.2685 28.4443 14 28.4443C21.7315 28.4443 28 22.1758 28 14.4443C28 6.71284 21.7315 0.444336 14 0.444336ZM15.1667 21.2775V23.7777H12.8333V21.2845C10.3483 20.9532 8.78383 19.5077 8.69633 17.3657H11.3575C11.4847 18.432 12.5603 19.1157 14.1097 19.1157C15.5412 19.1157 16.5527 18.4227 16.5527 17.4298C16.5527 16.591 15.897 16.108 14.2835 15.7615L12.5697 15.3975C10.1722 14.8958 8.99733 13.6475 8.99733 11.642C8.99733 9.507 10.4837 8.00667 12.8333 7.62984V5.111H15.1667V7.62634C17.4487 7.99617 18.9887 9.47434 19.0598 11.4962H16.471C16.3438 10.4567 15.3767 9.76484 14.0467 9.76484C12.67 9.76484 11.7588 10.403 11.7588 11.4052C11.7588 12.216 12.3877 12.6815 13.9277 13.0093L15.5132 13.3465C18.1568 13.903 19.2955 15.0417 19.2955 17.0833C19.2967 19.3957 17.7497 20.924 15.1667 21.2775Z"
                        fill="#6D777A"/>
                </Svg>
            </Pressable>
        </View>
    );

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('upload processed');
        const files = event.target.files;
        if (!files || files.length === 0) {
            return;
        }

        createSku({
            files: [files[0]],
            userAudience: [peerUserId]
        }).catch((ex) => {
            console.log('error making chat sku:', ex);
            // todo: show error message
        });
    };

    const UploadButton = () => {
        const inputRef = useRef(null);

        useEffect(() => {
            const handleChange = (event: any) => {
                handleUpload(event);
            };

            const inputElement = inputRef.current;
            if (inputElement) {
                // @ts-ignore
                inputElement.addEventListener('change', handleChange);
            }

            return () => {
                if (inputElement) {
                    // @ts-ignore
                    inputElement.removeEventListener('change', handleChange);
                }
            };
        }, []);

        return (
            <View>
                <Svg width="29" height="27" viewBox="0 0 29 27" fill="none">
                    <Path
                        d="M5.16659 0.444336C2.7735 0.444336 0.833252 2.38459 0.833252 4.77767V17.7777C0.833252 20.1708 2.7735 22.111 5.16659 22.111H7.90454C7.69732 23.383 7.09542 24.6686 5.51994 25.3949C5.41624 25.4333 5.3268 25.5026 5.26363 25.5934C5.20047 25.6841 5.1666 25.7921 5.16659 25.9027C5.16659 26.0463 5.22365 26.1841 5.32524 26.2857C5.42682 26.3873 5.56459 26.4443 5.70825 26.4443C5.76201 26.4445 5.81549 26.4366 5.86694 26.4211C8.56281 26.3616 11.468 24.7935 13.0419 22.111H24.6666C27.0597 22.111 28.9999 20.1708 28.9999 17.7777V4.77767C28.9999 2.38459 27.0597 0.444336 24.6666 0.444336H5.16659ZM22.4999 3.69434C23.697 3.69434 24.6666 4.66392 24.6666 5.861C24.6666 7.05809 23.697 8.02767 22.4999 8.02767C21.3028 8.02767 20.3333 7.05809 20.3333 5.861C20.3333 4.66392 21.3028 3.69434 22.4999 3.69434ZM11.6666 9.11312C12.3374 9.11312 13.0082 9.36931 13.5201 9.88119L15.9999 12.361L16.0168 12.3441L17.2843 13.6136C17.7414 14.0708 18.4796 14.0665 18.9368 13.6094C19.3939 13.1522 19.3939 12.4088 18.9368 11.9505L17.7011 10.7148C18.727 9.95841 20.1747 10.0357 21.1034 10.9645L24.6666 14.5277V17.7777H5.16659V14.5277L9.81307 9.88119C10.3249 9.36931 10.9957 9.11312 11.6666 9.11312Z"
                        fill="#6D777A"/>
                </Svg>
                <input
                    ref={inputRef}
                    style={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        flex: 1,
                        width: 29,
                        height: 27,
                    }}
                    type="file"
                    accept="image/jpg,image/jpeg,image/gif,image/png,video/mov,video/mp4"
                />
            </View>
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.inputFrame}>
                <MessageInput
                    messageText={messageText}
                    setMessageText={setMessageText}/>

                {(!messageText || messageText === '') &&
                    <View style={styles.buttonContainer}>
                        {accountType === AccountType.PATRON && <TipButton/>}
                        <UploadButton/>
                    </View>
                }
            </View>


            {(messageText && messageText !== '') && <SendButton onPress={handleSend}/>}
        </View>
    );
}

interface SendButtonProps {
    onPress: () => void;
}

const SendButton: React.FC<SendButtonProps> = ({onPress}) => {
    return (
        <Pressable style={styles.sendButton} onPress={onPress}>
            <Svg width="19" height="20" viewBox="0 0 19 20" fill="none">
                <Path
                    d="M18.2083 0.944336C18.1324 0.944857 18.0568 0.956314 17.9841 0.978353C17.9627 0.984162 17.9416 0.990868 17.9207 0.998454L0.514893 6.53394V6.53703C0.363873 6.59338 0.233654 6.69449 0.141644 6.82684C0.0496342 6.95919 0.000217429 7.11648 0 7.27767C0.000288378 7.41361 0.0355778 7.54719 0.102469 7.66554C0.169361 7.78388 0.2656 7.88301 0.381917 7.95337L5.67155 12.1498L16.1287 3.81567L7.79452 14.2728L11.9879 19.5593C12.0581 19.6767 12.1576 19.7738 12.2765 19.8413C12.3955 19.9088 12.5299 19.9443 12.6667 19.9443C12.8279 19.9441 12.9851 19.8947 13.1175 19.8027C13.2498 19.7107 13.351 19.5805 13.4073 19.4294H13.4104L18.9505 2.00814C18.9562 1.99233 18.9613 1.97634 18.966 1.96021C18.988 1.88749 18.9995 1.81198 19 1.736C19 1.52604 18.9166 1.32468 18.7681 1.17621C18.6197 1.02774 18.4183 0.944336 18.2083 0.944336Z"
                    fill="white"/>
            </Svg>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        padding: 25,
        borderTopColor: '#e9ecf2',
        borderTopWidth: 1,
        alignItems: 'center',
        gap: 10,
    },
    buttonContainer: {
        flexDirection: 'row',
        gap: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    sendButton: {
        borderRadius: 9,
        backgroundColor: '#87BCF1',
        height: 39,
        width: 43,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.27)',
    },
    inputFrame: {
        flex: 1,
        height: 50,
        borderRadius: 15,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#EBEBEB',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'center',
        paddingRight: 15,
    },
});
