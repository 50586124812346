import {getAuthToken} from "../lib/auth";
import {apiBaseUrl} from "../lib/utils";

export const sendHeartbeat = async () => {
    let res = await fetch(`${apiBaseUrl}/heartbeat`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getAuthToken()}`
        }
    })

    if (!res.ok) {
        console.log(`heartbeat failed with status ${res.status}`)
    }
};