import {Image, Pressable, StyleSheet, Text, View} from "react-native";
import {getAuthToken} from "../../lib/auth";
import * as React from "react";
import {apiBaseUrl, makeImageURL} from "../../lib/utils";
import {layer300, neutral100, red100} from "../colors";
import {font300, font400} from "../fonts";

export default function BlockedUser({
    avatarUri,
    username,
    userId,
    onUnBlock
}) {
    const onUnblockPressed = async () => {
        try {
            let res = await fetch(`${apiBaseUrl}/users/${userId}/unblock`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${getAuthToken()}`
                }
            });

            if (res.statusCode >= 400) {
                throw new Error('bad status: ' + res.statusCode)
            }

            onUnBlock(userId);
        } catch (err) {
            console.log('error getting blocked users:', err);
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.avatarShadow}>
                <Image
                    resizeMode="contain"
                    source={{uri: makeImageURL(avatarUri)}}
                    style={styles.avatar}
                />
            </View>

            <Text style={styles.displayNameText}>{username}</Text>
            <View style={styles.unblockContainer}>
                <Pressable
                    onPress={onUnblockPressed}
                    style={styles.unblockButton}>
                    <Text style={styles.unblockButtonText}>Unblock</Text>
                </Pressable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 7,
        marginHorizontal: 14,
        borderRadius: 3,
    },
    avatarShadow: {
        width: 50,
        height: 50,
        borderRadius: 25,
        marginRight: 10,
        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.4)',
    },
    avatar: {
        width: 50,
        height: 50,
        borderRadius: 25,
        marginRight: 10,
    },
    displayNameText: {
        color: neutral100,
        fontSize: 16,
        fontFamily: font300,
    },
    unblockContainer: {
        flex: 1,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    unblockButton: {
        borderRadius: 3,
        paddingHorizontal: 10,
        paddingVertical: 5,
        backgroundColor: '#984c4c',
    },
    unblockButtonText: {
        fontSize: 15,
        color: red100,
        fontFamily: font400,
    },
})
