import {AssistantState, State} from "./state";
import React from "react";
import _ from "lodash";
import {getAssistant, GetAssistantResponse, NotFoundError, saveAssistant} from "../lib/assistant";
import {AccountType} from "../lib/profile";

export function setAssistantState(
    state: AssistantState,
    data: State,
    setData: React.Dispatch<React.SetStateAction<State>>
) {
    if (_.isEqual(state, data.assistant)) return;

    setData(prev => ({...prev, assistant: state}));
}

export async function refreshAssistantState(
    data: State,
    setData: React.Dispatch<React.SetStateAction<State>>
) {
    try {
        if (!data.authToken || data.authToken === '') {
            throw new Error('could not fetch assistant data: missing auth token');
        }

        if (data.profile?.me.accountType !== AccountType.CREATOR) {
            // Patron's cannot create assistants, so they don't need this data.
            return;
        }

        let assistant: GetAssistantResponse;
        try {
            assistant = await getAssistant(data.authToken);
        } catch (error) {
            if (error instanceof NotFoundError) {
                await saveAssistant(data.authToken, { enabled: false });
                assistant = await getAssistant(data.authToken);
            } else {
                throw error;
            }
        }

        setAssistantState({
            id: assistant.identity.id,
            name: assistant.identity.name,
            enabled: assistant.settings.enabled,
            servicesOffered: assistant.creatorKnowledge.servicesOffered,
            servicesNotOffered: assistant.creatorKnowledge.servicesNotOffered,
            servicePriceDetails: assistant.creatorKnowledge.servicePriceDetails,
        }, data, setData);
    } catch (ex) {
        console.log(ex);
        alert(`could not fetch assistant data from server: ${ex}`);
    }
}
